import { SurveyComponent } from "../../../../components/survey/survey_component";
import env from "../../../../env";
import { expressions } from "../../../../survey/validators";

const required = env.ENDPOINT !== "local";

export const marital_status_credicapital = (clientUrlForCatalog) =>
  new SurveyComponent({
    title: {
      en: "Family information",
      "es-MX": "Información familiar",
    },
    elements: [
      {
        title: { en: "Marital status", "es-MX": "Estado civil" },
        name: "custom_text_29",
        isRequired: required,
        type: "dropdown",
        defaultValue: "soltero",
        choices: [
          {
            value: "soltero",
            text: { en: "Single", "es-MX": "Soltero" },
          },
          {
            value: "unión_libre",
            text: { en: "Common law", "es-MX": "Unión Libre" },
          },
          {
            value: "casado",
            text: { en: "Married", "es-MX": "Casado" },
          },
        ],
      },
      {
        title: { en: "If married", "es-MX": "Si está casado *" },
        type: "panel",
        visibleIf: "{custom_text_29} == 'casado'",
        innerIndent: 1,
        elements: [
          {
            title: {en: "curp", "es-MX": "CURP *"},
            type: "text",
            name: "custom_text_102",
            isRequired: required,
            validators: [expressions.curp()],
            placeHolder: "HEGG560427MVZRRL04",
          },
          {
            type: "html",
            name: "Consultar CURP",
            html: "<button hidden class='curp-button'>Consultar CURP</button>",
          },
          {
            type: "html",
            name: "Error consulta CURP",
            html: "<p hidden class='sv_q_erbox' id='curp_validation_error'>No hemos podido validar su CURP, por favor inténtelo de nuevo.</p>"
          },
          {
            type: "html",
            name: "Error wrong format CURP",
            html: `<p hidden class='sv_q_erbox' id='curp_format_error'>La CURP consiste de 18 caracteres
              en el siguiente orden: 4 letras y 6 dígitos seguidos de 6 letras y 2 dígitos o 7 letras 
              y 1 dígito</p>`
          },
          {
            title: {
              en: "First name of spouse",
              "es-MX": "Nombres *",
            },
            type: "text",
            name: "custom_text_30",
            isRequired: required,
          },
          {
            title: {
              en: "Last name of spouse",
              "es-MX": "Apellido paterno *",
            },
            type: "text",
            name: "custom_text_43",
            isRequired: required,
          },
          {
            title: {
              en: "Mother's last name of spouse",
              "es-MX": "Apellido materno *",
            },
            type: "text",
            name: "custom_text_44",
          },
          {
            title: { en: "Sex", "es-MX": "Sexo *" },
            type: "text",
            name: "custom_text_45",
            isRequired: required,
          },
          {
            title: {
              en: "Spouse's date of birth",
              "es-MX": "Fecha de nacimiento del cónyuge *",
            },
            type: "text",
            name: "custom_text_31",
            isRequired: required,
            inputType: "date",
          },
          {
            title: {
              en: "Federal entity of birth",
              "es-MX": "Entidad federativa de nacimiento*",
            },
            type: "text",
            name: "custom_text_46",
            isRequired: required,
          },
          {
            title: {
              en: "Nationality of the spouse",
              "es-MX": "Nacionalidad del cónyuge",
            },
            type: "text",
            name: "custom_text_40",
            isRequired: required,
          },
          {
            title: {
              en: "Identity card / passport of the spouse",
              "es-MX": "Tarjeta de identidad/pasaporte del cónyuge",
            },
            type: "text",
            name: "custom_text_41",
            isRequired: required,
          },
          {
            title: { en: "Tax ID Number (RFC)", "es-MX": "RFC" },
            type: "text",
            name: "custom_text_101",
            validators: [
              {
                type: "regexvalidator",
                text: {
                  en: "Please enter a correct Tax Id Number (RFC) of 4 letters and 6 digits",
                  "es-MX":
                    "Por favor ingrese un RFC correcto de 4 letras y 6 dígitos",
                },
                regex: `^[A-Za-z]{4}[0-9]{6}$`,
              },
            ],
            placeHolder: "VECJ880326",
          },
          {
            title: { en: "Homoclave", "es-MX": "Homoclave" },
            type: "text",
            name: "custom_text_103",
            validators: [
              {
                type: "regexvalidator",
                text: {
                  en: "Please enter a correct homoclave of 3 characters",
                  "es-MX":
                    "Por favor ingrese una homoclave correcta de 3 caracteres",
                },
                regex: `^[0-9A-Za-z]{3}$`,
              },
            ],
            placeHolder: "XXX",
          },
          {
            title: {
              en: "Spouse's phone number",
              "es-MX": "Número de teléfono del cónyuge",
            },
            type: "phone",
            name: "custom_text_122",
            isRequired: required,
          },
          {
            title: {
              en: "Spouse's employment status",
              "es-MX": "Situación laboral del cónyuge",
            },
            type: "text",
            name: "custom_text_32",
            isRequired: required,
          },
          {
            title: {
              en: "Spouse's work address",
              "es-MX": "Dirección laboral del cónyuge",
            },
            type: "text",
            name: "custom_text_33",
            isRequired: required,
          },
          {
            title: { en: "Postal code", "es-MX": "Código postal" },
            type: "text",
            name: "custom_text_34",
            isRequired: required,
          },
          {
            type: "dropdown",
            name: "custom_text_35",
            title: { en: "Municipality", "es-MX": "Municipio" },
            renderAs: "select2",
            isRequired: true,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/colonia/colonias-codigopostal?codigoPostal={custom_text_34}`,
              path: "Municipio",
              valueName: "Descripcion",
              attachOriginalItems: true,
            },
          },
          {
            type: "dropdown",
            name: "custom_text_36",
            title: { en: "State", "es-MX": "Estado" },
            renderAs: "select2",
            isRequired: true,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/colonia/colonias-codigopostal?codigoPostal={custom_text_34}`,
              path: "Endidad_Federtiva",
              valueName: "Descripcion",
              attachOriginalItems: true,
            },
          },
          {
            type: "dropdown",
            name: "custom_text_106",
            title: { en: "Suburb", "es-MX": "Colonia" },
            renderAs: "select2",
            isRequired: true,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/colonia/colonias-codigopostal?codigoPostal={custom_text_34}`,
              path: "Colonias",
              valueName: "Descripcion",
              attachOriginalItems: true,
            },
          },
          {
            title: { en: "Marital Regime", "es-MX": "Régimen Conyugal" },
            type: "dropdown",
            isRequired: required,
            name: "custom_text_38",
            choices: [
              {
                value: "Sociedad Conyugal",
                text: { en: "Sociedad Conyugal", "es-MX": "Sociedad Conyugal" },
              },
              {
                value: "Separación de bienes",
                text: {
                  en: "Separación de bienes",
                  "es-MX": "Separación de bienes",
                },
              },
            ],
          },
        ],
      },
      {
        title: { en: "If common law", "es-MX": "Si está en unión libre*" },
        type: "panel",
        visibleIf: "{custom_text_29} == 'unión_libre'",
        innerIndent: 1,
        elements: [
          {
            title: { en: "curp", "es-MX": "CURP*" },
            type: "text",
            name: "custom_text_102",
            isRequired: required,
            validators: [expressions.curp()],
            placeHolder: "HEGG560427MVZRRL04",
          },
          {
            type: "html",
            name: "Consultar CURP",
            html: "<button hidden class='curp-button'>Consultar CURP</button>",
          },
          {
            type: "html",
            name: "Error consulta CURP",
            html: "<p hidden class='sv_q_erbox' id='curp_validation_error'>No hemos podido validar su CURP, por favor inténtelo de nuevo.</p>"
          },
          {
            type: "html",
            name: "Error wrong format CURP",
            html: `<p hidden class='sv_q_erbox' id='curp_format_error'>La CURP consiste de 18 caracteres
              en el siguiente orden: 4 letras y 6 dígitos seguidos de 6 letras y 2 dígitos o 7 letras 
              y 1 dígito</p>`
          },
          {
            title: {
              en: "Full name of partner",
              "es-MX": "Nombre completo del concubino(a) *",
            },
            type: "text",
            name: "custom_text_30",
            isRequired: required,
          },
          {
            title: {
              en: "Last name of spouse",
              "es-MX": "Apellido paterno *",
            },
            type: "text",
            name: "custom_text_43",
            isRequired: required,
          },
          {
            title: {
              en: "Mother's last name of spouse",
              "es-MX": "Apellido materno *",
            },
            type: "text",
            name: "custom_text_44",
          },
          {
            title: { en: "Sex", "es-MX": "Sexo *" },
            type: "text",
            name: "custom_text_45",
            isRequired: required,
          },
          {
            title: {
              en: "Partner's date of birth",
              "es-MX": "Fecha de nacimiento del concubino(a)",
            },
            type: "text",
            name: "custom_text_31",
            isRequired: required,
            inputType: "date",
          },
          {
            title: {
              en: "Federal entity of birth",
              "es-MX": "Entidad federativa de nacimiento*",
            },
            type: "text",
            name: "custom_text_46",
            isRequired: required,
          },
          {
            title: {
              en: "Nationality of the partner",
              "es-MX": "Nacionalidad del concubino(a)",
            },
            type: "text",
            name: "custom_text_40",
            isRequired: required,
          },
          {
            title: {
              en: "Identity card / passport of the partner",
              "es-MX": "Tarjeta de identidad/pasaporte del concubino(a)",
            },
            type: "text",
            name: "custom_text_41",
            isRequired: required,
          },
          {
            title: { en: "Tax ID Number (RFC)", "es-MX": "RFC" },
            type: "text",
            name: "custom_text_101",
            validators: [
              {
                type: "regexvalidator",
                text: {
                  en: "Please enter a correct Tax Id Number (RFC) of 4 letters and 6 digits",
                  "es-MX":
                    "Por favor ingrese un RFC correcto de 4 letras y 6 dígitos",
                },
                regex: `^[A-Za-z]{4}[0-9]{6}$`,
              },
            ],
            placeHolder: "VECJ880326",
          },
          {
            title: { en: "Homoclave", "es-MX": "Homoclave" },
            type: "text",
            name: "custom_text_103",
            validators: [
              {
                type: "regexvalidator",
                text: {
                  en: "Please enter a correct homoclave of 3 characters",
                  "es-MX":
                    "Por favor ingrese una homoclave correcta de 3 caracteres",
                },
                regex: `^[0-9A-Za-z]{3}$`,
              },
            ],
            placeHolder: "XXX",
          },
          {
            title: {
              en: "Partner's phone number",
              "es-MX": "Número de teléfono del concubino(a)",
            },
            type: "phone",
            name: "custom_text_122",
            isRequired: required,
          },
          {
            title: {
              en: "Partner's employment status",
              "es-MX": "Situación laboral del concubino(a)",
            },
            type: "text",
            name: "custom_text_32",
            isRequired: required,
          },
          {
            title: {
              en: "Partner's work address",
              "es-MX": "Dirección laboral del concubino(a)",
            },
            type: "text",
            name: "custom_text_33",
            isRequired: required,
          },
          {
            title: { en: "Postal code", "es-MX": "Código postal" },
            type: "text",
            name: "custom_text_34",
            isRequired: required,
          },
          {
            type: "dropdown",
            name: "custom_text_35",
            title: { en: "Municipality", "es-MX": "Municipio" },
            renderAs: "select2",
            isRequired: true,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/colonia/colonias-codigopostal?codigoPostal={custom_text_34}`,
              path: "Municipio",
              valueName: "Descripcion",
              attachOriginalItems: true,
            },
          },
          {
            type: "dropdown",
            name: "custom_text_36",
            title: { en: "State", "es-MX": "Estado" },
            renderAs: "select2",
            isRequired: true,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/colonia/colonias-codigopostal?codigoPostal={custom_text_34}`,
              path: "Endidad_Federtiva",
              valueName: "Descripcion",
              attachOriginalItems: true,
            },
          },
          {
            type: "dropdown",
            name: "custom_text_106",
            title: { en: "Suburb", "es-MX": "Colonia" },
            renderAs: "select2",
            isRequired: true,
            choicesByUrl: {
              url: `${clientUrlForCatalog}/api/colonia/colonias-codigopostal?codigoPostal={custom_text_34}`,
              path: "Colonias",
              valueName: "Descripcion",
              attachOriginalItems: true,
            },
          },
        ],
      },
      {
        title: { en: "Residence", "es-MX": "Casa" },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_39",
        choices: [
          { value: "Rentada", text: { en: "Rentada", "es-MX": "Rentada" } },
          { value: "Propia", text: { en: "Propia", "es-MX": "Propia" } },
          { value: "Familiar", text: { en: "Familiar", "es-MX": "Familiar" } },
        ],
      },
    ],
  });
