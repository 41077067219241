import { SurveyComponent } from "../../survey_component";

export const HouseContractAdditional = new SurveyComponent({
  name: "house_contract_additional",
  type: "file_uploader",
  accept: "image/*,application/pdf",
  isRequired: false,
  title: {
    en: "Rental or Ownership Agreement (Back Image) *",
    ar: "عقد الإيجار أو الملكية (الصورة الخلفية) *"
  },
  description: {
    en:
      "If you cannot find the document now, you can close this form and come back later.",
    ar:
      "إذا لم تتمكن من العثور على المستند الآن ، يمكنك إغلاق هذا النموذج والعودة اليه لاحقًا"
  }
});
