import Config from "../../config";
import { DEFAULT_LOCALE, translate } from "../../lib/intl";
import { select_survey_js_model } from "../../survey/survey.utils";
import {
  credit_check_form_banco_popular_existing_client,
  credit_check_form_banco_popular_new_client,
} from "./credit_check_pages_banco_popular";
import { credit_check_form_crediavance } from "./credit_check_pages_crediavance";
import { credit_check_form_credicapital } from "./credit_check_pages_credicapital";
import { credit_check_form_ffdl } from "./credit_check_pages_ffdl";
import {
  credit_check_form_procredito_agro,
  credit_check_form_procredito_transport,
} from "./credit_check_pages_procredito";
import { credit_check_form_rentamigo } from "./credit_check_pages_rentamigo";

export function credit_check(
  locale = DEFAULT_LOCALE,
  loan_purpose = "default",
  api_url = ""
) {
  // deployment specific pages
  const credicapital_pages = [credit_check_form_credicapital(api_url)];
  const ffdl_pages = [credit_check_form_ffdl];
  const procredito_pages = {
    default: credit_check_form_procredito_transport(api_url),
    agro: credit_check_form_procredito_agro(api_url),
    transport: credit_check_form_procredito_transport(api_url),
  };
  const banco_popular_pages = {
    new_client: credit_check_form_banco_popular_new_client,
    existing_client: credit_check_form_banco_popular_existing_client,
  };
  const crediavance_pages = [credit_check_form_crediavance];
  const rentamigo_pages = [credit_check_form_rentamigo];

  const availablePages = {
    credicapital: credicapital_pages,
    ffdl: ffdl_pages,
    procredito: select_survey_js_model(loan_purpose, procredito_pages),
    crediavance: crediavance_pages,
    rentamigo: rentamigo_pages,
    banco_popular: select_survey_js_model(loan_purpose, banco_popular_pages),
  };

  const currentDeploymentPages = availablePages[Config.deployment]
    ? availablePages[Config.deployment]
    : [];

  return {
    showQuestionNumbers: "off",
    sendResultOnPageNext: true,
    firstPageIsStarted: false,
    startSurveyText: translate("general.startSurveyText"),
    showCompletedPage: false,
    pagePrevText: translate("general.pagePrevText"),
    pageNextText: translate("general.pageNextText"),
    completeText: translate("general.pageCompleteText"),
    requiredText: "",
    isSinglePage: false, // Can be set to True for review mode
    pages: currentDeploymentPages,
    locale,
  };
}
