import Config from '../../config';
import { DEFAULT_LOCALE, translate } from '../../lib/intl';
import { choose_flow_banco_popular_pages } from './choose_flow_banco_popular';
import { choose_flow_mfw_pages } from './choose_flow_mfw';

export function choose_flow(locale = DEFAULT_LOCALE) {
  const availablePages = {
    mfw: choose_flow_mfw_pages,
    banco_popular: choose_flow_banco_popular_pages,
  };

  const currentDeploymentPages = availablePages[Config.deployment]
    ? availablePages[Config.deployment]
    : [];

  return {
    showQuestionNumbers: 'off',
    sendResultOnPageNext: true,
    firstPageIsStarted: false,
    startSurveyText: translate('general.startSurveyText'),
    showCompletedPage: false,
    pagePrevText: translate('general.pagePrevText'),
    pageNextText: translate('general.pageNextText'),
    completeText: translate('general.pageNextText'), // note same as `Next`
    requiredText: '',
    isSinglePage: false, // Can be set to True for review mode
    pages: currentDeploymentPages,
    locale,
  };
}
