import React from 'react';
import { connect } from 'react-redux';
import * as Survey from 'survey-react';
import { select_survey_js_model } from '../../survey/survey.utils';
import { Footer } from '../../components/footer/footer';
import HelpComponent from '../../components/help/help';
import { Start } from '../../components/start/start';
import Config from '../../config';
import { DEFAULT_LOCALE, translate } from '../../lib/intl';
import { loanActions } from '../../store/store';
import { calc_loanterms } from '../../survey/calculations';
import { removeElementTitles, saveBase64, useBase } from '../../survey/survey.utils';
import '../../theme/survey.scss';
import { agreement, review } from './review';
import './review.container.scss';
import { review_banco_popular } from './review_banco_popular';
import { review_bll } from './review_bll';
import { review_crediavance } from './review_crediavance';
import { review_credicapital } from './review_credicapital';
import { review_ffdl } from './review_ffdl';
import { review_finadev } from './review_finadev';
import { review_procredito_transport, review_procredito_agro } from './review_procredito';
import { review_rentamigo } from './review_rentamigo';
import { review_rw_business, review_rw_farming } from './review_rwanda';
// Survey.Serializer.addProperty('itemvalue', { name: 'id' });

class ReviewContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    const review_procredito = {
      default: review_procredito_transport,
      agro: review_procredito_agro,
      transport: review_procredito_transport,
    };

    const review_rw = {
      default: review_rw_business,
      farming: review_rw_farming,
      business: review_rw_business,
    };

    const deploymentModels = {
      rw: select_survey_js_model(props.loan.loan_purpose, review_rw),
      ffdl: review_ffdl,
      mfw: review,
      procredito: select_survey_js_model(props.loan.loan_purpose, review_procredito),
      finadev: review_finadev,
      crediavance: review_crediavance,
      credicapital: review_credicapital,
      rentamigo: review_rentamigo,
      banco_popular: review_banco_popular,
      bll: review_bll,
    };

    // setup model
    const currentDeploymentModel = (locale = DEFAULT_LOCALE) => {
      const model =
        deploymentModels[Config.deployment] !== undefined
          ? deploymentModels[Config.deployment]
          : deploymentModels.mfw;
      model.locale = locale;
      return model;
    };

    this.model = new Survey.Model(currentDeploymentModel(props.device.locale));

    // only restore certain data
    this.model.data = {
      amount: props.loan.intake.amount ? props.loan.intake.amount : 500,
      repayment_period: props.loan.intake.repayment_period
        ? props.loan.intake.repayment_period
        : 12,
      loan_purpose: props.loan.loan_purpose,
      //
      locale: props.device.locale,

      other_incomes: props.loan.intake.other_incomes,

      all_liabilities_and_outstanding_loans:
        props.loan.intake.all_liabilities_and_outstanding_loans,

      // ID
      first_name: props.loan.intake.first_name,
      last_name: props.loan.intake.last_name,
      id_number: props.loan.intake.id_number,
      ...this.model.data,
    };

    // this is required now as loan terms must be computed before the oncomplete event.
    // add backend driven loan terms
    let loan_terms = {};
    let loan_terms_summary = props.loan.loan_terms_summary
      ? props.loan.loan_terms_summary
      : { total_fees: 0, repayment_period: props.loan.repayment_period };

    //calculate loan terms for rwanda
    if (['mfw','rw'].includes(Config.deployment)) {
      loan_terms = calc_loanterms([
        this.model.data.amount,
        this.model.data.repayment_period,
        this.model.data.monthly_insurance,
        null,
      ]);
    }
    this.model.data = {
      ...this.model.data,
      ...loan_terms_summary,
      ...loan_terms,
      ...this.props.loan.intake,
      loan: this.props.loan,
    };

    // set callbacks
    this.model.onCompleting.add((survey) => this.onCompleting(survey));
    this.model.render();

    this.state = {
      is_started: false,
      help_count: 0,
      help_time: 0,
      timeOpen: null,
    };
  }

  onCompleting(survey) {
    let data = survey.data;
    let loan_terms_summary = data.loan_terms_summary ? data.loan.loan_terms_summary : {};

    // loan data should be in survey data, but new version does not contain this data. As such recaculate
    let loan_terms = {
      total_fees: 0,
      repayment_period: data.repayment_period,
      ...loan_terms_summary,
    };
    let signed_agreement;

    if (['mfw', 'rw'].includes(Config.deployment)) {
      loan_terms = calc_loanterms([
        data.amount,
        data.repayment_period,
        data.monthly_insurance,
        null,
      ]);
      signed_agreement = agreement(
        loan_terms,
        'The following agreements have been made in the business-flow:',
        this.props.device.locale
      );
    }

    // update loan terms and signed_agreement info
    data = {
      ...data,
      ...loan_terms,
      purpose: data.purpose,
      signature: Config.review_container.signature_required ? data.signature : 'agree',
      agreement: Config.review_container.signature_required ? signed_agreement : '',
    };

    // Save signature if required and modify data
    if (Config.review_container.signature_required) {
      saveBase64('signature.png', 'image/png', data.signature, (signature_date) => {
        data = { ...data, signature: signature_date };

        this.props
          .saveReview(this.props.loan.uuid, this.base, data)
          .then((loan) => this.props.setPage('guarantor_inviting'));
      });
    } else {
      this.props
        .saveReview(this.props.loan.uuid, this.base, data)
        .then((loan) => this.props.setPage('guarantor_inviting'));
    }
  }

  _openHelp() {
    // counts and keeps track of time
    this.setState({
      help_count: this.state.help_count + 1,
      timeOpen: new Date(),
    });
    this.model.stopTimer();
  }

  _closeHelp() {
    // resets model timer
    this.setState({
      timeOpen: null,
      help_time: this.state.help_time + Math.ceil((new Date() - this.state.timeOpen) / 1000),
    });
    this.model.startTimer();
  }

  render({ history, account, device } = this.props) {
    return (
      <div className="reviewContainer">
        <HelpComponent
          onClose={() => this._closeHelp()}
          onOpen={() => this._openHelp()}
          type="home"
          locale={device.locale}
        />

        {this.state.is_started ? (
          <div className="centerWrapper">
            <Survey.Survey model={this.model} locale={device.locale} />
          </div>
        ) : (
          <div>
            <Start
              stage="review"
              time={Config.has_no_container_time ? '0' : '5'}
              onStart={() => this.setState({ is_started: true })}
            >
              <p className="heavy">{translate('start_review.desc1')}</p>
              <p className="spacer">{translate('start_review.desc2')}</p>
            </Start>
            <Footer />
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    removeElementTitles(document, ['agree', 'removeThisName', 'truth', 'agree_last'], (el) => {
      const container = el.closest('.sv_p_container');
      container && container.setAttribute('dir', 'rtl');

      return true;
    });
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    device: state.device,
    loan: state.loan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveReview: (uuid, base, data) => dispatch(loanActions.saveReview(uuid, useBase(base, data))),
    setPage: (page) => dispatch(loanActions.setPage(page)),
  };
};

const connectedContainer = connect(mapStateToProps, mapDispatchToProps)(ReviewContainer);

export { connectedContainer as ReviewContainer };
