import $ from "jquery";
import React from "react";
import { Spinner } from "react-activity";
import ReactDOM from "react-dom";
import { connect, Provider } from "react-redux";
import storage from "redux-persist/lib/storage";
import "select2/dist/css/select2.min.css";
// register all customer components
import * as Survey from "survey-react";
// example sandbox select2
//https://codesandbox.io/s/ji1fi?file=/src/SurveyComponent.jsx:546-601
import * as widgets from "surveyjs-widgets";
// eslint-disable-next-line no-unused-vars
import select2Init from "select2";
import { Api } from "./api/api";
import {
  AlertDialog,
  hideAlertDialog,
  setAlertDialog,
} from "./components/alert/alert";
import Config from "./config";
import { URLToArray } from "./lib/helpers";
import { register_format_currency, translate } from "./lib/intl";
import { track } from "./lib/tracking";
import { Logger } from "./logger/logger";
import { Router } from "./router";
import { Network } from "./services/network";
import { initReducer } from "./store/reducer";
import { deviceActions, initStore } from "./store/store";
import {
  register_calcDaysDifference,
  register_calcYearsDifference,
  register_calc_pmt,
  register_calc_terms,
  register_days_from_now,
  register_get_full_name,
  register_get_loan_term,
  register_months_and_days_from_now,
  register_months_from_now,
  register_multiplyAndSumInArray,
  register_sum_matrixdown,
  register_template_conditional,
  register_weekdays_from_now,
  register_get_value_from_url,
} from "./survey/calculations";
import { register_ceil } from "./survey/ceil";
import { register_widget_file_uploader } from "./survey/file_uploader";
import { register_widget_logic } from "./survey/iq_question";
import { register_widget_likert } from "./survey/likert_scale";
// Update locales
import { survey_ar } from "./survey/locales/ar";
import { survey_en } from "./survey/locales/en";
import { survey_es } from "./survey/locales/es";
import { survey_fr } from "./survey/locales/fr";
import { survey_rn } from "./survey/locales/rn";
import { survey_rw } from "./survey/locales/rw";
import { register_widget_memory } from "./survey/memory_question";
import {
  register_fixed,
  register_money_functions,
} from "./survey/money_functions";
import { register_widget_multiple_choice } from "./survey/multiple_choice";
import { registerPhoneComponent } from "./survey/phone";
import { register_widget_ranking } from "./survey/ranking";
import { register_widget_repayment_schedule } from "./survey/repayment_schedule";
import { register_widget_slider } from "./survey/slider";
import { register_translate } from "./survey/translate";
import {
  register_differentPhoneNumbers,
  register_validators,
  register_samePhoneNumbersMatrix
} from "./survey/validators";
import "./theme/reset.scss";

export const logger = new Logger();

// Declare api, store and url params once.
export const api = new Api();
export const { store, persistor } = initStore(
  storage,
  initReducer(),
  [],
  api.endpoint.showReduxLogger
);
export const url_params = URLToArray(window.location.href);

register_calc_terms(Survey);
register_get_loan_term(Survey);
register_widget_ranking(Survey);
register_widget_likert(Survey);
register_widget_multiple_choice(Survey);
register_widget_slider(Survey);
register_widget_logic(Survey);
register_widget_memory(Survey);
register_widget_file_uploader(Survey);
register_fixed(Survey);
register_ceil(Survey);
register_translate(Survey);
register_sum_matrixdown(Survey);
register_multiplyAndSumInArray(Survey);
register_money_functions(Survey);
register_widget_repayment_schedule(Survey);
register_calc_pmt(Survey);
register_template_conditional(Survey);
register_months_from_now(Survey);
register_months_and_days_from_now(Survey);
register_days_from_now(Survey);
register_weekdays_from_now(Survey);
register_get_full_name(Survey);
register_calcYearsDifference(Survey);
register_calcDaysDifference(Survey);
register_format_currency(Survey);
register_get_value_from_url(Survey);
register_samePhoneNumbersMatrix(Survey);

const allowedCountries = Config.allowedCountries;

register_validators(Survey, allowedCountries);
register_differentPhoneNumbers(Survey);
registerPhoneComponent(Survey, allowedCountries);

Survey.surveyLocalization.locales["en"] = survey_en;
Survey.surveyLocalization.locales["ar"] = survey_ar;
Survey.surveyLocalization.locales["rw"] = survey_rw;
Survey.surveyLocalization.locales["rn"] = survey_rn;
Survey.surveyLocalization.locales["es-MX"] = survey_es;
Survey.surveyLocalization.locales["es-HN"] = survey_es;
Survey.surveyLocalization.locales["es-GT"] = survey_es;
Survey.surveyLocalization.locales["fr-TD"] = survey_fr;
Survey.surveyLocalization.locales["fr-BI"] = survey_fr;

export const setupAuthToken = async (authToken) => {
  if (authToken) {
    Survey.ChoicesRestfull.onBeforeSendRequest = function (_, options) {
      options.request.setRequestHeader("Authorization", `Bearer ${authToken}`);
    };
  }
};

// Fix samsung internet: https://github.com/SamsungInternet/support/issues/56
// eslint-disable-next-line no-extend-native
Function.prototype.ToString = () => {
  this.toString();
};

class Root extends React.Component {
  constructor(props) {
    //don't set seperate de-hydratation fase for main page,
    // let path = window.location.pathname;
    // let paths = ['/'];
    // console.log('dehydrating: ', path, paths.indexOf(path) === -1);

    super(props);
    window["$"] = window["jQuery"] = $;
    this.state = {
      // During A/B testing, we must awaiting dehydrating. else: dehydrating: paths.indexOf(path) === -1
      dehydrating: true,
      localeLoading: true,
    };
  }

  componentWillMount() {
    // refresh
    persistor.subscribe(() => {
      // get latest store data
      let state = store.getState();
      // render locale, depending on lang=en or state default
      let locale = url_params.lang
        ? url_params.lang.split("#")[0]
        : state.device.locale;
      this.props.setLocale(locale).then(() => {
        this.setState({ localeLoading: false });
      });

      // add utm campaign data
      if (url_params.utm_campaign) {
        track("campaignVisitor", { campaign: url_params.utm_campaign });
        store.dispatch({
          type: "SET_UTM_DATA",
          utm_campaign: url_params.utm_campaign,
        });
      }

      if (this.state.dehydrating) {
        this.setState({ dehydrating: false });
      }
    });
  }

  render = ({ device } = this.props) => {
    widgets.select2(Survey);
    widgets.select2tagbox(Survey);
    return this.state.dehydrating ||
      this.state.localeLoading ||
      device.localePending
      ? this.renderSplash()
      : this.renderApp(device);
  };

  renderApp = (device) => (
    <Provider store={store}>
      <div className="app" dir={device.locale === "ar" ? "rtl" : "ltr"}>
        <AlertDialog />
        <Router />
      </div>
    </Provider>
  );

  renderSplash = () => (
    <div className="loadingSpinner">
      <Spinner color="#eee" size={50} speed={1} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    device: state.device,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLocale: (locale) => dispatch(deviceActions.setLocale(locale)),
  };
};

const AppWithState = connect(mapStateToProps, mapDispatchToProps)(Root);

class App extends React.Component {
  render = () => (
    <Provider store={store}>
      <AppWithState />
    </Provider>
  );
}

Network.addListeners(
  () => api.ping(),
  (online) =>
    online
      ? hideAlertDialog()
      : setAlertDialog(
          translate("errors.network_issue.title"),
          translate("errors.network_issue.body"),
          { length: Infinity, map: () => {} }
        )
);

// render main app in browserrouter with all routes
ReactDOM.render(
  <logger.sentryErrorBoundary>
    <App />
  </logger.sentryErrorBoundary>,
  document.getElementById("root")
);
