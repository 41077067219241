import { SurveyComponent } from "../../survey_component";

export const BankAccountStatement = new SurveyComponent({
  type: "panel",
  title: {
    en: "Please upload the last three months bank statements (at least) *",
    ar: "يرجى تحميل كشف حساب بنكي لآخر ثلاثة أشهر (على الأقل) *"
  },
  description: {
    en:
      "If you cannot find the document now, you can close this form and come back later.",
    ar:
      "إذا لم تتمكن من العثور على المستند الآن ، يمكنك إغلاق هذا النموذج والعودة اليه لاحقًا"
  },
  visibleIf: "{bank_account} == 'yes'",
  elements: [1, 2, 3].map(n => ({
    title: {
      en: `Bank statement ${n}`,
      ar: "كشف حساب بنكي"
    },
    name: `bank_statement_${n}`,
    type: "file_uploader",
    accept: "image/*,application/pdf",
    isRequired: true
  }))
});
