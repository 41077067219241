import React from "react";
import {connect} from "react-redux";
import * as Survey from "survey-react";
import {Bullet} from "../../components/bullet/bullet";
import {Footer} from "../../components/footer/footer";
import HelpComponent from "../../components/help/help";
import {Start} from "../../components/start/start";
import Config from "../../config";
import {translate, translateList} from "../../lib/intl";
import {loanActions} from "../../store/store";
import {
    clearDropdowns,
    extractSurveyData,
    getApiUrl,
    saveBase64,
    setupLoadFromServer,
} from "../../survey/survey.utils";
import "../../theme/survey.scss";
import {credit_check} from "./credit_check";
import {addCURPButton} from "../../survey/curp";
import env from "../../env";

const CURRENT_CONTAINER = "creditCheck";

class CreditCheckContainer extends React.PureComponent {
    constructor(props) {
        super(props);
        // setup model
        this.model = new Survey.Model(
            credit_check(
                props.device.locale,
                props.loan.loan_purpose,
                getApiUrl(props)
            )
        );

        if (
            Config.load_from_api &&
            Config.server_form_load &&
            Config.server_form_load[CURRENT_CONTAINER]
        ) {
            setupLoadFromServer(
                this.model,
                Config.server_form_load[CURRENT_CONTAINER]
            );
        }

        if (
            Config.dropdowns_to_clear &&
            Config.dropdowns_to_clear[CURRENT_CONTAINER]
        ) {
            clearDropdowns(this.model, Config.dropdowns_to_clear[CURRENT_CONTAINER]);
        }

        // restore data
        this.model.data = this.base = {
            ...this.model.data,
            ...this.props.loan.intake,
        };

        // set callbacks
        this.model.onPartialSend.add((survey) => this.onPartialSend(survey));
        this.model.onCompleting.add((survey) => this.onCompleting(survey));

        this.model.render();

        this.state = {
            is_started: false,
            help_count: 0,
            help_time: 0,
            timeOpen: null,
        };

        this.descList = [];
        this.bullets = [];
        this.requirements = [];
        try {
            if (env.DEPLOYMENT_NAME.indexOf("credicapital") > -1) {
                this.model.onAfterRenderPage.add(addCURPButton(this.props.device.api_service_tokens["address_service_token"],
                    getApiUrl(props), '[data-name="custom_text_21"] input', "[data-name='first_name'] input",
                    "[data-name='surname'] input", "[data-name='second_surname'] input",
                    "[data-name='custom_text_6'] input", "[data-name='gender'] input",
                    "[data-name='date_of_birth'] input"));
            }
        } catch (e) {
            console.error(e, "Error adding curp button");
        }
        try {
            this.bullets = translateList("credit_check.bullets").map((bullet, idx) =>
                Bullet(translate(bullet), idx)
            );
            this.requirements = translateList("credit_check.requirements").map(
                (req, idx) => Bullet(translate(req), idx)
            );
            // Specific for Banco Popular to show additional requirements for new client
            if (this.props.loan.loan_purpose === "new_client") {
                this.requirements = [
                    ...this.requirements,
                    ...translateList("credit_check.extra_requirements_new_client").map(
                        (req, idx) => Bullet(translate(req), idx)
                    ),
                ];
            }
            this.descList = translateList("credit_check.desc_list").map(
                (desc, idx) => (
                    <p className="spacer" key={idx}>
                        {translate(desc)}
                    </p>
                )
            );
        } catch (e) {
            console.error(
                `Unable to translate ${e}, check translation key is added in locale file `
            );
        }
    }

    onPartialSend(survey) {
        this.props.saveIntake(
            this.props.loan.uuid,
            extractSurveyData(survey, this.state, this.base)
        );
    }

    async onCompleting(survey) {
        //update intake first
        let data = extractSurveyData(survey, this.state, this.base);
        let state = Config.credit_check_next_state
            ? Config.credit_check_next_state
            : "review_credit_check";
        // Save signature if required and modify data
        if (
            Config.credit_check_container &&
            Config.credit_check_container.signature_required
        ) {
            try {
                saveBase64(
                    "signature.png",
                    "image/png",
                    data.file_10,
                    (signature_date) => {
                        data = {...data, file_10: signature_date[0]["name"]};

                        this.props.saveIntake(this.props.loan.uuid, data).then(() => {
                            this.props
                                .completeCreditCheck(this.props.loan.uuid, state)
                                .then(() => this.props.setPage(state));
                        });
                    }
                );
            } catch (e) {
                console.log(e);
            }
        } else {
            await this.props.saveIntake(this.props.loan.uuid, data);

            this.props
                .completeCreditCheck(this.props.loan.uuid, state)
                .then(() => this.props.setPage(state));
        }
    }

    _openHelp() {
        // counts and keeps track of time
        this.setState({
            help_count: this.state.help_count + 1,
            timeOpen: new Date(),
        });
        this.model.stopTimer();
    }

    _closeHelp() {
        // resets model timer
        this.setState({
            timeOpen: null,
            help_time:
                this.state.help_time +
                Math.ceil((new Date() - this.state.timeOpen) / 1000),
        });
        this.model.startTimer();
    }

    render = ({device} = this.props) => {
        return (
            <div className="completeCreditCheck">
                <HelpComponent
                    onClose={() => this._closeHelp()}
                    onOpen={() => this._openHelp()}
                    type="home"
                    locale={device.locale}
                />

                {this.state.is_started ? (
                    <div className="centerWrapper">
                        <Survey.Survey model={this.model} locale={device.locale}/>
                    </div>
                ) : (
                    <div>
                        <Start
                            stage="credit_check"
                            time={Config.has_no_container_time ? "0" : "5"}
                            onStart={() => this.setState({is_started: true})}
                        >
                            <p className="heavy">{translate("credit_check.desc1")}</p>
                            <p className="spacer">{translate("credit_check.desc2")}</p>
                            {this.descList}
                            {this.bullets}
                            {translate("credit_check.req") !== "credit_check.req" ? (
                                <div>
                                    <p className="spacer">{translate("credit_check.req")}</p>
                                </div>
                            ) : null}
                            {this.requirements}
                        </Start>
                        <Footer/>
                    </div>
                )}
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        account: state.account,
        device: state.device,
        loan: state.loan,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveIntake: (uuid, assessment, submit = false) =>
            dispatch(loanActions.saveIntake(uuid, assessment, submit)),
        completeCreditCheck: (uuid, state, purpose) =>
            dispatch(loanActions.completeCreditCheck(uuid, state, purpose)),
        setPage: (page) => dispatch(loanActions.setPage(page)),
    };
};

const connectedContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CreditCheckContainer);

export {connectedContainer as CreditCheckContainer};
