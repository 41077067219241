import { SurveyComponent } from "../../../../components/survey/survey_component";
import env from "../../../../env";
import { expressions } from "../../../../survey/validators";

const required = env.ENDPOINT !== "local";

// if you start using the (clientUrlForCatalog) remove the _ in the name
export const additional_info_credicapital = (_clientUrlForCatalog) =>
  new SurveyComponent({
    title: {
      en: "Additional info ASEA",
      "es-MX": "Información adicional ASEA",
    },
    elements: [
      {
        title: {
          en: "Are you related to a shareholder or directors of ASEA Solution?",
          "es-MX":
            "¿Tiene usted parentesco con accionistas o directivos de Solución ASEA?*",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_69",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        type: "panel",
        visibleIf: "{custom_text_69} == 'si'",
        innerIndent: 1,
        name: "custom_text_70",
        elements: [
          {
            title: {
              en: "Name",
              "es-MX": "Nombre",
            },
            type: "text",
            isRequired: required,
            name: "custom_text_71",
          },
          {
            title: {
              en: "Position in the company",
              "es-MX": "Puesto que ocupa en la empresa",
            },
            type: "text",
            isRequired: required,
            name: "custom_text_72",
          },
          {
            title: {
              en: "Relationship",
              "es-MX": "Parentesco",
            },
            type: "dropdown",
            isRequired: required,
            name: "custom_text_73",
            choices: [
              {
                value: "padre_madre",
                text: { en: "Father/Mother", "es-MX": "Padre/Madre" },
              },
              {
                value: "cunado",
                text: { en: "Brother in law", "es-MX": "Cuñado" },
              },
              { value: "esposo", text: { en: "Spouse", "es-MX": "Esposo(a)" } },
              {
                value: "hermano",
                text: { en: "Sibling", "es-MX": "Hermano(a)" },
              },
              { value: "hijo", text: { en: "Son", "es-MX": "Hijo(a)" } },
              {
                value: "no_aplica",
                text: { en: "Does not apply", "es-MX": "No aplica" },
              },
              { value: "otro", text: { en: "Other", "es-MX": "Otro" } },
            ],
          },
        ],
      },
      {
        title: {
          en: "Do your parents or children have credit with ASEA Solution?",
          "es-MX": "¿Sus padres o hijos tienen crédito con Solución ASEA?",
        },
        type: "dropdown",
        name: "custom_text_76",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        title: { en: "", "es-MX": "" },
        type: "panel",
        visibleIf: "{custom_text_76} == 'si'",
        innerIndent: 1,
        name: "custom_text_77",
        elements: [
          {
            title: {
              en: "Are they financially dependent on each other?",
              "es-MX": "¿Tienen dependencia económica entre sí?",
            },
            type: "dropdown",
            choices: [
              { value: "si", text: { en: "Yes", "es-MX": "Si" } },
              { value: "no", text: { en: "No", "es-MX": "No" } },
            ],
          },
          {
            title: {
              en: "Name",
              "es-MX": "Nombre",
            },
            type: "text",
            name: "custom_text_79",
          },
          {
            title: {
              en: "CURP",
              "es-MX": "CURP",
            },
            validators: [expressions.curp()],
            type: "text",
            name: "custom_text_109",
            placeHolder: "HEGG560427MVZRRL04",
          },
          {
            title: { en: "Relationship", "es-MX": "Parentesco" },
            type: "dropdown",
            name: "custom_text_80",
            choices: [
              {
                value: "padre_madre",
                text: { en: "Father/Mother", "es-MX": "Padre/Madre" },
              },
              {
                value: "cunado",
                text: { en: "Brother in law", "es-MX": "Cuñado" },
              },
              { value: "esposo", text: { en: "Spouse", "es-MX": "Esposo(a)" } },
              {
                value: "hermano",
                text: { en: "Sibling", "es-MX": "Hermano(a)" },
              },
              { value: "hijo", text: { en: "Son", "es-MX": "Hijo(a)" } },
              {
                value: "no_aplica",
                text: { en: "Does not apply", "es-MX": "No aplica" },
              },
              { value: "otro", text: { en: "Other", "es-MX": "Otro" } },
            ],
          },
        ],
      },
    ],
  });
