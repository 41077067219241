import { SurveyComponent } from "../../../components/survey/survey_component";
import env from "../../../env";

const required = env.ENDPOINT !== 'local';

export const phone = new SurveyComponent({
  title: {
    en: 'About your phone', ar:
    'مجموعة معلومات الهاتف '
  },
  name: 'phone',

  elements:
    [
      {
        title: {en: "What model phone do you have?", ar: "ما هو نوع هاتفك؟"},
        description: {
          en: "Please enter the brand and type, e.g. Samsung, Huawei",
          ar: "يرجى إدخال العلامة التجارية والنوع مثل هواوي أو سامسونغ أو غير ذلك"
        },
        name: "phone_model",
        type: "text",
        isRequired: required,
      },

      {
        title: {en: "How long have you had this phone for?", ar: "منذ متى وأنت تملك هذا الهاتف؟"},
        name: "phone_age",
        type: "radiogroup",
        isRequired: required,
        choices: [
          {value: '1_month', text: {en: "less than 1 month", ar: "أقل من شهر"}},
          {value: '6_month', text: {en: "1-6 months", ar: "1-6 شهر"}},
          {value: '12_month', text: {en: "6-12 months", ar: "6-12 شهر"}},
          {value: '24_month', text: {en: "1-2 years", ar: "1-2 سنة"}},
          {value: '36_month', text: {en: "over 2 years", ar: "أكثر من سنتين"}},
        ]
      },

      {
        title: {
          en: "Have you bought your phone new or used?",
          ar: "عندما قمت بشراء هاتفك، هل كان جديداً أم مستعملاً؟"
        },
        description: {
          en: "When you bought your phone, {phone_age} ago",
          ar: "أي عندما قمت بشراءه قبل  {phone_age} "
        },
        name: "phone_new_or_used",
        type: "radiogroup",
        isRequired: required,
        visibleIf: "{phone_age} notempty",
        choices: [
          {value: 'used', text: {en: "Used", ar: "جديد "}},
          {value: 'new', text: {en: "New", ar: "مستعمل "}},
        ]
      },
    ]
});
