import env from "../../env";
// import { expressions } from "../../survey/validators";
const required = env.ENDPOINT !== "local";

export const guarantor_rentamigo = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Sign",
    "es-MX": "Firmar aplicacion",
  },

  questionStartIndex: 1,
  requiredText: "",

  pages: [
    // Page 0: Guarantor Acceptance
    {
      name: "guarantor_intake",
      title: { en: "Guarantor acceptance", "es-MX": "Aceptación del fiador" },
      innerIndent: 1,
      elements: [
        {
          type: "radiogroup",
          title: {
            en: "Guarantor acceptance",
            "es-MX": "Aceptación del fiador",
          },
          name: "guarantor_refused",
          isRequired: required,
          choices: [
            {
              value: false,
              text: { en: "I accept", "es-MX": "Acepto" },
            },
            {
              value: true,
              text: { en: " I don't accept", "es-MX": "No acepto" },
            },
          ],
        },
      ],
    },
    // Page 1: Guarantor Information
    {
      name: "guarantor_information",
      title: {
        en: "General data of the guarantor",
        "es-MX": "Datos generales del fiador",
      },
      innerIndent: 1,
      elements: [
        {
          type: "text",
          name: "guarantor_first_name",
          isRequired: required,
          title: { en: "What is your first name?", "es-MX": "Primer nombre*" },
        },
      ],
    },
    // Page 2: Guarantor Signature
    {
      name: "Guarantor's Signature",
      title: { en: "Guarantor's signature", "es-MX": "Firma del garante" },
      innerIndent: 1,
      elements: [
        {
          type: "html",
          title: {
            en: "One of the conditions to be a guarantor is that you have a good credit record. With your permission, this will be checked at the credit bureau.",
            "es-MX":
              "Una de las condiciones para ser fiador es  que tengas un buen récord crediticio. Con tu permiso, esto será comprobado en buró de crédito.",
          },
        },
        {
          type: "dropdown",
          name: "confirm_1",
          isRequired: required,
          storeOthersAsComment: false,
          choices: [
            { value: "I agree", text: { en: "I agree", "es-MX": "Acepto" } },
          ],
          title: {
            en: "Permission to check record at the credit bureau",
            "es-MX": "Consentimiento para comprobar record crediticio",
          },
        },
        {
          name: "Consentimiento consulta a buro de credito",
          type: "html",
          html: `<div><p>Una de las condiciones para ser fiador es  que tengas un buen récord crediticio. Con tu permiso, esto será comprobado en buró de crédito.
           </p><br><a class='button sv_complete_btn' 
           href='https://static-assets-moneyphone-dev.s3.eu-central-1.amazonaws.com/Consentimiento+consulta+a+buro+de+credito.pdf' download='Consentimiento' target="_blank">Descargar consentimiento para firma</a></div>`,
        },
        {
          type: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required,
          name: "picture_1",
          title: {
            en: "Upload signed permission *",
            "es-MX": "Subir consentimiento firmado *",
          },
        },
        {
          type: "signaturepad",
          name: "final_signature",
          isRequired: true,
          title: {
            en: "Please enter your signature *",
            "es-MX": "Por favor ingrese su firma *",
          },
        },
      ],
    },
  ],
};
