import { SurveyComponent } from "../../survey_component";
import { expressions } from "../../../../survey/validators";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const MonthlyCashFlowFamilyExpenses = new SurveyComponent({
  type: "panel",
  innerIndent: "1",
  name: "family_expenses",
  title: {
    en: "Family Expenses",
    ar: "مصاريف العائلة",
  },
  elements: [
    {
      name: "mcf_expenses", // FIXME: Verify how this information should be saved; preventing duplicate data.
      // valueName: 'mcf_expenses', // FIXME: Verify how this information should be saved; preventing duplicate data.
      type: "matrixdynamic",
      titleLocation: "none",
      minRowCount: 0,
      rowCount: 0,
      isRequired: false,
      addRowText: {
        en: "Add",
        ar: "إضافة",
      },
      columns: [
        {
          title: {
            en: "Item",
            ar: "البند",
          },
          name: "mcf_expense_type",
          isRequired: required,
          cellType: "dropdown",
          inputType: "string",
          choices: [
            {
              value: "project_income",
              text: {
                en: "Project income",
                ar: "دخل المشروع",
              },
            },
            {
              value: "family_income",
              text: {
                en: "Family income",
                ar: "دخل العائلة",
              },
            },
            {
              value: "spouse_income",
              text: {
                en: "Husband / Wife",
                ar: "الزوج / الزوجة",
              },
            },
            {
              value: "sons_income",
              text: {
                en: "Sons",
                ar: "الأبناء",
              },
            },
            {
              value: "other_income",
              text: {
                en: "Other",
                ar: "أخرى",
              },
            },
          ],
        },
        {
          title: {
            en: "Amount (JOD)",
            ar: "المبلغ (دينار أردني)",
          },
          name: "mcf_expense_amount",
          isRequired: required,
          cellType: "text",
          inputType: "number",
          validators: [
            expressions.amount_greater_than_zero("mcf_expense_amount"),
          ],
        },
        {
          title: {
            en: "Attachment (optional)",
            ar: "المرفق",
          },
          name: "mcf_expense_attachment",
          isRequired: false,
          cellType: "file_uploader",
          accept: "image/*,application/pdf",
        },
      ],
    },
    {
      title: {
        en: "Total Family Expenses",
        ar: "مجموع نفقات العائلة",
      },
      type: "expression",
      name: "mcf_total_expense_amount",
      visibleIf: "sumInArray({mcf_expenses}, 'mcf_expense_amount') > 0",
      expression: "fixed(sumInArray({mcf_expenses}, 'mcf_expense_amount'), 2)", // Floating Point Proof
      displayStyle: "currency",
      currency: "JOD",
    },
  ],
});
