import env from "../../env";

const required = env.ENDPOINT !== "local";

export const credicapital_assessment_study = [
  // Page 1
  {
    title: {
      en: "Socio-economic study",
      "es-MX": "Estudio socioeconómico",
    },
    elements: [
      {
        title: {
          en: "Customer or prospect name",
          "es-MX": "Nombre del cliente o prospecto",
        },
        type: "text",
        name: "first_name",
        isRequired: required,
      },
      {
        title: {
          en: "Sales details",
          "es-MX": "Detalle de las ventas",
        },
        type: "panel",
        elements: [
          {
            title: {
              en: "Sales control",
              "es-MX": "Control de ventas",
            },
            type: "dropdown",
            name: "custom_text_82",
            isRequired: required,
            hasOther: true,
            storeOthersAsComment: false,
            otherText: { en: "Other", "es-MX": "Otro" },
            choices: [
              {
                value: "computadora",
                text: { en: "Computer", "es-MX": "Computadora" },
              },
              {
                value: "libreta",
                text: { en: "Notebook", "es-MX": "Libreta" },
              },
            ],
          },
          {
            title: {
              en: "Activity or line of business",
              "es-MX": "Actividad o giro del negocio",
            },
            type: "dropdown",
            name: "custom_text_84",
            isRequired: required,
            choices: [
              { value: "comercio", text: { en: "Trade", "es-MX": "Comercio" } },
              {
                value: "servicio",
                text: { en: "Service", "es-MX": "Servicio" },
              },
              {
                value: "industria",
                text: { en: "Industry", "es-MX": "Industria" },
              },
            ],
          },
          {
            type: "panel",
            visibleIf: `{custom_text_84} == 'comercio' || {custom_text_84} == 'servicio'`,
            elements: [
              {
                title: {
                  en: "Total sales for the previous month",
                  "es-MX": "Total de ventas del mes anterior",
                },
                type: "text",
                inputType: "number",
                name: "custom_float_39",
                isRequired: required,
              },
              {
                title: {
                  en: "LIST OF PRODUCTS",
                  "es-MX": "LISTADO DE PRODUCTOS",
                },
                name: "custom_jsonfield_2",
                valueName: "custom_jsonfield_2",
                type: "matrixdynamic",
                minRowCount: 1,
                maxRowCount: 15,
                columnLayout: "vertical",
                isRequired: false,
                addRowText: {
                  en: "Add",
                  "es-MX": "Añadir",
                },
                columns: [
                  {
                    title: {
                      en: "Product Description, Item.",
                      "es-MX": "Descripción del Producto, Artículo.",
                    },
                    name: "descripcion_del_producto",
                    cellType: "text",
                    inputType: "string",
                  },
                  {
                    title: {
                      en: "Unit of measurement",
                      "es-MX": "Unidad de medida",
                    },
                    name: "unidad_de_medida",
                    cellType: "text",
                    inputType: "string",
                  },
                  {
                    title: {
                      en: "Sale price",
                      "es-MX": "Precio de venta",
                    },
                    name: "precio_de_venta",
                    cellType: "text",
                    inputType: "number",
                  },
                  {
                    title: {
                      en: "Purchase price",
                      "es-MX": "Precio de compra",
                    },
                    name: "precio_de_compra",
                    cellType: "text",
                    inputType: "number",
                  },
                  {
                    title: {
                      en: "Monthly amount",
                      "es-MX": "Cantidad mensual",
                    },
                    name: "cantidad_mensual",
                    cellType: "text",
                    inputType: "number",
                  },
                  {
                    title: {
                      en: "Monthly sales amount",
                      "es-MX": "Importe de ventas mensual",
                    },
                    cellType: "expression",
                    name: "importe_ventas_mensual",
                    expression:
                      "{row.precio_de_venta} * {row.cantidad_mensual}",
                    displayStyle: "currency",
                    currency: "MXN",
                  },
                  {
                    title: {
                      en: "Monthly purchase amount",
                      "es-MX": "Importe de compras mensual",
                    },
                    cellType: "expression",
                    name: "importe_de_compras_mensual",
                    expression:
                      "{row.precio_de_compra} * {row.cantidad_mensual}",
                    displayStyle: "currency",
                    currency: "MXN",
                  },
                ],
              },
              {
                title: {
                  en: "Total monthly sales",
                  "es-MX": "Total ventas mensual",
                },
                type: "expression",
                name: "custom_float_20",
                expression:
                  "fixed(sumInArray({custom_jsonfield_2}, 'importe_ventas_mensual'), 2)",
                displayStyle: "currency",
                currency: "MXN",
              },
              {
                title: {
                  en: "Total purchases per month",
                  "es-MX": "Total compras al mes",
                },
                type: "expression",
                name: "custom_float_21",
                expression:
                  "fixed(sumInArray({custom_jsonfield_2}, 'importe_de_compras_mensual'), 2)",
                displayStyle: "currency",
                currency: "MXN",
              },
              {
                title: {
                  en: "Gross margin",
                  "es-MX": "Margen bruto",
                },
                type: "expression",
                name: "custom_float_22",
                expression:
                  "fixed(divide(subtract({custom_float_20}, {custom_float_21}), {custom_float_20}), 2)",
                displayStyle: "currency",
                currency: "MXN",
              },

              {
                title: {
                  en: "Services - Add up to 15 services",
                  "es-MX": "Añadir Servicios (hasta 15 servicios)",
                },
                name: "custom_jsonfield_3",
                valueName: "custom_jsonfield_3",
                type: "matrixdynamic",
                minRowCount: 1,
                maxRowCount: 15,
                addRowText: {
                  en: "Add",
                  "es-MX": "Añadir",
                },
                columns: [
                  {
                    title: {
                      en: "Service description",
                      "es-MX": "Descripción del servicio",
                    },
                    name: "descripcion_del_servicio",
                    cellType: "text",
                    inputType: "string",
                  },
                  {
                    title: {
                      en: "Unit of measurement",
                      "es-MX": "Unidad de medida",
                    },
                    name: "unidad_de_medida",
                    cellType: "text",
                    inputType: "string",
                  },
                  {
                    title: {
                      en: "Service price",
                      "es-MX": "Precio del servicio",
                    },
                    name: "precio_del_servicio",
                    cellType: "text",
                    inputType: "number",
                  },
                  {
                    title: {
                      en: "Consumable cost",
                      "es-MX": "Costo de consumibles",
                    },
                    name: "costo_de_consumibles",
                    cellType: "text",
                    inputType: "number",
                  },
                  {
                    title: {
                      en: "Number of monthly services",
                      "es-MX": "Número de servicios mensual",
                    },
                    name: "numero_de_servicios_mensual",
                    cellType: "text",
                    inputType: "number",
                  },
                  {
                    title: {
                      en: "Monthly services amount",
                      "es-MX": "Importe de servicios mensual",
                    },
                    cellType: "expression",
                    name: "Importe_de_servicios_mensual",
                    expression:
                      "{row.precio_del_servicio} * {row.numero_de_servicios_mensual}",
                    displayStyle: "currency",
                    currency: "MXN",
                  },
                  {
                    title: {
                      en: "Monthly consumables amount",
                      "es-MX": "Importe de consumibles mensual",
                    },
                    cellType: "expression",
                    name: "importe_de_consumibles_mensual",
                    expression:
                      "{row.costo_de_consumibles} * {row.numero_de_servicios_mensual}",
                    displayStyle: "currency",
                    currency: "MXN",
                  },
                ],
              },
              {
                title: {
                  en: "Total amount of consumables",
                  "es-MX": "Total importe de consumibles",
                },
                type: "expression",
                name: "custom_float_23",
                expression:
                  "fixed(sumInArray({custom_jsonfield_3}, 'importe_de_consumibles_mensual'), 2)",
                displayStyle: "currency",
                currency: "MXN",
              },
              {
                title: {
                  en: "Total amount of services",
                  "es-MX": "Total importe de servicios",
                },
                type: "expression",
                name: "custom_float_24",
                expression:
                  "fixed(sumInArray({custom_jsonfield_3}, 'Importe_de_servicios_mensual'), 2)",
                displayStyle: "currency",
                currency: "MXN",
              },
              {
                title: {
                  en: "Gross Margin",
                  "es-MX": "Margen bruto",
                },
                type: "expression",
                name: "custom_float_25",
                expression:
                  "fixed(divide(subtract({custom_float_24}, {custom_float_23}), {custom_float_24}), 2)",
                displayStyle: "currency",
                currency: "MXN",
              },
            ],
          },
          {
            type: "panel",
            visibleIf: `{custom_text_84} == 'industria'`,
            elements: [
              {
                title: {
                  en: "How much was your sale for the previous month?",
                  "es-MX": "¿Cuánto fue su venta del mes anterior?",
                },
                type: "text",
                inputType: "number",
                name: "custom_float_40",
                isRequired: required,
              },
              {
                title: {
                  en: "What are the products that you sell the most on a daily basis? Consider the average of good, regular and bad days.",
                  "es-MX":
                    "¿Cuáles son los productos que más vende a diario? considerar el promedio de dias bueno, regular y malos.",
                },
                name: "custom_jsonfield_4",
                valueName: "custom_jsonfield_4",
                type: "matrixdynamic",
                minRowCount: 1,
                maxRowCount: 15,
                isRequired: false,
                addRowText: {
                  en: "Add",
                  "es-MX": "Añadir",
                },
                columns: [
                  {
                    title: {
                      en: "Product",
                      "es-MX": "Producto",
                    },
                    name: "producto",
                    isRequired: required,
                    cellType: "text",
                    inputType: "string",
                  },
                  {
                    title: {
                      en: "Unit",
                      "es-MX": "Unidad",
                    },
                    name: "unidad",
                    isRequired: required,
                    cellType: "text",
                    inputType: "string",
                  },
                  {
                    title: {
                      en: "Salesunit price",
                      "es-MX": "Precio unitario de venta",
                    },
                    name: "precio_unitario_de_venta",
                    isRequired: required,
                    cellType: "text",
                    inputType: "number",
                  },
                  {
                    title: {
                      en: "Monthly amount",
                      "es-MX": "Cantidad mensual",
                    },
                    name: "cantidad_mensual",
                    isRequired: required,
                    cellType: "text",
                    inputType: "number",
                  },
                  {
                    title: {
                      en: "Amount of sales per month",
                      "es-MX": "Importe de las ventas al mes",
                    },
                    cellType: "expression",
                    name: "importe_de_las_ventas_al_mes",
                    expression:
                      "{row.cantidad_mensual} * {row.precio_unitario_de_venta}",
                    displayStyle: "currency",
                    currency: "MXN",
                  },
                ],
              },
              {
                title: {
                  en: "Total",
                  "es-MX": "Total",
                },
                type: "expression",
                name: "custom_float_26",
                expression:
                  "fixed(sumInArray({custom_jsonfield_4}, 'importe_de_las_ventas_al_mes'), 2)",
                displayStyle: "currency",
                currency: "MXN",
              },
              {
                title: {
                  en: "What are the products that you sell the most on a daily basis?",
                  "es-MX":
                    "¿Cuáles son los productos que más compra a diario? - Insumos (materia prima)",
                },
                name: "custom_jsonfield_5",
                valueName: "custom_jsonfield_5",
                type: "matrixdynamic",
                minRowCount: 1,
                maxRowCount: 15,
                isRequired: false,
                addRowText: {
                  en: "Add",
                  "es-MX": "Añadir",
                },
                columns: [
                  {
                    title: {
                      en: "Inputs (raw material)",
                      "es-MX": "Insumos (materia prima)",
                    },
                    name: "insumos",
                    isRequired: required,
                    cellType: "text",
                    inputType: "string",
                  },
                  {
                    title: {
                      en: "Unit",
                      "es-MX": "Unidad",
                    },
                    name: "unidad",
                    isRequired: required,
                    cellType: "text",
                    inputType: "string",
                  },
                  {
                    title: {
                      en: "Unit purchase price",
                      "es-MX": "Precio unitario de compra",
                    },
                    name: "precio_unitario_de_compra",
                    isRequired: required,
                    cellType: "text",
                    inputType: "number",
                  },
                  {
                    title: {
                      en: "Monthly amount",
                      "es-MX": "Cantidad mensual",
                    },
                    name: "cantidad_mensual",
                    isRequired: required,
                    cellType: "text",
                    inputType: "number",
                  },
                  {
                    title: {
                      en: "Amount of purchases per month",
                      "es-MX": "Importe de las compras al mes",
                    },
                    cellType: "expression",
                    name: "importe_de_las_compras_al_mes",
                    expression:
                      "{row.precio_unitario_de_compra} * {row.cantidad_mensual}",
                    displayStyle: "currency",
                    currency: "MXN",
                  },
                ],
              },
              {
                title: {
                  en: "Total",
                  "es-MX": "Total",
                },
                type: "expression",
                name: "custom_float_27",
                expression:
                  "fixed(sumInArray({custom_jsonfield_5}, 'importe_de_las_compras_al_mes'), 2)",
                displayStyle: "currency",
                currency: "MXN",
              },
              {
                title: {
                  en: "Gross margin",
                  "es-MX": "Margen bruto",
                },
                type: "expression",
                name: "custom_float_28",
                expression:
                  "fixed(divide(subtract({custom_float_26}, {custom_float_27}), {custom_float_26}), 2)",
                displayStyle: "currency",
                currency: "MXN",
              },
            ],
          },
        ],
      },
    ],
  },
  // Page 2
  {
    title: {
      en: "Analysis of monthly seasonality",
      "es-MX": "Análisis de la estacionalidad mensual",
    },

    elements: [
      {
        type: "matrixdropdown",
        name: "custom_jsonfield_12",
        title: {
          en: "Seasonality of the month",
          "es-MX": "Estacionalidad del mes",
        },
        description: {
          "es-MX":
            "Criterio de estacionalidad: 100% Bueno, 80% Regular, 60% Malo",
          en: "Seasonality note: 100% Good, 80% Regular, 60% Bad",
        },
        columns: [
          {
            name: "estacionalidad",
            title: {
              en: "Seasonality",
              "es-MX": "Estacionalidad",
            },
            cellType: "dropdown",
            isRequired: required,
            choices: [
              {
                value: "60",
                text: { en: "60%", "es-MX": "60%" },
              },
              {
                value: "80",
                text: { en: "80%", "es-MX": "80%" },
              },
              {
                value: "100",
                text: { en: "100%", "es-MX": "100%" },
              },
            ],
          },
        ],
        rows: ["Mes actual", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      },
      {
        title: {
          en: "Monthly economic information",
          "es-MX": "Información económica (Mensual)",
        },
        type: "panel",
        elements: [
          {
            type: "panel",
            title: {
              en: "Business",
              "es-MX": "Negocio",
            },
            elements: [
              {
                title: {
                  en: "How much cash do you have?",
                  "es-MX": "¿Con cuánto efectivo cuenta?",
                },
                type: "text",
                inputType: "number",
                name: "custom_text_137",
              },
              {
                title: {
                  en: "Do you have money in a bank or financial institution?",
                  "es-MX": "¿Tiene usted dinero en algún banco o financiera?",
                },
                type: "dropdown",
                name: "custom_text_138",
                choices: [
                  {
                    value: "si",
                    text: { en: "Yes", "es-MX": "Sí" },
                  },
                  {
                    value: "no",
                    text: { en: "No", "es-MX": "No" },
                  },
                ],
              },
              {
                title: {
                  en: "If yes, indicate amount",
                  "es-MX": "En caso de sí, indicar monto",
                },
                visibleIf: `{custom_text_138} == 'si'`,
                type: "text",
                inputType: "number",
                name: "custom_float_29",
              },
            ],
          },
          {
            title: {
              en: "How much is the amount of your savings and investments?",
              "es-MX":
                "¿A cuánto asciende el monto de sus ahorros e inversiones?",
            },
            type: "text",
            inputType: "number",
            name: "custom_float_30",
          },
        ],
      },
      {
        title: {
          en: "Business operating expenses for:",
          "es-MX": "Gastos de operación del negocio por:",
        },
        type: "panel",
        elements: [
          {
            title: {
              en: "You have employees, how much do you spend for salary payments?",
              "es-MX": "Tiene empleados, ¿Cuánto gasta por pago de sueldos?",
            },
            type: "text",
            inputType: "number",
            name: "custom_float_31",
          },
          {
            title: {
              en: "Transportation or freight of merchandise",
              "es-MX": "Transporte o flete de mercancía",
            },
            type: "text",
            inputType: "number",
            name: "custom_float_32",
          },
          {
            title: {
              en: "Payment of taxes, water, electricity, telephone, rent of the premises",
              "es-MX":
                "Pago de impuestos, agua, luz, teléfono, renta del local​",
            },
            type: "text",
            inputType: "number",
            name: "custom_float_33",
          },
          {
            title: {
              en: "Other expenses generated by your business (consumables / supplies),",
              "es-MX":
                "Otros gastos que genere su negocio (consumibles/insumos)",
            },
            type: "text",
            inputType: "number",
            name: "custom_float_34",
          },
        ],
      },

      {
        title: {
          en: "Family income and expenses",
          "es-MX": "Gastos e ingresos familiares",
        },
        type: "panel",
        elements: [
          {
            title: {
              en: "How much do you spend on food, rent, water, electricity, telephone, gas, education, medical expenses?",
              "es-MX":
                "¿Cuánto gasta en alimentación, pago de renta, agua, luz, teléfono, gas, educación, gastos médicos?",
            },
            type: "text",
            inputType: "number",
            name: "custom_float_35",
          },
          {
            title: {
              en: "You have any other family income, how much is the income?",
              "es-MX":
                "Tiene algún otro ingreso familiar, ¿A cuánto asciende el monto del ingreso?​",
            },
            type: "dropdown",
            name: "custom_text_139",
            choices: [
              {
                value: "si",
                text: { en: "Yes", "es-MX": "Sí" },
              },
              {
                value: "no",
                text: { en: "No", "es-MX": "No" },
              },
            ],
          },
          {
            title: {
              en: "How much is the income?",
              "es-MX": "¿A cuánto asciende el monto del ingreso?​",
            },
            visibleIf: `{custom_text_139} == 'si'`,
            type: "text",
            inputType: "number",
            name: "custom_float_36",
          },
        ],
      },
      {
        type: "panel",
        title: {
          en: "Liabilities reported in SIC",
          "es-MX": "Pasivos reportados en SIC",
        },
        elements: [
          {
            title: {
              en: "According to the SIC, how much do you pay monthly?",
              "es-MX": "De acuerdo al SIC, ¿cuánto paga mensualmente?",
            },
            type: "text",
            inputType: "number",
            name: "custom_float_37",
          },
          {
            title: {
              en: "According to the SIC, in how many months will you pay the debt?",
              "es-MX": "De acuerdo al SIC, ¿en cuántos meses pagará la deuda?",
            },
            type: "text",
            inputType: "number",
            name: "custom_float_38",
          },
        ],
      },
    ],
  },
  // Page 3
  {
    title: {
      en: "Guarantor information",
      "es-MX": "Datos de la garantía",
    },
    elements: [
      {
        title: {
          en: "Do you want to have a guarantor?",
          "es-MX": "¿Quieres agregar una garantía?",
        },
        type: "dropdown",
        isRequired: "{amount} <= 50000",
        visibleIf: "{amount} <= 50000",
        name: "custom_text_130",
        choices: [
          {
            value: "si",
            text: { en: "Yes", "es-MX": "Sí" },
          },
          {
            value: "no",
            text: { en: "No", "es-MX": "No" },
          },
        ],
      },
      {
        name: "custom_jsonfield_13",
        valueName: "custom_jsonfield_13",
        type: "matrixdynamic",
        visibleIf: "{custom_text_130} == 'si' || {amount} > 50000",
        minRowCount: 1,
        maxRowCount: 2,
        columnLayout: "vertical",
        rowCount: 1,
        addRowLocation: "bottom",
        isRequired: required,
        addRowText: { en: "Add", "es-MX": "Añadir" },
        title: {
          en: "Add guarantees",
          "es-MX":
            "Agregar garantías",
        },
        columns: [
          //Dropdown
          {
            title: {
              en: "Guarantee type",
              "es-MX": "Tipo de garantía",
            },
            cellType: "dropdown",
            isRequired: required,
            name: "tipo_de_garantia",
            choices: [
              {
                value: "factura",
                text: { en: "Invoice", "es-MX": "Factura" },
              },
              {
                value: "Documento que ampara la propiedad inscrito",
                text: {
                  en: "Document that protects the registered property",
                  "es-MX": "Documento de ampara la propiedad inscrito",
                },
              },
              {
                value: "Documento que ampara propiedad no inscrito",
                text: {
                  en: "Document that protects the property not registered",
                  "es-MX": "Documento que ampara propiedad no inscrito",
                },
              },
            ],
          },
          //Invoice
          {
            title: {
              en: "Owner",
              "es-MX": "Propietario",
            },
            cellType: "text",
            name: "propietario",
            isRequired: required,
            visibleIf: "{row.tipo_de_garantia} == 'factura'",
          },
          {
            title: {
              en: "Current value",
              "es-MX": "Valor actual",
            },
            cellType: "text",
            inputType: "number",
            name: "valor_actual",
            isRequired: required,
            visibleIf: "{row.tipo_de_garantia} == 'factura'",
          },
          {
            title: {
              en: "Acquisition date",
              "es-MX": "Fecha de adquisición",
            },
            cellType: "text",
            inputType: "date",
            name: "fecha_de_adquisicion",
            isRequired: required,
            visibleIf: "{row.tipo_de_garantia} == 'factura'",
          },
          {
            title: {
              en: "Years of use",
              "es-MX": "Años de uso",
            },
            cellType: "text",
            inputType: "number",
            name: "anos_de_uso",
            isRequired: required,
            visibleIf: "{row.tipo_de_garantia} == 'factura'",
          },
          {
            title: {
              en: "Brand",
              "es-MX": "Marca",
            },
            cellType: "text",
            name: "marca",
            isRequired: required,
            visibleIf: "{row.tipo_de_garantia} == 'factura'",
          },
          {
            title: {
              en: "Model",
              "es-MX": "Modelo",
            },
            cellType: "text",
            name: "modelo",
            isRequired: required,
            visibleIf: "{row.tipo_de_garantia} == 'factura'",
          },
          {
            title: {
              en: "Invoice number",
              "es-MX": "N. de factura",
            },
            cellType: "text",
            name: "n_de_factura",
            isRequired: required,
            visibleIf: "{row.tipo_de_garantia} == 'factura'",
          },
          {
            title: {
              en: "Plate No.",
              "es-MX": "N. de placa",
            },
            cellType: "text",
            name: "n_de_placa",
            isRequired: required,
            visibleIf: "{row.tipo_de_garantia} == 'factura'",
          },
          {
            title: {
              en: "Serial No.",
              "es-MX": "N. de serie",
            },
            cellType: "text",
            name: "n_de_serie",
            isRequired: required,
            visibleIf: "{row.tipo_de_garantia} == 'factura'",
          },
          {
            title: {
              en: "No. motor",
              "es-MX": "N. motor",
            },
            cellType: "text",
            name: "n_motor",
            isRequired: required,
            visibleIf: "{row.tipo_de_garantia} == 'factura'",
          },
          {
            title: {
              en: "Warranty description",
              "es-MX": "Descripción de la garantía",
            },
            cellType: "text",
            name: "descripcion_de_la_garantia",
            isRequired: required,
            visibleIf: "{row.tipo_de_garantia} == 'factura'",
          },
          {
            title: {
              en: "Upload document",
              "es-MX": "Subir documento",
            },
            cellType: "file_uploader",
            accept: "image/*,application/pdf",
            name: "documento_de_garantia",
            isRequired: required,
            visibleIf: "{row.tipo_de_garantia} == 'factura'",
          },
          //Document
          {
            title: {
              en: "Owner",
              "es-MX": "Propietario",
            },
            cellType: "text",
            name: "propietario",
            isRequired: required,
            visibleIf:
            "{row.tipo_de_garantia} == 'Documento que ampara la propiedad inscrito' or {row.tipo_de_garantia} == 'Documento que ampara propiedad no inscrito'",
          },
          {
            title: {
              en: "Current value",
              "es-MX": "Valor actual",
            },
            cellType: "text",
            inputType: "number",
            name: "valor_actual",
            isRequired: required,
            visibleIf:
            "{row.tipo_de_garantia} == 'Documento que ampara la propiedad inscrito' or {row.tipo_de_garantia} == 'Documento que ampara propiedad no inscrito'",
          },
          {
            title: {
              en: "Acquisition date",
              "es-MX": "Fecha de adquisición",
            },
            cellType: "text",
            inputType: "date",
            name: "fecha_de_adquisicion",
            isRequired: required,
            visibleIf:
            "{row.tipo_de_garantia} == 'Documento que ampara la propiedad inscrito' or {row.tipo_de_garantia} == 'Documento que ampara propiedad no inscrito'",
          },
          {
            title: {
              en: "Measures",
              "es-MX": "Medidas",
            },
            cellType: "text",
            name: "medidas",
            isRequired: required,
            visibleIf:
            "{row.tipo_de_garantia} == 'Documento que ampara la propiedad inscrito' or {row.tipo_de_garantia} == 'Documento que ampara propiedad no inscrito'",
          },
          {
            title: {
              en: "Surface",
              "es-MX": "Superficie",
            },
            cellType: "text",
            name: "superficie",
            isRequired: required,
            visibleIf:
            "{row.tipo_de_garantia} == 'Documento que ampara la propiedad inscrito' or {row.tipo_de_garantia} == 'Documento que ampara propiedad no inscrito'",
          },
          {
            title: {
              en: "Document type",
              "es-MX": "Tipo de documento",
            },
            cellType: "text",
            name: "tipo_de_documento",
            isRequired: required,
            visibleIf:
            "{row.tipo_de_garantia} == 'Documento que ampara la propiedad inscrito' or {row.tipo_de_garantia} == 'Documento que ampara propiedad no inscrito'",
          },
          {
            title: {
              en: "Registration data in RPPyC",
              "es-MX": "Dato de inscripción en RPPyC",
            },
            cellType: "dropdown",
            name: "dato_de_inscripcion_en_rppyc",
            isRequired: required,
            visibleIf:
            "{row.tipo_de_garantia} == 'Documento que ampara la propiedad inscrito' or {row.tipo_de_garantia} == 'Documento que ampara propiedad no inscrito'",
            choices: [
              {
                value: "si",
                text: { en: "Yes", "es-MX": "Sí" },
              },
              {
                value: "no",
                text: { en: "No", "es-MX": "No" },
              },
            ],
          },
          {
            cellType: "html",
            html: "<strong>Colindancias</strong>",
            visibleIf:
            "{row.tipo_de_garantia} == 'Documento que ampara la propiedad inscrito' or {row.tipo_de_garantia} == 'Documento que ampara propiedad no inscrito'",
          },
          {
            title: {
              en: "North",
              "es-MX": "Norte",
            },
            cellType: "text",
            name: "norte",
            isRequired: required,
            visibleIf:
            "{row.tipo_de_garantia} == 'Documento que ampara la propiedad inscrito' or {row.tipo_de_garantia} == 'Documento que ampara propiedad no inscrito'",
          },
          {
            title: {
              en: "South",
              "es-MX": "Sur",
            },
            cellType: "text",
            name: "sur",
            isRequired: required,
            visibleIf:
            "{row.tipo_de_garantia} == 'Documento que ampara la propiedad inscrito' or {row.tipo_de_garantia} == 'Documento que ampara propiedad no inscrito'",
          },
          {
            title: {
              en: "East",
              "es-MX": "Oriente",
            },
            cellType: "text",
            name: "oriente",
            isRequired: required,
            visibleIf:
            "{row.tipo_de_garantia} == 'Documento que ampara la propiedad inscrito' or {row.tipo_de_garantia} == 'Documento que ampara propiedad no inscrito'",
          },
          {
            title: {
              en: "West",
              "es-MX": "Poniente",
            },
            cellType: "text",
            name: "poniente",
            isRequired: required,
            visibleIf:
            "{row.tipo_de_garantia} == 'Documento que ampara la propiedad inscrito' or {row.tipo_de_garantia} == 'Documento que ampara propiedad no inscrito'",
          },
          {
            title: {
              en: "Warranty description",
              "es-MX": "Descripción de la garantía ",
            },
            cellType: "text",
            name: "descripcion_de_la_garantia",
            isRequired: required,
            visibleIf:
            "{row.tipo_de_garantia} == 'Documento que ampara la propiedad inscrito' or {row.tipo_de_garantia} == 'Documento que ampara propiedad no inscrito'",
          },
          {
            title: {
              en: "Upload document",
              "es-MX": "Subir documento",
            },
            cellType: "file_uploader",
            accept: "image/*,application/pdf",
            name: "documento_de_garantia",
            isRequired: required,
            visibleIf:
            "{row.tipo_de_garantia} == 'Documento que ampara la propiedad inscrito' or {row.tipo_de_garantia} == 'Documento que ampara propiedad no inscrito'",
          }
        ],
      }
    ],
  },
  // Page 4
  {
    title: {
      en: "References",
      "es-MX": "Referencias",
    },
    elements: [
      {
        name: "custom_jsonfield_8",
        valueName: "custom_jsonfield_8",
        type: "matrixdynamic",
        minRowCount: 1,
        maxRowCount: 2,
        columnLayout: "vertical",
        rowCount: 1,
        addRowLocation: "bottom",
        isRequired: true,
        addRowText: { en: "Add", "es-MX": "Añadir" },
        title: {
          en: "References with neighbors or clients (made by the commercial executive)",
          "es-MX":
            "Referencias con vecinos o clientes (realizado por el ejecutivo comercial)",
        },
        columns: [
          {
            title: {
              en: "Full name",
              "es-MX": "Nombre completo",
            },
            cellType: "text",
            name: "nombre_completo",
            isRequired: required,
          },
          {
            title: {
              en: "Address",
              "es-MX": "Domicilio",
            },
            cellType: "text",
            name: "domicilio",
            isRequired: required,
          },
          {
            title: {
              en: "Observations",
              "es-MX": "Observaciones",
            },
            cellType: "text",
            name: "observaciones",
            isRequired: required,
          },
          {
            title: {
              en: "Years of knowing",
              "es-MX": "Años de conocer",
            },
            cellType: "text",
            inputType: "number",
            name: "años_de_conocer",
            isRequired: required,
          },
        ],
      },
    ],
  },
  // Page 5
  {
    title: {
      en: "Sign",
      "es-MX": "Firma",
    },
    innerIndent: 1,
    name: "",
    elements: [
      {
        type: "panel",
        elements: [
          {
            html: {
              en: "Through this and without additional authorization to this, I irrevocably authorize Solution Asea S.A. de C.V. S.F.P. to make use of the data of my INE before the National Electoral Institute solely for the purposes of the financing that I request, the above to validate my identity and subject myself to what is strictly agreed upon herein. I declare under protest of saying the truth that the information recorded here is true and that the origin of the funds with which the corresponding contract derived from this application will be fulfilled comes from legal sources. In the same way, I declare to be the real owner of the resources. ",
              "es-MX":
                "Mediante la presente y sin mediar autorización adicional a la presente autorizo de manera irrevocable a Solución Asea S.A. de C.V. S.F.P. para hacer uso de los datos de mi INE ante el Instituto Nacional Electoral únicamente para efectos del financiamiento que solicito, lo anterior para validar mi identidad y sujetarme a lo estrictamente pactado en la presente. Declaro bajo protesta de decir verdad que la información aquí asentada es cierta y que el origen de los fondos con los que se dara cumplimiento al contrato correspondiente que se derive de la presente solicitud, procede de fuentes lícitas. De igual forma declaro ser el propietario real de los recursos.",
            },
            type: "html",
          },
          {
            html: {
              en: "At the signing of this manifesto under protest of saying the truth that I fully know the content of the Privacy Notice that was read to me by the staff of Soluciones Asea S.A. de C.V. S.F.P., making me aware of the scope and legal effects of said document, which I express my consent to the signing of this document for the purposes of any consultation, I can do so through the website: www.credicapital.com.mx ",
              "es-MX":
                "A la firma de la presente manifiesto bajo protesta de decir verdad que conozco plenamente el contenido del Aviso de Privacidad que me fue leido por personal de Solución Asea S.A. de C.V. S.F.P., haciendome del conocimiento los alcances  y efectos legales que tiene dicho documento, mismo que expreso mi consentimiento a la firma del presente para efectos de cualquier consulta podré realizarlo a travez de la página web: www.credicapital.com.mx",
            },
            type: "html",
          },
          {
            title: {
              en: "executives name",
              "es-MX": "Nombre completo del ejecutivo",
            },
            type: "text",
            isRequired: required,
            name: "custom_text_136",
          },
          {
            title: {
              en: "Executive digital signature",
              "es-MX": "Firma digital ejecutivo",
            },
            type: "signaturepad",
            isRequired: required,
            name: "file_4",
          },
          {
            title: {
              en: "Client's name",
              "es-MX": "Nombre completo del solicitante",
            },
            type: "expression",
            isRequired: required,
            expression: `getFullName({first_name},{middle_name},{surname},{second_surname})`,
          },
          {
            title: {
              en: "Executive digital signature",
              "es-MX": "Firma digital del cliente",
            },
            type: "signaturepad",
            isRequired: required,
            name: "file_10",
          },
        ],
      },
    ],
  },
];
