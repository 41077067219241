import { SurveyComponent } from "../../../../components/survey/survey_component";
import env from "../../../../env";
import { expressions } from "../../../../survey/validators";

const required = env.ENDPOINT !== "local";

export const applicant_credicapital = (clientUrlForCatalog) =>
  new SurveyComponent({
    title: {
      en: "Applicant information",
      "es-MX": "Datos del solicitante",
    },
    description: {
      en: "Please complete the required information about the applicant.",
      "es-MX":
        "Por favor, complete la información requerida sobre el aplicante.",
    },
    elements: [
      // fetch from credit check
      {
        title: { en: "Names", "es-MX": "Nombres" },
        type: "text",
        name: "first_name",
        isRequired: required,
      },
      {
        title: { en: "Last name", "es-MX": "Apellido paterno" },
        type: "text",
        name: "surname",
        isRequired: required,
      },
      {
        title: { en: "Mother's last name", "es-MX": "Apellido materno" },
        type: "text",
        name: "second_surname",
      },
      {
        title: {
          en: "Full name (without abbreviations)",
          "es-MX": "Nombre completo(sin abreviaturas)",
        },
        type: "expression",
        expression: "getFullName({first_name},{surname},{second_surname})",
        isRequired: required,
      },
      {
        title: {
          en: "To verify the identity of the applicant, upload a profile photo",
          "es-MX":
            "Para verificar la identidad del solicitante, cargue una foto de perfil*",
        },
        type: "file_uploader",
        accept: "image/*,application/pdf",
        name: "id_file_with_avatar",
        isRequired: required,
      },
      {
        title: { en: "Sex", "es-MX": "Sexo" },
        type: "text",
        name: "gender",
        isRequired: required,
        readOnly: true,
      },
      {
        title: { en: "Date of birth", "es-MX": "Fecha de nacimiento*" },
        type: "text",
        name: "date_of_birth",
        isRequired: required,
        inputType: "date",
        readOnly: true,
        validators: [
          {
            type: "expression",
            text: {
              en: "You must be older than 21 and younger than 75 to apply",
              "es-MX":
                "Debes tener más de 21 y menos de 75 años menos para postularte.",
            },
            expression:
              "(age({date_of_birth}) > 21) && (age({date_of_birth}) < 75)",
          },
        ],
      },
      {
        title: {
          en: "Federal entity of birth",
          "es-MX": "Entidad federativa de nacimiento*",
        },
        type: "text",
        name: "custom_text_6",
        isRequired: required,
        readOnly: true
      },
      {
        title: { en: "Country of birth", "es-MX": "País de nacimiento*" },
        type: "text",
        name: "home_country",
        isRequired: required,
      },
      {
        title: { en: "Nationality", "es-MX": "Nacionalidad*" },
        type: "text",
        name: "nationality",
        isRequired: required,
      },
      {
        title: { en: "Postal Code", "es-MX": "Código postal*" },
        type: "text",
        isRequired: required,
        readOnly: "true",
        name: "po_box_postal_code",
      },
      {
        title: { en: "Address", "es-MX": "Domicilio*" },
        type: "text",
        name: "home_street",
        readOnly: "true",
        isRequired: required,
      },
      {
        title: { en: "Municipality", "es-MX": "Municipio*" },
        type: "text",
        isRequired: required,
        readOnly: "true",
        name: "custom_text_2",
      },
      {
        title: { en: "Estate", "es-MX": "Estado*" },
        type: "text",
        isRequired: required,
        readOnly: "true",
        name: "custom_text_3",
      },
      {
        title: { en: "Colonia", "es-MX": "Colonia*" },
        type: "text",
        isRequired: required,
        readOnly: "true",
        name: "custom_text_11",
      },
      {
        title: {
          en: "Address reference",
          "es-MX": "Referencia del domicilio*",
        },
        type: "text",
        name: "custom_text_13",
        isRequired: required,
      },
      {
        title: { en: "Business age", "es-MX": "Antigüedad del negocio*" },
        type: "text",
        name: "custom_text_14",
        isRequired: required,
      },
      {
        type: "panel",
        title: {
          en: "Phone numbers",
          "es-MX": "Números de teléfono",
        },
        isRequired: true,
        requiredErrorText: {
          en: "Please enter at least one phone number",
          "es-MX": "Por favor ingrese al menos un número de teléfono",
        },
        elements: [
          {
            title: {
              en: "Mobile phone number",
              "es-MX": "Número de teléfono Celular",
            },
            type: "phone",
            name: "phone_number",
            validators: [
              expressions.phone_en("phone_number"),
              expressions.phone_length("phone_number"),
            ],
          },
          {
            title: {
              en: "Home Phone number",
              "es-MX": "Número de teléfono Casa",
            },
            type: "phone",
            name: "custom_text_12",
            validators: [
              expressions.phone_en("custom_text_12"),
              expressions.phone_length("custom_text_12"),
            ],
          },
          {
            title: {
              en: " Work Phone number",
              "es-MX": "Número de teléfono Trabajo",
            },
            type: "phone",
            name: "custom_text_75",
            validators: [
              expressions.phone_en("custom_text_75"),
              expressions.phone_length("custom_text_75"),
            ],
          },
        ],
      },
      {
        type: "dropdown",
        name: "custom_text_15",
        title: {
          en: "Occupation, profession or economic activity",
          "es-MX": "Ocupación, profesión o actividad económica*",
        },
        renderAs: "select2",
        isRequired: required,
        choicesByUrl: {
          url: `${clientUrlForCatalog}/api/actividadeconomica/actividades-economicas`,
          valueName: "Descripcion",
        },
      },
    ],
  });
