import { SurveyComponent } from "../../../components/survey/survey_component";
import env from "../../../env";

const required = env.ENDPOINT !== "local";

export const yourBusiness = new SurveyComponent({
  title: { en: "Loan and business", ar: "القرض والعمل" },
  name: "business",

  elements: [
    {
      title: { en: "Loan Amount (JOD) *", ar: "قيمة القرض (دينار أردني) *" },
      description: { en: "In JOD", ar: "بالدينار الأردني" },
      name: "amount",
      type: "nouislider",
      step: 1,
      rangeMin: 500,
      rangeMaxStart: 10000,
      maxStep: 10000,
      rangeMax: 75000
    },

    {
      title: {
        en: "Within how many months do you wish to repay your loan? *",
        ar: "في غضون كم شهر ترغب في سداد القرض الخاص بك؟ *",
      },
      description: {
        en: "You will start paying based on your payment schedule.",
        ar: "ستبدأ بسداد تمويلك بناءاً على جدول السداد الخاص بك",
      },
      name: "repayment_period",
      type: "nouislider",
      readOnly: 1,
      step: 1,
      rangeMin: 8,
      rangeMax: 60,
    },
    {
      title: {
        en: "Tell us about your business? *",
        ar: "أخبرنا عن عملك؟ *",
      },
      name: "business_exists",
      valueName: "business_status",
      type: "radiogroup",
      isRequired: required,
      choices: [
        {
          value: "new",
          text: { en: "New Business (Idea)", ar: "مشروع عمل جديد (فكرة)" },
        },
        {
          value: "existing",
          text: { en: "Existing Business", ar: "عمل قائم" },
        },
      ],
    },
    {
      title: { en: "Business Name *", ar: "اسم العمل *" },
      name: "title",
      type: "text",
      isRequired: required,
    },
    {
      title: {
        en: "Business Owner *",
        ar: "اسم مالك العمل *",
      },
      name: "business_owner",
      type: "text",
      isRequired: required,
    },
    {
      title: {
        en: "Business Type (Nature) *",
        ar: "نوع العمل (طبيعة العمل) *",
      },
      description: {
        en: "For example a barbershop, tailoring, kindergarten, etc.",
        ar: "على سبيل المثال، حلاقة، خياطة، رياض الأطفال، إلخ.",
      },
      name: "business_nature",
      type: "text",
      isRequired: required,
    },
    {
      title: {
        en: "Expected Monthly Income (JOD) *",
        ar: "الدخل الشهري المتوقع *",
      },
      name: "business_income",
      type: "text",
      isRequired: required,
    },
    {
      title: { en: "Describe your business *", ar: "صف لنا عملك *" },
      description: {
        en:
          "Do you want to add more? Having a good description of the project helps your assessment.",
        ar:
          "هل تود بإخبارنا بالمزيد عن مشروعك؟ تقديم وصف جيد عن مشروعك يساعدنا في في تقييمه",
      },
      name: "description",
      type: "comment",
      isRequired: required,
    },

    {
      title: { en: "Business Start Date *", ar: "تاريخ بدء العمل *" },
      description: {
        en: "When do you plan to open your business?",
        ar:
          "إذا لم تبدأ بمشروعك بعد، قم من فضلك بتحديد التاريخ الذي ستبدأ به مشروعك",
      },
      name: "starting_date",
      type: "text",
      inputType: "date",
      isRequired: required,
      visibleIf: "{business_exists} == 'new'",
    },

    {
      title: { en: "Business Start Date *", ar: "تاريخ بدء العمل *" },
      description: {
        en: "When did you start your business?",
        ar: "متى بدأت بمشروعك؟",
      },
      name: "starting_date",
      type: "text",
      inputType: "date",
      isRequired: required,
      visibleIf: "{business_exists} != 'new'",
    },
    {
      title: {
        en: "Is your business formally registered? *",
        ar: "هل عملك مسجّل بشكل رسمي؟ *",
      },
      name: "formal_registration",
      type: "radiogroup",
      isRequired: required,
      choices: [
        { value: "yes", text: { en: "Yes", ar: "نعم" } },
        { value: "no", text: { en: "No", ar: "لا" } },
      ],
    },
    {
      title: {
        en: "Careers license *",
        ar: "رخصة مهن *",
      },
      visibleIf: "{formal_registration} == 'yes",
      name: "careers_license",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
    },
    {
      title: {
        en: "Commercial Registration *",
        ar: "سجل تجاري *",
      },
      visibleIf: "{formal_registration} == 'yes",
      name: "commercial_registration",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
    },
    {
      title: {
        en: "Lease / Ownership Contract *",
        ar: "عقد إيجار / ملكية *",
      },
      visibleIf: "{formal_registration} == 'yes",
      name: "lease_contract",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required, // only first one is required
    },
  ],
});
