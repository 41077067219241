import { SurveyComponent } from "../../../components/survey/survey_component";
import { expressions } from "../../../survey/validators";
import env from "../../../env";

const required = env.ENDPOINT !== 'local';

export const references = new SurveyComponent({
  title: {
    en: 'References',
    ar: 'المعرفين'
  },
  name: 'references',

  elements: [

    {
      type: "html",
      name: "reference_desc",
      html: {
        en: `<p>Please list 2 references who would like to give you a positive endorsement. For example: your (previous) employer, a colleague, teacher, advisor, etc.
          It can be anyone who can provide an insight into your work ethic. As long as the person is not your guarantor. </p>`,
        ar: `<p>قم رجاءاً بذكر معرفين اثنين ممن يمكن أن يزودانا بمعلومات تعريفية عنك. على سبيل المثال، مديرك السابق، أو زميلك في العمل، أو معلمك أو مرشدك… إلخ. ومن الممكن أن يكون المعرف شخص قادر على تزويدنا بلمحة عن أسلوبك في العمل. تأكد من أن يختلف المعرف عن  الكفيل </p>`
      },
    },

    {
      title: {
        en: "Reference #1",
        ar: "المعرف الأول"
      },
      name: "panel_ref_1",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          title: {
            en: "What is the first and last name of your reference",
            ar: "الاسم الأول  للمعرف واسم عائلته "
          },
          name: "ref1_name",
          type: "text",
          isRequired: required,
        },
        {
          title: {
            en: "What is the phone number of {ref1_name}?",
            ar: "رقم هاتف المعرف الأول"
          },
          name: "ref1_phone",
          type: "phone",
          visibleIf: "{ref1_name} notempty",
          isRequired: required,
          validators: [
            expressions.phone_en('ref1_phone'),
            expressions.phone_length('ref1_phone')
          ]
        },
        {
          title: {
            en: "What is the relation of {ref1_name} to you?",
            ar: "ما هي علاقة المعرف بك؟ {ref1_name} "
          },
          description: {en: "e.g. your family, friend, boss", ar: "مثلا قريب لك، صديقك، مديرك"},
          name: "ref1_relation",
          type: "text",
          visibleIf: "{ref1_name} notempty",
          isRequired: required,
        }
      ]
    },

    {
      title: {
        en: "Reference #2",
        ar: "المعرف الثاني"
      },
      name: "panel_ref_2",
      type: "panel",
      innerIndent: 1,
      elements: [
        {
          title: {
            en: "What is the first and last name of your reference",
            ar: "الاسم الأول للمعرف واسم عائلته"
          },
          name: "ref2_name",
          type: "text",
          isRequired: required,
        },
        {
          title: {
            en: "What is the phone number of {ref2_name}?",
            ar: "رقم هاتف المعرف الثاني"
          },
          name: "ref2_phone",
          type: "phone",
          visibleIf: "{ref2_name} notempty",
          isRequired: required,
          validators: [
            expressions.phone_en('ref2_phone'),
            expressions.phone_length('ref2_phone')
          ]
        },
        {
          title: {
            en: "What is the relation of {ref2_name} to you?",
            ar: "ما هي علاقة المعرف بك؟ {ref2_name} "
          },
          description: {en: "e.g. your family, friend, boss", ar: "مثلا قريب لك، صديقك، مديرك"},
          name: "ref2_relation",
          type: "text",
          visibleIf: "{ref2_name} notempty",
          isRequired: required,
        }
      ]
    },
  ]
});
