import env from "../../env";
import { expressions, regexValidators } from "../../survey/validators";

const required = env.ENDPOINT !== "local";

export const intake_rw_pages_business = [
    // Page 1: Applicant Information
    {
      title: {
        en: 'Business Applicant Information',
        rw: 'Amakuru yusaba',
      },
      name: 'applicant',
      elements: [
        {
          title: { en: 'Applicant Full Name *', rw: 'Amazina yombi*' },
          name: 'last_name',
          type: 'text',
          isRequired: required,
        },
        {
          title: {
            en: 'Can you share a picture for yourself? *',
            rw: 'Waduha ifoto yawe? *',
          },
          name: 'profile_avatar',
          type: 'file_uploader',
          accept: 'image/*,application/pdf',
          isRequired: required,
        },
        {
          title: {
            en: 'Date of Birth *',
            rw: 'Itariki yamavuko *',
          },
          name: 'date_of_birth',
          type: 'text',
          inputType: 'date',
          isRequired: required,
        },
        {
          title: {
            en: 'What is your gender? *',
            rw: 'Igitsina ni ikihe? *',
          },
          name: 'gender',
          type: 'radiogroup',
          isRequired: required,
          choices: [
            { value: 'male', text: { en: 'Male', rw: 'Gabo' } },
            { value: 'female', text: { en: 'Female', rw: 'Gore' } },
          ],
        },
        {
          title: {
            en: 'What is your marital status? *',
            rw: 'Irangamimerere ni irihe? *',
          },
          name: 'marital',
          type: 'radiogroup',
          isRequired: required,
          choices: [
            { value: 'married', text: { en: 'Married', rw: 'Ndubatse' } },
            { value: 'single', text: { en: 'Single', rw: 'Ingaragu' } },
          ],
        },
        {
          title: { en: 'Full name of partner', rw: 'Amazina y’uwo mwashakanye' },
          name: 'custom_text_2',
          type: 'text',
          visibleIf: "{marital} == 'married'",
          isRequired: required,
        },
        {
          name: 'custom_text_3',
          type: 'phone',
          isRequired: required,
          visibleIf: "{marital} == 'married'",
          validators: [
            expressions.phone_en('phone_number'),
            expressions.phone_length('phone_number'),
          ],
          title: {
            en: 'Telephone number of your partner ',
            rw: 'Numero ya telephone y’uwo mwashakanye',
          },
        },
        {
          name: 'custom_text_4',
          type: 'text',
          isRequired: required,
          visibleIf: "{marital} == 'married'",
          validators: [
            {
              type: "regexvalidator",
              text: {en: "Number should be from 16 digits length",
                        rw: "Imibare igomba kuba ari 16"
                          },
              regex: "^[0-9]{16}$",
            }
          ],
          title: {
            en: 'ID number of your partners ',
            rw: "Numero y’indangamuntu",
          },
        },
        {
          title: {
            en: 'Upload the ID of your partner ',
            rw: "Shyiraho indangamuntu y’uwo mwashakanye ",
          },
          description: {
            en:
              "If you don't have an ID card upload the replacement document.",
            rw: 'Niba udafite indangamuntu shyiraho icyangombwa cyiyisimbura',
          },
          name: 'file_3',
          visibleIf: "{marital} == 'married'",
          type: 'file_uploader',
          accept: 'image/*,application/pdf',
          isRequired: required,
        },
      ],
    },
    // Page 2: Identification Information
    {
      name: 'identification',
      title: {
        en: 'Identification Information',
        rw: "Amakuru ajyanye n'umwirondoro",
      },
      elements: [
        {
          name: 'nationality',
          type: 'dropdown',
          isRequired: required,
          storeOthersAsComment: false,
          hasOther: true,
          title: {
            en: 'Nationality *',
            rw: 'Ubwenegihugu *',
          },
          choices: [
            {
              value: 'rwandan',
              text: {
                en: 'Rwandan',
                rw: 'Umunyarwanda',
              },
            },
          ],
        },
        {
          name: 'id_number',
          type: 'text',
          isRequired: required,
          validators: [
            {
              type: "regexvalidator",
              text: {en: "Number should be from 16 digits length",
                     rw: "Imibare igomba kuba ari 16"
             },
              regex: "^[0-9]{16}$",
            }
          ],
          title: {
            en: 'ID No. *',
            rw: "Nimero y'indangamuntu ​ *",
          },
        },
        {
          title: {
            en: 'Front Image (Attachment) *',
            rw: "Ifoto y'imbere y'indangamuntu *",
          },
          description: {
            en:
              "If you don't have an ID card upload the replacement document.",
            rw: 'Niba udafite indangamuntu shyiraho icyangombwa cyiyisimbura',
          },
          name: 'id_file',
          type: 'file_uploader',
          accept: 'image/*,application/pdf',
          isRequired: required,
        },
        {
          title: {
            en: 'Back Image (Attachment) *',
            rw: "Ifoto y'inyuma y'indandamuntu *",
          },
          description: {
            en:
              'If you cannot find the document now, you can close this form and come back later.',
            rw: 'Niba udafite icyangombwa nonaha, wafunga ugakomeza nyuma',
          },
          name: 'id_file_back',
          type: 'file_uploader',
          accept: 'image/*,application/pdf',
          isRequired: required,
        },
        {
          name: 'id_avatar',
          type: 'panel',
          innerIndent: 1,
          title: {
            en: 'Please verify that this card belongs to you',
            rw: 'Genzura niba ikarita ari iyawe',
          },
          elements: [
            {
              name: 'id_avatar_desc',
              type: 'html',
              description: {
                en:
                  'Please verify your identification information provided above by uploading a picture of yourself holding the provided identification information.',
                rw: 'Genzura umwirondoro watanze hejuru ushyiraho ifoto yawe',
              },
              html: {
                en:
                  'Please verify your identification information provided above by uploading a picture of yourself holding the provided identification information.',
                rw: 'Genzura umwirondoro watanze hejuru ushyiraho ifoto yawe',
              },
            },
            {
              name: 'id_avatar_desc_picture',
              type: 'html',
              html: `<img alt="example" src='${require('../../assets/images/example_card.png')}' width='200'/>`,
            },
            {
              name: 'id_file_with_avatar',
              type: 'file_uploader',
              accept: 'image/*,application/pdf',
              title: {
                en: 'Identification Picture ',
                rw: 'Ibiranga ifoto ',
              },
            },
          ],
        },
        {
          name: "custom_text_1",
          type: "text",
          isRequired: required,
          title: {
            en: "Please fill in your Equity Bank account number",
            rw: "Uzuza nimero ya konti yawe yo muri  Equity Bank",
          },
          maxLength: "13",
          validators: [
            {
              type: "regexvalidator",
              text: {
                en: "13 digits",
                rw: "13 nimero",
              },
              regex: `^[0-9]{13}$`,
            },
          ],
        },
        {
          title: { en: "How much do you want to borrow?", rw: "Urashaka kuguza amafaranga angahe?" },
          type: "text",
          inputType: "number",
          name: "amount",
          validators: [expressions.amount_greater_than_zero("amount")],
        },
      ],
    },
    // Page 3: Residence & Home Information
    {
      title: {
        en: 'Residence & Home Information',
        rw: 'Amakuru yaho utuye',
      },
      name: 'home',
      elements: [
        {
          title: {
            en: 'Where do you live?',
            rw: 'Utuye he?',
          },
          name: 'panel_home',
          type: 'panel',
          innerIndent: 1,
          elements: [
            {
              name: 'province',
              type: 'text',
              isRequired: required,
              title: {
                en: 'Province *',
                rw: 'Intara *',
              },
            },
            {
              name: 'district',
              type: 'text',
              isRequired: required,
              title: {
                en: 'District *',
                rw: 'Akarere *',
              },
            },
            {
              name: 'home_country', // Mapped to home country for now. Field on the backend will be renamed later.
              type: 'text',
              isRequired: required,
              title: {
                en: 'Sector *',
                rw: 'Umurenge *',
              },
            },
            {
              name: 'cell',
              type: 'text',
              isRequired: required,
              title: {
                en: 'Cell *',
                rw: 'Akagali *',
              },
            },
            {
              name: 'village',
              type: 'text',
              isRequired: required,
              title: {
                en: 'Village *',
                rw: 'Umudugudu *',
              },
            },
          ],
        },
        {
          name: 'phone_number',
          type: 'phone',
          isRequired: required,
          validators: [
            expressions.phone_en('phone_number'),
            expressions.phone_length('phone_number'),
          ],
          title: {
            en: 'Mobile No. 1 *',
            rw: 'Numero ya telefone *',
          },
        },
      ],
    },
 // Page 4: Production Information
 {
  name: 'production',
  title: {
    en: 'Production Information',
    rw: 'Amakuru yumusaruro', // @TODO Current translation through google translate.
  },
  description: {
    en:
      'The production information provided will be verified and confirmed by the cooperative.',
    rw:
      "Amakuru ajyanye n'umusaruro amaze gutangwa  azasuzumwa kandi yemezwe numucangamali wa cooperative", // @TODO translated as has been/ NOT will be
  },
  elements: [
    {
      name: 'last_name',
      type: 'text',
      title: {
        en: 'Name of the Applicant ',
        rw: "Amazina y'usaba",
      },
    },
    {
      name: 'cooperative_name',
      type: 'text',
      title: {
        en: 'Name of cooperative',
        rw: 'Izina rya cooperative abarizwamo',
      },
    },
    {
      title: {
        en:
          'Provide with us  production information of 3 previous agriculture seasons',
        rw: "Duhe amakuru y'umusaruro ku bihembwe bitatu bishize",
      },
      description: {
        en:
          "You can fill in information about 2 seasons in case you don't have information about 3 seasons",
        rw: "Ushobora kuzuza amakuru y'ibihembwe bibiri gusa niba ariyo ufite",
      },
      name: 'panel_home',
      type: 'panel',
      innerIndent: 1,
      elements: [
        {
          title: {
            en: 'Fill the agriculture season eg 2020A,B or C ',
            rw: "Andika igihembwe cy’ihinga urugero: 2020A,B cg C",
          },
          name: 'agricultural_season_1',
          type: 'panel',
          innerIndent: 1,
          elements: [
            {
              name: 'land_size_season_1',
              type:'text',
              title: {
                en: 'Irish Potato Cultivated Land size (hectare)',
                rw: "Ingano y'ubuso wahinzeho ibirayi (hegitare)",
              },
              validators: [
                regexValidators.max_three_decimal_places(),
              ]
            },
            {
              name: 'plot_ownership_season_1',
              type: 'radiogroup',
              isRequired: required,
              title: {
                en: 'Plot ownership *',
                rw: 'Ubutaka wahinze nubwawe? *',
              },
              choices: [
                {
                  value: true,
                  text: {
                    en: 'Yes',
                    rw: 'Yego',
                  },
                },
                {
                  value: false,
                  text: {
                    en: 'No',
                    rw: 'Oya',
                  },
                },
              ],
            },
            {
              name: 'uip_number_1',
              visibleIf: '{plot_ownership_season_1} == true',
              type: 'text',
              title: {
                en: 'If yes provide UPI',
                rw: "Niba andika numero z'ubutaka",
              },
            },
            {
              name: 'file_5',
              visibleIf: '{plot_ownership_season_1} == false',
              type: 'file_uploader',
              accept: 'image/*,application/pdf',
              title: {
                en: 'If not provide land leasing contract (upload)',
                rw: "Niba  ari oya duhe amasezerano y'ubukode",
              },
            },
            {
              name: 'supply_volume_kg_season_1',
              type: 'text',
              isRequired: required,
              title: {
                en: 'Volume of supplied production in KG',
                rw: "Ingano y'umusaruro wawe muri kilogarama ",
              },
              validators: [
                regexValidators.max_two_decimal_places(),
              ]
            },
            {
              name: 'variety_season_1',
              type: 'text',
              isRequired: required,
              title: {
                en: 'Variety',
                rw: "Ubwoko bw'ibirayi",
              },
            },
            {
              name: 'price_season_1',
              isRequired: required,
              type:'text',
              title: {
                en: 'Price/kg in RWF',
                rw: 'Igiciro wagurishije kuri kilo mu Rwf ',
              },
              validators: [
                regexValidators.max_two_decimal_places(),
              ]
            },
            {
              name: 'sales_season_1',
              type: 'text',
              isRequired: required,
              title: {
                en: 'Sales in Rwf',
                rw: 'Amafaranga winjije mu Rwf',
              },
              validators: [
                regexValidators.max_two_decimal_places(),
              ]
            },
          ],
        },
        {
          title: {
            en: 'Fill the agriculture season eg 2020A,B or C ',
            rw: "Andika igihembwe cy’ihinga urugero: 2020A,B cg C",
          },
          name: 'agricultural_season_2',
          type: 'panel',
          innerIndent: 1,
          elements: [
            {
              name: 'land_size_season_2',
              isRequired: required,
              type:'text',
              title: {
                en: 'Irish Potato Cultivated Land size (hectare)',
                rw: "Ingano y'ubuso wahinzeho ibirayi (hegitare)",
              },
              validators: [
                regexValidators.max_three_decimal_places(),
              ]
            },
            {
              name: 'plot_ownership_season_2',
              type: 'radiogroup',
              isRequired: required,
              title: {
                en: 'Plot ownership *',
                rw: 'Ubutaka wahinze nubwawe? *',
              },
              choices: [
                {
                  value: true,
                  text: {
                    en: 'Yes',
                    rw: 'Yego',
                  },
                },
                {
                  value: false,
                  text: {
                    en: 'No',
                    rw: 'Oya',
                  },
                },
              ],
            },
            {
              name: 'uip_number_2',
              visibleIf: '{plot_ownership_season_2} == true',
              type: 'text',
              title: {
                en: 'If yes provide UPI',
                rw: "Niba andika numero z'ubutaka",
              },
            },
            {
              name: 'file_6',
              visibleIf: '{plot_ownership_season_2} == false',
              type: 'file_uploader',
              accept: 'image/*,application/pdf',
              title: {
                en: 'If not provide land leasing contract (upload)',
                rw: "Niba  ari oya duhe amasezerano y'ubukode",
              },
            },
            {
              name: 'supply_volume_kg_season_2',
              isRequired: required,
              type:'text',
              title: {
                en: 'Volume of supplied production in KG',
                rw: "Ingano y'umusaruro wawe muri kilogarama ",
              },
              validators: [
                regexValidators.max_two_decimal_places(),
              ]
            },
            {
              name: 'variety_season_2',
              type: 'text',
              isRequired: required,
              title: {
                en: 'Variety',
                rw: "Ubwoko bw'ibirayi",
              },
            },
            {
              name: 'price_season_2',
              type:'text',
              isRequired: required,
              title: {
                en: 'Price/kg in RWF ',
                rw: 'Igiciro wagurishije kuri kilo mu Rwf ',
              },
              validators: [
                regexValidators.max_two_decimal_places(),
              ]
            },
            {
              name: 'sales_season_2',
              type: 'text',
              isRequired: required,
              title: {
                en: 'Sales in Rwf',
                rw: 'Amafaranga winjije mu Rwf ',
              },
              validators: [
                regexValidators.max_two_decimal_places(),
              ]
            },
          ],
        },
        {
          title: {
            en: 'Fill the agriculture season eg 2020A,B or C ',
            rw: "Andika igihembwe cy’ihinga urugero: 2020A,B cg C",
          },
          name: 'agricultural_season_3',
          type: 'panel',
          innerIndent: 1,
          elements: [
            {
              name: 'land_size_season_3',
              type:'text',
              title: {
                en: 'Irish Potato Cultivated Land size (hectare)',
                rw: "Ingano y'ubuso wahinzeho ibirayi (hegitare)",
              },
              validators: [
                regexValidators.max_three_decimal_places(),
              ]
            },
            {
              name: 'plot_ownership_season_3',
              type: 'radiogroup',
              title: {
                en: 'Plot ownership *',
                rw: 'Ubutaka wahinze nubwawe? *',
              },
              choices: [
                {
                  value: true,
                  text: {
                    en: 'Yes',
                    rw: 'Yego',
                  },
                },
                {
                  value: false,
                  text: {
                    en: 'No',
                    rw: 'Oya',
                  },
                },
              ],
            },
            {
              name: 'uip_number_3',
              visibleIf: '{plot_ownership_season_3} == true',
              type: 'text',
              title: {
                en: 'If yes provide UPI',
                rw: "Niba andika numero z'ubutaka",
              },
            },
            {
              name: 'file_7',
              visibleIf: '{plot_ownership_season_3} == false',
              type: 'file_uploader',
              accept: 'image/*,application/pdf',
              title: {
                en: 'If not provide land leasing contract (upload)',
                rw: "Niba  ari oya duhe amasezerano y'ubukode",
              },
            },
            {
              name: 'supply_volume_kg_season_3',
              type: 'text',
              title: {
                en: 'Volume of supplied production in KG',
                rw: "Ingano y'umusaruro wawe muri kilogarama ",
              },
              validators: [
                regexValidators.max_two_decimal_places(),
              ]
            },
            {
              name: 'variety_season_3',
              type: 'text',
              title: {
                en: 'Variety',
                rw: "Ubwoko bw'ibirayi",
              },
            },
            {
              name: 'price_season_3',
              type: 'text',
              title: {
                en: 'Price/kg in RWF ',
                rw: 'Igiciro wagurishije kuri kilo mu Rwf ',
              },
              validators: [
                regexValidators.max_two_decimal_places(),
              ]
            },
            {
              name: 'sales_season_3',
              type: 'text',
              title: {
                en: 'Sales in Rwf',
                rw: 'Amafaranga winjije mu Rwf ',
              },
              validators: [
                regexValidators.max_two_decimal_places(),
              ]
            },
          ],
        },
        {
          title: {
            en: 'Fill the current agriculture season',
            rw: 'Andika amakuru kugihembwe ugiye guhinga',
          },
          name: 'current_agricultural_season',
          type: 'panel',
          innerIndent: 1,
          elements: [
            {
              name: 'land_size_current_season',
              type: 'text',
              title: {
                en: 'What size of land will you cultivate (hectare)?',
                rw: 'Uzahinga kubuso bungana iki (hegitare)?',
              },
              validators: [
                regexValidators.max_three_decimal_places(),
              ]
            },
            {
              name: 'variety_current_season',
              type: 'text',
              title: {
                en: 'Which variety of potato will you cultivate?',
                rw: "Uzahinga ubuhe bwoko bw'ibirayi?",
              },
            },
          ],
        },
      ],
    },
  ],
},
// Page 5: Information related to your Guarantor
{
  title: {
    en: 'Information related to your Guarantor',
    rw: "Amakuru ajyanye n'umwishingizi wawe",
  },
  name: 'home',
  elements: [
    {
      name: 'cooperative_name',
      type: 'text',
      isRequired: required,
      title: {
        en: 'Name of the Cooperative *',
        rw: 'Izina rya Cooperative *',
      },
    },
    {
      name: 'guarantor_first_name',
      visibleIf: '{cooperative_name} notempty',
      type: 'text',
      title: {
        en: 'Name of the president of the Cooperative​',
        rw: 'Amazina yombi ya perezida wa Cooperative​',
      },
    },
    {
      name: 'guarantor_phone',
      type: 'phone',
      visibleIf: '{cooperative_name} notempty',
      isRequired: required,
      title: {
        en: 'President Mobile No. *',
        rw: 'Nimero ya telefone ya perezida wa koperative​ *',
      },
      description: {
        en:
          'We will use these phone numbers to send your  cooperative a short application form.',
        rw:
          'Tuzakoresha izi numero twohereza umwishingizi wawe ifishi nto yokuzuza.',
      },
      validators: [
        expressions.phone_en('guarantor_phone'),
        expressions.phone_length('guarantor_phone'),
        expressions.same_phone_number('guarantor_phone')
      ],
    },
  ],
},
];
export const intake_rw_pages_farming = [
  // Page 1: Applicant Information
  {
    title: {
      en: 'Farming Applicant Information',
      rw: 'Amakuru yusaba',
    },
    name: 'applicant',
    elements: [
      {
        title: { en: 'Applicant Full Name *', rw: 'Amazina yombi *' },
        name: 'last_name',
        type: 'text',
        isRequired: required,
      },
      {
        title: {
          en: 'Can you share a picture for yourself? *',
          rw: 'Waduha ifoto yawe? *',
        },
        name: 'profile_avatar',
        type: 'file_uploader',
        accept: 'image/*,application/pdf',
        isRequired: required,
      },
      {
        title: {
          en: 'Date of Birth *',
          rw: 'Itariki yamavuko *',
        },
        name: 'date_of_birth',
        type: 'text',
        inputType: 'date',
        isRequired: required,
      },
      {
        title: {
          en: 'What is your gender? *',
          rw: 'Igitsina ni ikihe? *',
        },
        name: 'gender',
        type: 'radiogroup',
        isRequired: required,
        choices: [
          { value: 'male', text: { en: 'Male', rw: 'Gabo' } },
          { value: 'female', text: { en: 'Female', rw: 'Gore' } },
        ],
      },
      {
        title: {
          en: 'What is your marital status? *',
          rw: 'Irangamimerere ni irihe? *',
        },
        name: 'marital',
        type: 'radiogroup',
        isRequired: required,
        choices: [
          { value: 'married', text: { en: 'Married', rw: 'Ndubatse' } },
          { value: 'single', text: { en: 'Single', rw: 'Ingaragu' } },
        ],
      },
      {
        title: { en: 'Full name of partner', rw: 'Amazina y’uwo mwashakanye' },
        name: 'custom_text_2',
        type: 'text',
        visibleIf: "{marital} == 'married'",
        isRequired: required,
      },
      {
        name: 'custom_text_3',
        type: 'phone',
        isRequired: required,
        visibleIf: "{marital} == 'married'",
        validators: [
          expressions.phone_en('phone_number'),
          expressions.phone_length('phone_number'),
        ],
        title: {
          en: 'Telephone number of your partner ',
          rw: 'Numero ya telephone y’uwo mwashakanye',
        },
      },
      {
        name: 'custom_text_4',
        type: 'text',
        isRequired: required,
        visibleIf: "{marital} == 'married'",
        validators: [
          {
            type: "regexvalidator",
            text: {en: "Number should be from 16 digits length",
                        rw: "Imibare igomba kuba ari 16"
                          },
            regex: "^[0-9]{16}$",
          }
        ],
        title: {
          en: 'ID number of your partners ',
          rw: "Numero y’indangamuntu",
        },
      },
      {
        title: {
          en: 'Upload the ID of your partner ',
          rw: "Shyiraho indangamuntu y’uwo mwashakanye ",
        },
        description: {
          en:
            "If you don't have an ID card upload the replacement document.",
          rw: 'Niba udafite indangamuntu shyiraho icyangombwa cyiyisimbura',
        },
        name: 'file_3',
        visibleIf: "{marital} == 'married'",
        type: 'file_uploader',
        accept: 'image/*,application/pdf',
        isRequired: required,
      },
    ],
  },
  // Page 2: Identification Information
  {
    name: 'identification',
    title: {
      en: 'Identification Information',
      rw: "Amakuru ajyanye n'umwirondoro",
    },
    elements: [
      {
        name: 'nationality',
        type: 'dropdown',
        isRequired: required,
        storeOthersAsComment: false,
        hasOther: true,
        title: {
          en: 'Nationality *',
          rw: 'Ubwenegihugu *',
        },
        choices: [
          {
            value: 'rwandan',
            text: {
              en: 'Rwandan',
              rw: 'Umunyarwanda',
            },
          },
        ],
      },
      {
        name: 'id_number',
        type: 'text',
        isRequired: required,
        validators: [
          {
            type: "regexvalidator",
            text: {en: "Number should be from 16 digits length",
                        rw: "Imibare igomba kuba ari 16"
                          },
            regex: "^[0-9]{16}$",
          }
        ],
        title: {
          en: 'ID No. *',
          rw: "Nimero y'indangamuntu ​ *",
        },
      },
      {
        title: {
          en: 'Front Image (Attachment) *',
          rw: "Ifoto y'imbere y'indangamuntu *",
        },
        description: {
          en:
            "If you don't have an ID card upload the replacement document.",
          rw: 'Niba udafite indangamuntu shyiraho icyangombwa cyiyisimbura',
        },
        name: 'id_file',
        type: 'file_uploader',
        accept: 'image/*,application/pdf',
        isRequired: required,
      },
      {
        title: {
          en: 'Back Image (Attachment) *',
          rw: "Ifoto y'inyuma y'indandamuntu *",
        },
        description: {
          en:
            'If you cannot find the document now, you can close this form and come back later.',
          rw: 'Niba udafite icyangombwa nonaha, wafunga ugakomeza nyuma',
        },
        name: 'id_file_back',
        type: 'file_uploader',
        accept: 'image/*,application/pdf',
        isRequired: required,
      },
      {
        name: 'id_avatar',
        type: 'panel',
        innerIndent: 1,
        title: {
          en: 'Please verify that this card belongs to you',
          rw: 'Genzura niba ikarita ari iyawe',
        },
        elements: [
          {
            name: 'id_avatar_desc',
            type: 'html',
            description: {
              en:
                'Please verify your identification information provided above by uploading a picture of yourself holding the provided identification information.',
              rw: 'Genzura umwirondoro watanze hejuru ushyiraho ifoto yawe',
            },
            html: {
              en:
                'Please verify your identification information provided above by uploading a picture of yourself holding the provided identification information.',
              rw: 'Genzura umwirondoro watanze hejuru ushyiraho ifoto yawe',
            },
          },
          {
            name: 'id_avatar_desc_picture',
            type: 'html',
            html: `<img alt="example" src='${require('../../assets/images/example_card.png')}' width='200'/>`,
          },
          {
            name: 'id_file_with_avatar',
            type: 'file_uploader',
            accept: 'image/*,application/pdf',
            isRequired: false,
            title: {
              en: 'Identification Picture ',
              rw: 'Ibiranga ifoto ',
            },
          },
        ],
      },
      {
        name: "custom_text_1",
        type: "text",
        isRequired: required,
        title: {
          en: "Please fill in your Equity Bank account number",
          rw: "Uzuza nimero ya konti yawe yo muri  Equity Bank",
        },
        maxLength: "13",
        validators: [
          {
            type: "regexvalidator",
            text: {
              en: "13 digits",
              rw: "13 nimero",
            },
            regex: `^[0-9]{13}$`,
          },
        ],
      },
      {
        title: { en: "How much do you want to borrow?", rw: "Urashaka kuguza amafaranga angahe?" },
        type: "text",
        inputType: "number",
        name: "amount",
        validators: [expressions.amount_greater_than_zero("amount")],
      },
    ],
  },
  // Page 3: Residence & Home Information
  {
    title: {
      en: 'Residence & Home Information',
      rw: 'Amakuru yaho utuye',
    },
    name: 'home',
    elements: [
      {
        title: {
          en: 'Where do you live?',
          rw: 'Utuye he?',
        },
        name: 'panel_home',
        type: 'panel',
        innerIndent: 1,
        elements: [
          {
            name: 'province',
            type: 'text',
            isRequired: required,
            title: {
              en: 'Province *',
              rw: 'Intara *',
            },
          },
          {
            name: 'district',
            type: 'text',
            isRequired: required,
            title: {
              en: 'District *',
              rw: 'Akarere *',
            },
          },
          {
            name: 'home_country', // Mapped to home country for now. Field on the backend will be renamed later.
            type: 'text',
            isRequired: required,
            title: {
              en: 'Sector *',
              rw: 'Umurenge *',
            },
          },
          {
            name: 'cell',
            type: 'text',
            isRequired: required,
            title: {
              en: 'Cell *',
              rw: 'Akagali *',
            },
          },
          {
            name: 'village',
            type: 'text',
            isRequired: required,
            title: {
              en: 'Village *',
              rw: 'Umudugudu *',
            },
          },
        ],
      },
      {
        name: 'phone_number',
        type: 'phone',
        isRequired: required,
        validators: [
          expressions.phone_en('phone_number'),
          expressions.phone_length('phone_number'),
        ],
        title: {
          en: 'Mobile No. 1 *',
          rw: 'Numero ya telefone *',
        },
      },
    ],
  },
   // Page 4: Production Information
   {
    name: 'production',
    title: {
      en: 'Production Information',
      rw: 'Amakuru yumusaruro', 
    },
    description: {
      en:
        'The production information provided will be verified and confirmed by the cooperative.',
      rw:
        "Amakuru y’umusaruro azasuzumwa kandi yemezwe na koperative  ", 
    },
    elements: [
      {
        title: {
          en:
            'Provide with us  production information of 3 previous agriculture seasons',
          rw: "Duhe amakuru y'umusaruro ku bihembwe bitatu bishize",
        },
        description: {
          en:
            "You can fill in information about 2 seasons in case you don't have information about 3 seasons",
          rw: "Ushobora kuzuza amakuru y'ibihembwe bibiri gusa niba ariyo ufite",
        },
        name: 'panel_home',
        type: 'panel',
        innerIndent: 1,
        elements: [
          {
            title: {
              en: 'Season 1',
              rw: "Igihembwe cya 1",
            },
            name: 'agricultural_season_1',
            type: 'panel',
            innerIndent: 1,
            elements: [
              {
                name: 'land_size_season_1',
                type:'text',
                inputType: 'number',
                title: {
                  en: 'Cultivated Land of the selected crop (hectare)',
                  rw: "Ingano y’ubuso wahinzeho igihingwa wahisemo muri hegitare",
                },
                validators: [
                  regexValidators.max_three_decimal_places(),
                ]
              },
              {
                name: 'supply_volume_kg_season_1',
                type: 'text',
                inputType: 'number',
                isRequired: required,
                title: {
                  en: 'Production of the selected crop in KGs',
                  rw: "Ingano y’umusaruro kugihigwa wahisemo  muri kilogarama",
                },
                validators: [
                  regexValidators.max_two_decimal_places(),
                ]
              },
              {
                name: 'variety_season_1',
                type: 'text',
                title: {
                  en: 'Variety',
                  rw: "Ubwoko bw’igihingwa wahisemo",
                },
              },
              {
                name: 'price_season_1',
                isRequired: required,
                type:'text',
                inputType: 'number',
                title: {
                  en: 'Price of the selected crop/kg in RWF',
                  rw: 'Igiciro ku kilo ku gihingwa wahisemo(RWF) ',
                },
                validators: [
                  regexValidators.max_two_decimal_places(),
                ]
              },
              {
                name: 'sales_season_1',
                type: 'text',
                inputType: 'number',
                isRequired: required,
                title: {
                  en: 'Sales of selected crop in RWF',
                  rw: 'Amafaranga winjije yose ku gihingwa wahisemo (RWF)',
                },
                validators: [
                  regexValidators.max_two_decimal_places(),
                ]
              },
            ],
          },
          {
            title: {
              en: 'Season 2',
              rw: "Igihembwe cya 2",
            },
            name: 'agricultural_season_2',
            type: 'panel',
            innerIndent: 1,
            elements: [
              {
                name: 'land_size_season_2',
                isRequired: required,
                type:'text',
                title: {
                  en: 'Cultivated land of the selected crop in hectare',
                  rw: "Ingano y’ubuso wahinzeho igihingwa wahisemo muri hegitare ",
                },
                validators: [
                  regexValidators.max_three_decimal_places(),
                ]
              },
              {
                name: 'supply_volume_kg_season_2',
                isRequired: required,
                type:'text',
                title: {
                  en: 'Production of the selected crop in KG',
                  rw: "Ingano y’umusaruro kugihigwa wahisemo  muri kilogarama",
                },
                validators: [
                  regexValidators.max_two_decimal_places(),
                ]
              },
              {
                name: 'variety_season_2',
                type: 'text',
                title: {
                  en: 'Variety',
                  rw: "Ubwoko bw’igihingwa wahisemo",
                },
              },
              {
                name: 'price_season_2',
                type:'text',
                isRequired: required,
                title: {
                  en: 'Price of the selected crop/kg in RWF ',
                  rw: 'Igiciro ku kilo ku gihingwa wahisemo(RWF)',
                },
                validators: [
                  regexValidators.max_two_decimal_places(),
                ]
              },
              {
                name: 'sales_season_2',
                type: 'text',
                isRequired: required,
                title: {
                  en: 'Sales of the selected crop in Rwf',
                  rw: 'Amafaranga winjije yose ku gihingwa wahisemo (RWF)',
                },
                validators: [
                  regexValidators.max_two_decimal_places(),
                ]
              },
            ],
          },
          {
            title: {
              en: 'Season 3',
              rw: "Igihembwe cya 3",
            },
            name: 'agricultural_season_3',
            type: 'panel',
            innerIndent: 1,
            elements: [
              {
                name: 'land_size_season_3',
                type:'text',
                title: {
                  en: 'Cultivated land of the selected crop in hectare',
                  rw: "Ingano y’ubuso wahinzeho igihingwa wahisemo muri hegitare",
                },
                validators: [
                  regexValidators.max_three_decimal_places(),
                ]
              },
              {
                name: 'supply_volume_kg_season_3',
                type: 'text',
                title: {
                  en: 'Production of selected crop in KG',
                  rw: "Ingano y’umusaruro kugihigwa wahisemo  muri kilogarama",
                },
                validators: [
                  regexValidators.max_two_decimal_places(),
                ]
              },
              {
                name: 'variety_season_3',
                type: 'text',
                title: {
                  en: 'Variety',
                  rw: "Ubwoko bw’igihingwa wahisemo",
                },
              },
              {
                name: 'price_season_3',
                type: 'text',
                title: {
                  en: 'Price of selected crop/kg in RWF ',
                  rw: 'Igiciro ku kilo ku gihingwa wahisemo(RWF)',
                },
                validators: [
                  regexValidators.max_two_decimal_places(),
                ]
              },
              {
                name: 'sales_season_3',
                type: 'text',
                title: {
                  en: 'Sales of selected crop in Rwf',
                  rw: 'Amafaranga winjije yose ku gihingwa wahisemo (RWF)',
                },
                validators: [
                  regexValidators.max_two_decimal_places(),
                ]
              },
            ],
          },
          {
            title: {
              en: 'Information on current season of the selected crop',
              rw: 'Amakuru ku gihembwe  ugiye guhinga',
            },
            name: 'current_agricultural_season',
            type: 'panel',
            innerIndent: 1,
            elements: [
              {
                name: 'land_size_current_season',
                type:'text',
                isRequired: required,
                title: {
                  en: 'Expected Cultivated land of the selected crop in hectare',
                  rw: "Ingano y’ubuso uteganya guhinga muri hegitare",
                },
                validators: [
                  regexValidators.max_three_decimal_places(),
                ]
              },
              {
                name: 'supply_volume_kg_current_season',
                type: 'text',
                isRequired: required,
                title: {
                  en: 'Expected production of selected crop in KG',
                  rw: "Ingano y’umusaruro uteganyijwe  muri kilogarama",
                },
                validators: [
                  regexValidators.max_two_decimal_places(),
                ]
              },
              {
                name: 'variety_current_season',
                type: 'text',
                title: {
                  en: 'Variety',
                  rw: "Ubwoko uteganya guhinga",
                },
              },
              {
                name: 'price_current_season',
                type: 'text',
                isRequired: required,
                title: {
                  en: 'Expected price of selected crop/kg in RWF ',
                  rw: 'Igiciro uteganya kugurishirizaho (RWF)',
                },
                validators: [
                  regexValidators.max_two_decimal_places(),
                ]
              },
              {
                name: 'sales_current_season',
                type: 'text',
                isRequired: required,
                title: {
                  en: 'Expected sales of selected crop in Rwf',
                  rw: 'Amafaranga yose uteganya kwinjiza (RWF)',
                },
                validators: [
                  regexValidators.max_two_decimal_places(),
                ]
              },
              {
                name: 'plot_ownership_season_1',
                type: 'radiogroup',
                isRequired: required,
                title: {
                  en: 'Do you own a land',
                  rw: 'Ufite ubutaka uhingaho ?',
                },
                choices: [
                  {
                    value: true,
                    text: {
                      en: 'Yes',
                      rw: 'Yego',
                    },
                  },
                  {
                    value: false,
                    text: {
                      en: 'No',
                      rw: 'Oya',
                    },
                  },
                ],
              },
              {
                name: 'file_1',
                visibleIf: '{plot_ownership_season_1} == true',
                type: "file_uploader",
                accept: "image/*,application/pdf",
                isRequired: required,
                title: {
                  en: 'Upload the land title',
                  rw: "Shyiraho icyangombwa cy’ubutaka",
                },
              },
              {
                name: 'file_2',
                type: "file_uploader",
                accept: "image/*,application/pdf",
                title: {
                  en: 'Upload your Bank Statement or Mobile Money statement',
                  rw: "Shyiraho urupapuro rwerekana amakuru yuko ubitsa unabikuza kuri banki cg kuri mobile money",
                },
              },
            ],
          },
        ],
      },
    ],
  },
// Page 5: Information related to your Guarantor
  {
    title: {
      en: 'Information related to your Guarantor',
      rw: "Amakuru ajyanye n'umwishingizi wawe",
    },
    name: 'home',
    elements: [
      {
        name: 'cooperative_name',
        type: 'text',
        isRequired: required,
        title: {
          en: 'Name of the Cooperative *',
          rw: 'Izina rya Cooperative *',
        },
      },
      {
        name: 'guarantor_first_name',
        visibleIf: '{cooperative_name} notempty',
        type: 'text',
        title: {
          en: 'Name of the president of the Cooperative​',
          rw: 'Amazina yombi ya perezida wa Cooperative​',
        },
      },
      {
        name: 'guarantor_phone',
        type: 'phone',
        visibleIf: '{cooperative_name} notempty',
        isRequired: required,
        title: {
          en: 'President Mobile No. *',
          rw: 'Nimero ya telefone ya perezida wa koperative​ *',
        },
        description: {
          en:
            'We will use these phone numbers to send your  cooperative a short application form.',
          rw:
            'Tuzakoresha izi numero twohereza umwishingizi wawe ifishi nto yokuzuza.',
        },
        validators: [
          expressions.phone_en('guarantor_phone'),
          expressions.phone_length('guarantor_phone'),
          expressions.same_phone_number('guarantor_phone')
        ],
      },
    ],
  },
];
