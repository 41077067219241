import React from 'react';
import {openInWhatsapp} from "../../lib/helpers";
import {api} from "../../app";
import {colors} from "../../theme/theme";
import {ws_whatsapp} from "../../components/icon/icon";
import {translate} from "../../lib/intl";
import {Button} from "../../components/button/button";

import Config from '../../config';

export class Message extends React.PureComponent {

  render({message, guarantor_phone=null} = this.props) {
    
    return (
      <div className='message'>
        <h2 className='bigSpacer'>{translate(`thanks_${message}.header`)}</h2>
        <p className='center bigSpacer'>{translate(`thanks_${message}.desc`, {guarantor_phone})}</p>

        <p className='center small bigSpacer'>{translate(`thanks_${message}.desc2`)}</p>

          {/** Button only visible for deployments that use whatsapp */}
          {Config.whatsapp_active && <Button style={{width: '240px'}} backgroundColor={colors.brand.secondary} color='#fff'
                title={translate(`thanks_${message}.button_title`)} icon={ws_whatsapp}
                onPress={() => openInWhatsapp(api.endpoint.whatsapp_number, translate(`thanks_${message}.chat_subject`))}
          />}
      </div>
    );
  }
}
