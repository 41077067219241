import React from "react";
import { connect } from "react-redux";
import * as Survey from "survey-react";
import { Bullet } from "../../components/bullet/bullet";
import { Footer } from "../../components/footer/footer";
import HelpComponent from "../../components/help/help";
import { Start } from "../../components/start/start";
import Config from "../../config";
import { DEFAULT_LOCALE, translate, translateList } from "../../lib/intl";
import { loanActions } from "../../store/store";
import {
  extractSurveyData,
  removeElementTitles,
  setupLoadFromServer,
} from "../../survey/survey.utils";
import "./supervision.container.scss";
import { credit_collection_supervision_credicapital } from "./credit_collection_supervision_credicapital";

const CURRENT_CONTAINER = "supervision";

class SupervisionContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    const deploymentModels = {
      credicapital: credit_collection_supervision_credicapital,
    };

    // setup model
    const currentDeploymentModel = (locale = DEFAULT_LOCALE) => {
      const model =
        deploymentModels[Config.deployment] !== undefined
          ? deploymentModels[Config.deployment]
          : deploymentModels.credicapital;
      model.locale = locale;
      return model;
    };

    this.model = new Survey.Model(currentDeploymentModel(props.device.locale));

    if (
      Config.load_from_api &&
      Config.server_form_load &&
      Config.server_form_load[CURRENT_CONTAINER]
    ) {
      setupLoadFromServer(
        this.model,
        Config.server_form_load[CURRENT_CONTAINER]
      );
    }

    // restore data
    this.model.data = this.base = {
      ...this.model.data,
      ...this.props.loan.intake,
    };

    this.requirements = [];

    try {
      this.requirements = translateList("supervision.requirements").map(
        (req, idx) => Bullet(translate(req), idx)
      );
      this.model.currentPageNo = this.props.loan.intake.current_page;
    } catch (error) {
      console.error(
        "Sometimes setting the page fails, so starting on title due to error: ",
        error
      );
    }

    // set callbacks
    this.model.onPartialSend.add((survey) => this.onPartialSend(survey));
    this.model.onCompleting.add((survey) => this.onCompleting(survey));

    this.model.render();

    this.state = {
      is_started: false,
      help_count: 0,
      help_time: 0,
      timeOpen: null,
    };
  }

  onPartialSend(survey) {
    this.props.saveIntake(
      this.props.loan.uuid,
      extractSurveyData(survey, this.state, this.base)
    );
  }

  async onCompleting(survey) {
    await this.props
      .saveIntake(
        this.props.loan.uuid,
        extractSurveyData(survey, this.state, this.base)
      )
      .then(() => {
        this.props.transitionLoanState(
          this.props.loan.uuid,
          "submit_supervision_form"
        );
      })
      .then(() => this.props.setPage("assessing"));
  }

  componentDidMount() {
    removeElementTitles(document, ["removeMe"]);
  }

  _openHelp() {
    // counts and keeps track of time
    this.setState({
      help_count: this.state.help_count + 1,
      timeOpen: new Date(),
    });
    this.model.stopTimer();
  }

  _closeHelp() {
    // resets model timer
    this.setState({
      timeOpen: null,
      help_time:
        this.state.help_time +
        Math.ceil((new Date() - this.state.timeOpen) / 1000),
    });
    this.model.startTimer();
  }

  render = ({ history, account, device, loan } = this.props) => (
    <div className="supervisionContainer">
      <HelpComponent
        onClose={() => this._closeHelp()}
        onOpen={() => this._openHelp()}
        type="home"
        locale={device.locale}
      />

      {this.state.is_started ? (
        <div className="centerWrapper">
          <Survey.Survey model={this.model} locale={device.locale} />
        </div>
      ) : (
        <div>
          <Start
            stage="supervision"
            time={Config.has_no_container_time ? "0" : "5"}
            onStart={() => this.setState({ is_started: true })}
          >
            <p className="heavy">{translate("supervision.desc1")}</p>
            {translate("supervision.req") !== "supervision.req" ? (
              <div>
                <p className="spacer">{translate("supervision.req")}</p>
              </div>
            ) : null}
            {this.requirements}
          </Start>
          <Footer />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    device: state.device,
    loan: state.loan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    transitionLoanState: (uuid, next_state) => {
      dispatch(loanActions.transitionLoanState(uuid, next_state, "user"));
    },
    saveIntake: (uuid, assessment, submit = false) =>
      dispatch(loanActions.saveIntake(uuid, assessment, submit)),
    setPage: (page) => dispatch(loanActions.setPage(page)),
  };
};

const connectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SupervisionContainer);

export { connectedContainer as SupervisionContainer };
