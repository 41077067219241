import Config from "../../config";
import { DEFAULT_LOCALE, translate } from "../../lib/intl";
import { select_survey_js_model } from "../../survey/survey.utils";
import { BankAccount } from "../educational_intake/pages/bank_account";
import { jobDetails } from "../educational_intake/pages/job_details";
import { MonthlyCashFlow } from "../educational_intake/pages/monthly_cash_flow";
import { IncomeStatement } from "../intake/pages/income_statement";
import {
  intake_banco_popular_pages_new_client,
  intake_banco_popular_pages_existing_client,
} from "./intake_banco_popular";
import { intake_crediavance_pages } from "./intake_crediavance";
import { intake_finadev_pages } from "./intake_finadev";
import {
  intake_procredito_pages_agro,
  intake_procredito_pages_transport,
} from "./intake_procredito";
import { intake_rw_pages_business, intake_rw_pages_farming } from "./intake_rw";
import { intake_rentamigo_pages } from "./intake_rentamigo";
import { applicant_bll } from "./pages/bll/01_applicant_bll";
import { identification_bll } from "./pages/bll/02_identification_bll";
import { residence_home_bll } from "./pages/bll/03_residence_home_bll";
import { production_bll } from "./pages/bll/04_production_bll";
import { guarantor_bll } from "./pages/bll/05_guarantor_bll";
import { applicant_credicapital } from "./pages/credicapital/01_applicant_credicapital";
import { identification_credicapital } from "./pages/credicapital/02_identification_credicapital";
import { marital_status_credicapital } from "./pages/credicapital/03_marital_status";
import { beneficiary_credicapital } from "./pages/credicapital/04_beneficiary_credicapital";
import { credit_credicapital } from "./pages/credicapital/05_credit_credicapital";
import { guarantor_credicapital } from "./pages/credicapital/06_guarantor_credicapital";
import { job_information_credicapital } from "./pages/credicapital/07_job_information_credicapital";
import { additional_info_credicapital } from "./pages/credicapital/08_additional_info_credicapital";
import { applicant_essentials } from "./pages/essentials/01_applicant_essentials";
import { identification_essentials } from "./pages/essentials/02_identification_essentials";
import { guarantor_essentials } from "./pages/essentials/03_guarantor_essentials";
import { family } from "./pages/family";
import { applicant_ffdl } from "./pages/ffdl/01_applicant_ffdl";
import { work_details_ffdl } from "./pages/ffdl/02_work_details_ffdl";
import { loan_details_ffdl } from "./pages/ffdl/03_loan_details_ffdl";
import { insurance_ffdl } from "./pages/ffdl/04_insurance_ffdl";
import { economic_ffdl } from "./pages/ffdl/05_economic_ffdl";
import { guarantor_collateral_ffdl } from "./pages/ffdl/06_guarantor_collateral_ffdl";
import { references_ffdl } from "./pages/ffdl/07_references_ffdl";
import { home } from "./pages/home";
import { identification } from "./pages/identification";
import { income_liabilities } from "./pages/income_and_liabilities";
import { loan } from "./pages/loan";
import { OtherInformation } from "./pages/other_information";
import { personal } from "./pages/personal";
import { phone } from "./pages/phone";
import { ProjectBalanceSheet } from "./pages/project_balance_sheet";
import { projectPartners } from "./pages/project_partners";
import { references } from "./pages/references";
import { SocialPerformanceIndicatorsAndAssets } from "./pages/spi_and_assets";
import { yourBusiness } from "./pages/your_business";

export function intake(
  locale = DEFAULT_LOCALE,
  loan_purpose = "default",
  api_url = ""
) {
  const mfw_pages = [
    personal,
    identification,
    family,
    home,
    phone,
    projectPartners,
    references,
    yourBusiness,
    income_liabilities,
    MonthlyCashFlow,
    BankAccount,
    ProjectBalanceSheet.setVisibleIf("{business_status} == 'existing'"),
    IncomeStatement().setVisibleIf("{business_status} == 'existing'"),
    loan,
    jobDetails,
    SocialPerformanceIndicatorsAndAssets,
    OtherInformation,
  ];

  const bll_pages = [
    applicant_bll,
    identification_bll,
    residence_home_bll,
    production_bll,
    guarantor_bll,
  ];

  const credicapital_pages = [
    applicant_credicapital(api_url),
    identification_credicapital(api_url),
    marital_status_credicapital(api_url),
    beneficiary_credicapital(api_url),
    credit_credicapital(api_url),
    guarantor_credicapital(api_url),
    job_information_credicapital(api_url),
    additional_info_credicapital(api_url),
  ];

  const essentials_pages = [
    applicant_essentials,
    identification_essentials,
    guarantor_essentials,
  ];

  const ffdl_pages = [
    applicant_ffdl,
    work_details_ffdl,
    loan_details_ffdl,
    insurance_ffdl,
    economic_ffdl,
    guarantor_collateral_ffdl,
    references_ffdl,
  ];

  const procredito_pages = {
    default: intake_procredito_pages_transport(api_url),
    agro: intake_procredito_pages_agro(api_url),
    transport: intake_procredito_pages_transport(api_url),
  };

  const banco_popular_pages = {
    new_client: intake_banco_popular_pages_new_client,
    existing_client: intake_banco_popular_pages_existing_client,
  };

  const rw_pages = {
    default: intake_rw_pages_business,
    business: intake_rw_pages_business,
    farming: intake_rw_pages_farming,
  };

  const availablePages = {
    credicapital: credicapital_pages,
    ffdl: ffdl_pages,
    rw: select_survey_js_model(loan_purpose, rw_pages),
    essentials: essentials_pages,
    mfw: mfw_pages,
    finadev: intake_finadev_pages,
    procredito: select_survey_js_model(loan_purpose, procredito_pages),
    crediavance: intake_crediavance_pages,
    rentamigo: intake_rentamigo_pages,
    banco_popular: select_survey_js_model(loan_purpose, banco_popular_pages),
    bll: bll_pages,
  };

  const currentDeploymentPages = availablePages[Config.deployment]
    ? availablePages[Config.deployment]
    : [];

  return {
    showProgressBar: "top",
    showQuestionNumbers: "off",
    sendResultOnPageNext: true,

    firstPageIsStarted: false,
    startSurveyText: translate("general.startSurveyText"),

    showCompletedPage: false,
    pagePrevText: translate("general.pagePrevText"),
    pageNextText: translate("general.pageNextText"),
    completeText: translate("general.pageNextText"), // note same as `Next`

    requiredText: "",
    isSinglePage: false, // Can be set to True for review mode

    pages: currentDeploymentPages,
    locale,
  };
}
