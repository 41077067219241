import React from "react";
import {connect} from "react-redux";
import * as Survey from "survey-react";
import {setAlertDialog} from "../../components/alert/alert";
import {Bullet} from "../../components/bullet/bullet";
import {Footer} from "../../components/footer/footer";
import HelpComponent from "../../components/help/help";
import {Start} from "../../components/start/start";
import Config from "../../config";
import {translate, translateList} from "../../lib/intl";
import {loanActions} from "../../store/store";
import {clearDropdowns, extractSurveyData, getApiUrl, removeElementTitles} from "../../survey/survey.utils";
import "../../theme/survey.scss";
import {intake} from "./intake";
import "./intake.container.scss";
import env from "../../env";
import {addCURPButton} from "../../survey/curp";

const CURRENT_CONTAINER = "intake";

// This line is commented out because ticket MON-388
//import { addSkipButton } from '../../survey/skip_button'

class IntakeContainer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.model = new Survey.Model(
            intake(props.device.locale, props.loan.loan_purpose, getApiUrl(props))
        );

        // This line is commented out because ticket MON-388
        //this.model.onAfterRenderPage.add(addSkipButton(this.props.saveIntake, this.props.loan.uuid));

        this.model.onMatrixCellValueChanged.add((_, options) => {
            if (options.columnName === "codigo_postal") {
                const questions = {
                    Endidad_Federtiva: options.getCellQuestion("estado"),
                    Municipio: options.getCellQuestion("municipio"),
                    Colonias: options.getCellQuestion("colonia"),
                };
                this.setupOptions(
                    options.value,
                    props.device.api_service_tokens,
                    questions
                );
            }
        });

        if (
            Config.dropdowns_to_clear &&
            Config.dropdowns_to_clear[CURRENT_CONTAINER]
        ) {
            clearDropdowns(this.model, Config.dropdowns_to_clear[CURRENT_CONTAINER]);
        }

        // restore data
        this.model.data = this.base = {
            ...this.model.data,
            ...this.props.loan.intake,
            guarantor_phone_verified: this.props.loan.intake.guarantor_phone,
            guarantor_2_phone_verified: this.props.loan.intake.guarantor_2_phone,
        };

        try {
            this.model.currentPageNo = this.props.loan.intake.current_page;
        } catch (error) {
            console.error(
                "Sometimes setting the page fails, so starting on title due to error: ",
                error
            );
        }

        // set callbacks
        this.model.onPartialSend.add((survey) => this.onPartialSend(survey));
        this.model.onCompleting.add((survey) => this.onCompleting(survey));
        
        if (env.DEPLOYMENT_NAME === "credicapital") {
            this.model.onAfterRenderPage.add((survey, options) => {
                if (options.page.name === "page4") {
                    let mainThis = this;
                    this.setRenderPage(mainThis);
                }                
              });
        }
        
        this.model.startTimer();
        this.model.render();

        this.state = {
            is_started:
                Config.deployment && Config.deployment === "fddl" ? true : false,

            help_count: 0,
            help_time: 0,
            timeOpen: null,
        };

        try {
            if (env.DEPLOYMENT_NAME.indexOf("credicapital") > -1) {
                this.model.onAfterRenderPanel.add(addCURPButton(this.props.device.api_service_tokens["address_service_token"], getApiUrl(props),
                    '[data-name="custom_text_102"] input', "[data-name='custom_text_30'] input",
                    "[data-name='custom_text_43'] input", "[data-name='custom_text_44'] input",
                    "[data-name='custom_text_46'] input", "[data-name='custom_text_45'] input",
                    "[data-name='custom_text_31'] input"));
            }
        } catch (e) {
            console.error(e, "Error adding curp button");
        }

        this.descList = [];
        this.bullets = [];
        this.requirements = [];
        try {
            this.bullets = translateList("start_intake.bullets").map((bullet, idx) =>
                Bullet(translate(bullet), idx)
            );
            this.requirements = translateList("start_intake.requirements").map(
                (req, idx) => Bullet(translate(req), idx)
            );
            // Specific for Banco Popular to show additional requirements for new client
            if (this.props.loan.loan_purpose === "new_client") {
                this.requirements = [
                    ...this.requirements,
                    ...translateList("start_intake.extra_requirements_new_client").map(
                        (req, idx) => Bullet(translate(req), idx)
                    ),
                ];
            }
            this.descList = translateList("start_intake.desc_list").map(
                (desc, idx) => (
                    <p className="spacer" key={idx}>
                        {translate(desc)}
                    </p>
                )
            );
        } catch (e) {
            console.error(
                `Unable to translate ${e}, check translation key is added in locale file `
            );
        }
    }

    onPartialSend(survey) {
        this.props.saveIntake(
            this.props.loan.uuid,
            extractSurveyData(survey, this.state, this.base)
        );
    }

    async setupOptions(postalCode, api_service_tokens, questions) {
        // TODO refactor to a better place, or extend api class with a get cal with custom headers, url and use the same handler for errors
        let response = await this.getAddressData(postalCode);
        if (response === undefined) {
            return
        }
        Object.keys(questions).forEach((key) => {
            if (Array.isArray(response[key])) {
                questions[key].choices = response[key].map((item) => item.Descripcion);
            }
        });
    }

    setRenderPage(mainThis) {
        let title = document.querySelector('[aria-label="Datos del beneficiario"]');
        if (title !== undefined) {
            let postals = document.querySelectorAll('input[aria-label="Código postal"]', '[~*[aria-label="Datos del beneficiario"]]');
            let colonia = document.querySelectorAll('select[aria-label="Colonia"]');
            let municipio = document.querySelectorAll('select[aria-label="Municipio"]');
            let estado = document.querySelectorAll('select[aria-label="Estado"]');

            let beneficiaries = this.props.loan.intake.custom_jsonfield_1;
            if (beneficiaries !== undefined) {
                for (let i = 0; i < postals.length; i++) {
                    let pin = postals[i].value;
                    if (pin !== undefined && pin !== "") {
                        this.updateAddress(pin, beneficiaries[i], colonia[i], municipio[i], estado[i]);
                    }
                }
            }
        }
    }

    async updateAddress(pincode, beneficiary, colonia, municipio, estado) {
        let response = await this.getAddressData(pincode);
        if (response === undefined) {
            return;
        }

        let addOptions = (options, target) => {
            options.forEach((item) => {
                let option = document.createElement('option');
                option.value = item.Descripcion;
                option.text = item.Descripcion;
                if (beneficiary[target.getAttribute("aria-label").toLowerCase()] === item.Descripcion) {
                    option.selected = true;
                }
                if (!this.isOptionInSelectElement(target, option)) {
                    target.add(option);
                }
            });
        };

        addOptions(response['Colonias'], colonia);
        addOptions(response['Endidad_Federtiva'], estado);
        addOptions(response['Municipio'], municipio);
    }


    isOptionInSelectElement(selectElement, option) {
        let found = false;
        for (let j = 0, l = selectElement.options.length; j < l; j++) {
            if (selectElement.options[j].value === option.value) {
                found = true;
                break;
            }
        }
        return found;
    }

    getAddressData = async (pincode) => {
        if (pincode === undefined || pincode === "") {
            return;
        }
        return await fetch(
            `${getApiUrl(
                this.props
            )}/api/colonia/colonias-codigopostal?codigoPostal=${pincode}`,
            {
                method: "GET",
                headers: new Headers({
                    Authorization: `Bearer ${this.props.device.api_service_tokens["address_service_token"]}`,
                    "Content-Type": "application/json; charset=utf-8",
                }),
            }
        )
            .then(response => response.json())
            .then((response) => {
                return response;
            })
            .catch(() =>
                setAlertDialog(
                    translate("errors.title"),
                    translate("errors.postalcode_fetch")
                )
            );
    }

    async onCompleting(survey) {
        console.log("Complete! ", survey);

        this.model.stopTimer();
        await this.props.saveIntake(
            this.props.loan.uuid,
            extractSurveyData(survey, this.state, this.base),
            true
        );
        let nextState =
            Config.intake_container && Config.intake_container.on_complete_next_state
                ? Config.intake_container.on_complete_next_state
                : "checking_intake";
        await this.props.setPage(nextState);
    }

    _openHelp() {
        // counts and keeps track of time
        this.setState({
            help_count: this.state.help_count + 1,
            timeOpen: new Date(),
        });
        this.model.stopTimer();
    }

    _closeHelp() {
        // resets model timer
        this.setState({
            timeOpen: null,
            help_time:
                this.state.help_time +
                Math.ceil((new Date() - this.state.timeOpen) / 1000),
        });
        this.model.startTimer();
    }

    componentDidMount() {
        removeElementTitles(document, ["removeMe"]);
    }

    render = ({history, account, device, loan} = this.props) => (
        <div className="intakeContainer">
            <HelpComponent
                onClose={() => this._closeHelp()}
                onOpen={() => this._openHelp()}
                type="home"
                locale={device.locale}
            />

            {this.state.is_started ? (
                <div className="centerWrapper">
                    <Survey.Survey model={this.model} locale={device.locale}/>
                </div>
            ) : (
                <div>
                    <Start
                        stage="intake"
                        time={Config.has_no_container_time ? "0" : "20"}
                        onStart={() => this.setState({is_started: true})}
                    >
                        <p className="heavy">{translate("start_intake.desc1")}</p>
                        <p className="spacer">{translate("start_intake.desc2")}</p>
                        {this.descList}
                        {this.bullets}
                        <div>
                            <p className="spacer">{translate("start_intake.req")}</p>
                        </div>
                        {this.requirements}
                        <p className="spacer">{translate("start_intake.extra_text")}</p>
                    </Start>
                    <Footer/>
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        account: state.account,
        device: state.device,
        loan: state.loan,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveIntake: (uuid, assessment, submit) =>
            dispatch(loanActions.saveIntake(uuid, assessment, submit)),
        setPage: (page) => dispatch(loanActions.setPage(page)),
    };
};

const connectedContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(IntakeContainer);

export {connectedContainer as IntakeContainer};
