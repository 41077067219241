import { SurveyComponent } from "../../../survey_component";
import env from "../../../../../env";

const required = env.ENDPOINT !== "local";

export const SecondGuarantorRelation = new SurveyComponent({
  name: "guarantor_2_relation",
  type: "text",
  visibleIf: "{guarantor_2_first_name} notempty",
  isRequired: required,
  title: {
    en: "What is the relation of {guarantor_2_first_name} to you?",
    ar: "ما هي علاقة {guarantor_2_first_name} بك؟ *"
  },
  description: {
    en: "(Ex. Boss, Friend, Family, etc.)",
    ar: "(مثال: مدير، صديق، عائلة، إلخ)"
  },
});
