import env from "../../env";

const required = env.ENDPOINT !== "local";

export const credit_collection_supervision_credicapital = {
  showProgressBar: "top",
  showQuestionNumbers: "off",
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: {
    en: "Submit supervision",
    "es-MX": "Firma supervisión de crédito y cobranza",
  },
  questionStartIndex: 1,
  requiredText: "",
  pages: [
    // Page 1
    {
      title: {
        en: "References",
        "es-MX": "Referencias",
      },
      elements: [
        {
          name: "custom_jsonfield_9",
          valueName: "custom_jsonfield_9",
          type: "matrixdynamic",
          minRowCount: 1,
          maxRowCount: 2,
          columnLayout: "vertical",
          rowCount: 1,
          addRowLocation: "bottom",
          isRequired: required,
          addRowText: { en: "Add", "es-MX": "Añadir" },
          title: {
            en: "References with neighbors or clients (made by the credit and collections executive)",
            "es-MX":
              "Referencias con vecinos o clientes (realizado por el ejecutivo de crédito y cobranza)",
          },
          columns: [
            {
              title: {
                en: "Full name",
                "es-MX": "Nombre completo",
              },
              cellType: "text",
              name: "nombre_completo",
              isRequired: required,
            },
            {
              title: {
                en: "Address",
                "es-MX": "Domicilio",
              },
              cellType: "text",
              name: "domicilio",
              isRequired: required,
            },
            {
              title: {
                en: "Observations",
                "es-MX": "Observaciones",
              },
              cellType: "text",
              name: "observaciones",
              isRequired: required,
            },
            {
              title: {
                en: "Years of knowing",
                "es-MX": "Años de conocer",
              },
              cellType: "text",
              inputType: "number",
              name: "años_de_conocer",
              isRequired: required,
            },
          ],
        },
      ],
    },
    // Page 2
    {
      title: {
        en: "Client",
        "es-MX": "Cliente",
      },
      innerIndent: 1,
      name: "",
      elements: [
        {
          title: {
            en: "Does the Applicant Exist?",
            "es-MX": "¿Existe el Solicitante?",
          },
          type: "dropdown",
          isRequired: required,
          name: "custom_text_142",
          choices: [
            {
              value: "si",
              text: { en: "Yes", "es-MX": "Sí" },
            },
            {
              value: "no",
              text: { en: "No", "es-MX": "No" },
            },
          ],
        },
        {
          title: {
            en: "Is the business (s) going?",
            "es-MX": "¿El (os) negocio(s) está(n) en marcha?",
          },
          type: "dropdown",
          isRequired: required,
          name: "custom_text_124",
          choices: [
            {
              value: "si",
              text: { en: "Yes", "es-MX": "Sí" },
            },
            {
              value: "no",
              text: { en: "No", "es-MX": "No" },
            },
          ],
        },
        {
          title: {
            en: "Does the address coincide with the Credit Application?",
            "es-MX": "¿Coincide el domicilio con la Solicitud de Crédito?",
          },
          type: "dropdown",
          isRequired: required,
          name: "custom_text_126",
          choices: [
            {
              value: "si",
              text: { en: "Yes", "es-MX": "Sí" },
            },
            {
              value: "no",
              text: { en: "No", "es-MX": "No" },
            },
          ],
        },
        {
          title: {
            en: "Does the business support the requested credit? (Inventory, Furniture, Customer influx, etc)",
            "es-MX":
              "¿El negocio respalda el crédito solicitado? (Inventario, Mobiliario, Afluencia de clientes, etc)",
          },
          type: "dropdown",
          isRequired: required,
          name: "custom_text_128",
          choices: [
            {
              value: "si",
              text: { en: "Yes", "es-MX": "Sí" },
            },
            {
              value: "no",
              text: { en: "No", "es-MX": "No" },
            },
          ],
        },
        {
          title: {
            en: "Is there a guarantee and in what conditions is it?",
            "es-MX": "¿Existe la garantía y en que condiciones se encuentra?",
          },
          type: "dropdown",
          isRequired: required,
          name: "custom_text_123",
          choices: [
            {
              value: "si",
              text: { en: "Yes", "es-MX": "Sí" },
            },
            {
              value: "no",
              text: { en: "No", "es-MX": "No" },
            },
          ],
        },
        {
          type: "comment",
          name: "custom_text_125",
          title: {
            en: "General comments about the client",
            "es-MX": "Comentarios generales sobre el cliente",
          },
        },
      ],
    },
    // Page 3
    {
      title: {
        en: "Guarantor in your case",
        "es-MX": "Aval en su caso",
      },
      innerIndent: 1,
      name: "",
      visibleIf: "{custom_text_110} == 'si' || {amount} >= 50000",
      elements: [
        {
          type: "panel",
          title: {
            en: "Endorsement",
            "es-MX": "Aval en su caso",
          },
          name: "aval_en_su_caso",
          elements: [
            {
              title: {
                en: "Do you know the amount of financing requested by your guarantor (mention the client's name)?",
                "es-MX":
                  "¿Conoce el monto del financiamiento que solicita su avalado (mencionar nombre del cliente)?",
              },
              type: "dropdown",
              isRequired: required,
              name: "custom_text_132",
              choices: [
                {
                  value: "si",
                  text: { en: "Yes", "es-MX": "Sí" },
                },
                {
                  value: "no",
                  text: { en: "No", "es-MX": "No" },
                },
              ],
            },
            {
              title: {
                en: "Are you willing to pay the credit in case the client does not pay?",
                "es-MX":
                  "¿Está dispuesto a pagar el crédito en caso de que el cliente no pague?",
              },
              type: "dropdown",
              isRequired: required,
              name: "custom_text_134",
              choices: [
                {
                  value: "si",
                  text: { en: "Yes", "es-MX": "Sí" },
                },
                {
                  value: "no",
                  text: { en: "No", "es-MX": "No" },
                },
              ],
            },
            {
              type: "comment",
              name: "custom_text_127",
              title: {
                en: "General comments about the guarantor",
                "es-MX": "Comentarios generales sobre el aval",
              },
            },
          ],
        },
      ],
    },
  ],
};
