import { SurveyComponent } from "../../survey_component";

import * as IdTypes from "./options/id_types";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const IdentificationTypeSyrian = new SurveyComponent({
  title: {
    en: "What identification paper do you have?",
    ar: "ما هي الوثائق التعريفية التي تمتلكها"
  },
  name: "id_type",
  type: "dropdown",
  visibleIf: "{nationality} == 'syrian'",
  isRequired: required,
  storeOthersAsComment: false,
  choices: [
    IdTypes.PassportOption,
    IdTypes.MoiIdOption,
    IdTypes.SonsOfJordanianWomenOption
  ]
});
