import Config from "../config";
import { translate } from "../lib/intl";
import { add, divide, multiply } from "./money_functions";
import { api, logger } from "../app";

const numeral = require("numeral");

export function date_formatter(locale, options) {
  return new Intl.DateTimeFormat(locale, options);
}

//=== education

export function calc_education_terms(amount, repayment_period) {
  return {
    amount,
    repayment_period,
    total_fees: multiply(amount, 0.008, repayment_period),
  };
}

//=== business

// convenience function to add days and months to start
function addDays(oldDate, days) {
  oldDate.setDate(oldDate.getDate() + days);
  return oldDate;
}

export function calc_start_repayment_date(
  days_to_start = 0,
  today = new Date(),
  days_non_payment_period = 50
) {
  today.setHours(0, 0, 0, 0);

  // check what the minimal starting date is
  let start_repayment_date = addDays(
    today,
    days_to_start + days_non_payment_period
  );

  // Pick the closest date when day is 2 / 5 / 10
  const dates = [
    new Date(
      start_repayment_date.getFullYear(),
      start_repayment_date.getMonth(),
      2
    ),
    new Date(
      start_repayment_date.getFullYear(),
      start_repayment_date.getMonth(),
      5
    ),
    new Date(
      start_repayment_date.getFullYear(),
      start_repayment_date.getMonth(),
      10
    ),
    // if month 12: january next year
    new Date(
      start_repayment_date.getFullYear(),
      start_repayment_date.getMonth() + 1,
      2
    ),
  ];

  dates.sort((d1, d2) =>
    Math.abs(start_repayment_date - d1) < Math.abs(start_repayment_date - d2)
      ? -1
      : +1
  );
  return dates[0];
}

export function calc_loanterms_mfw(params) {
  // Total term calculation, returns object of all data

  // only calculate if first two are visible
  if (!params[0] || !params[1]) {
    return {};
  }

  // inputs;
  let amount = parseInt(params[0], 10);
  let repayment_period = parseInt(params[1], 10);
  let monthly_insurance = params[2] ? parseFloat(params[2]) : 0;
  let start_loan_date = params[3];

  // assumptions:
  let days_to_start = 7;
  let monthly_flat_interest = Config.loan_terms.monthly_flat_interest;
  let monthly_administrative_fees =
    Config.loan_terms.monthly_administrative_fees;
  let monthly_compulsary_insurance =
    Config.loan_terms.monthly_compulsary_insurance;

  // for testing you can add a date, otherwise start "days_to_start"
  if (start_loan_date <= 1000 || start_loan_date === "null")
    start_loan_date = addDays(new Date(), days_to_start);

  // === 1. Determine date of first installment:
  let start_repayment_date = calc_start_repayment_date(
    days_to_start,
    new Date()
  );

  // === 2. Monthly installment and admin fee:
  // consists of linear repayment schedule over the term period with the interest (also share of non-payemnt period)
  let repayment = amount / repayment_period;
  let monthly_interest = amount * monthly_flat_interest;

  let monthly_installment = add(repayment, monthly_interest);

  let total_monthly = add(
    monthly_insurance,
    monthly_compulsary_insurance,
    monthly_installment
  );

  let total_yearly = multiply(total_monthly, 12);

  // === 4. Total fees:
  let total_fees = multiply(monthly_interest, repayment_period);
  let sales_tax = multiply(total_fees, 0.03); // Floating Point Proof

  // Correction for the 'revenue stamp': Loans of 0-1000 JODs always pay a 2 JOD revenue stamp.
  let stamp =
    amount <= 1000 ? 2 : multiply(Math.ceil(amount / 1000), 1000, 0.0003); // Floating Point Proof

  // === 5. Add in translations
  let start_loan_date_translated = translate("general.date", {
    date_in: start_loan_date,
  });
  let start_repayment_date_translated = translate("general.date", {
    date_in: start_repayment_date,
  });

  // return object to get from
  return {
    amount,
    repayment_period,
    start_loan_date,
    start_repayment_date,
    start_loan_date_translated,
    start_repayment_date_translated,
    monthly_installment: monthly_installment.toFixed(2),
    monthly_insurance: monthly_insurance + monthly_compulsary_insurance,
    total_monthly: total_monthly.toFixed(2),
    total_yearly: total_yearly.toFixed(2),
    total_fees: total_fees.toFixed(2),
    monthly_administrative_fees: monthly_administrative_fees.toFixed(2),
    sales_tax: sales_tax.toFixed(2),
    stamp: stamp.toFixed(2),
  };
}

export function calc_loanterms_rwanda(params) {
  // Total term calculation, returns object of all data
  // only calculate if first two are visible
  if (!params[0] || !params[1] || !params[3]) {
    return {};
  }

  // inputs;
  let amount = parseInt(params[0], 10);
  let repayment_period = 6; // 6 months

  // rates
  let admin_fee_percentage = 0.02; //  Admin fee 2% paid at once
  let total_admin_fee = admin_fee_percentage * amount;
  let VAT = 0.18; //  18% VAT on admin paid at once
  let insurance_fee_percentage = 0.006; // 0.6% paid at once
  let annual_interest_rate = 0.16; 
  let late_payment_penalty = 0.06; // per month

  //monthly amounts
  let monthly_flat_interest = divide(
    divide(annual_interest_rate / 2),
    repayment_period
  ); //16% / loan period
  let base_monthly_installment = divide(amount, repayment_period);
  let monthly_installment = add(
    multiply(monthly_flat_interest, amount),
    base_monthly_installment
  );

  //total fees
  let total_interest = multiply(amount, annual_interest_rate, 0.5);
  let total_insurance_fee = multiply(amount, insurance_fee_percentage);
  let total_vat_on_admin_fee = multiply(total_admin_fee, VAT);
  let total_admin_fee_incl_vat = add(total_vat_on_admin_fee, total_admin_fee);
  let total_fees_paid_at_once = add(
    total_admin_fee_incl_vat,
    total_insurance_fee,
    total_interest
  ); // Admin fee and Insurance
  let total_yearly = add(
    multiply(monthly_installment, repayment_period),
    total_fees_paid_at_once
  );

  // assumptions,
  let days_to_start = 7;
  let days_non_payment_period = 50;

  // for testing you can add a date, otherwise start "days_to_start"
  let start_loan_date = addDays(new Date(), days_to_start);
  let start_repayment_date = calc_start_repayment_date(
    days_to_start,
    new Date(),
    days_non_payment_period
  );
  let start_loan_date_translated = translate("general.date", {
    date_in: start_loan_date,
  });
  let start_repayment_date_translated = translate("general.date", {
    date_in: start_repayment_date,
  });

  // return object to get from
  return {
    amount,
    repayment_period,
    start_loan_date,
    start_repayment_date,
    total_admin_fee,
    total_vat_on_admin_fee,
    total_interest,
    start_loan_date_translated,
    start_repayment_date_translated,
    late_payment_penalty,
    annual_interest_rate,
    admin_fee_percentage,
    sales_tax: VAT,
    total_fees: total_fees_paid_at_once.toFixed(2),
    total_admin_fee_incl_vat: total_admin_fee_incl_vat.toFixed(2),
    monthly_installment: monthly_installment.toFixed(2),
    total_insurance_fee: total_insurance_fee.toFixed(2),
    total_yearly: total_yearly.toFixed(2),
  };
}

export function calc_loanterms_eduloan(params) {
  // Total term calculation, returns object of all data

  // only calculate if first two are visible
  if (!params[0] || !params[1]) {
    return {};
  }

  // inputs;
  let amount = parseInt(params[0], 10);
  let repayment_period = 6; // 6 months

  // rates
  let admin_fee_percentage = 0.02; //  Admin fee 2% paid at once
  let total_admin_fee = admin_fee_percentage * amount;
  let insurance_fee_percentage = 0.006; // 0.6% paid at once
  let annual_interest_rate = 0.14; //interest rate 14%
  let late_payment_penalty = 0.005; // per month

  //monthly amounts
  let monthly_flat_interest = divide(
    divide(annual_interest_rate / 2),
    repayment_period
  ); //14% / loan period
  let base_monthly_installment = divide(amount, repayment_period);
  let monthly_installment = add(
    multiply(monthly_flat_interest, amount),
    base_monthly_installment
  );
  let monthly_interest_rate = 0.06;

  //total fees
  let total_interest = multiply(amount, annual_interest_rate, 0.5); //0.5 based on 6 months (half a year)
  let total_insurance_fee = multiply(amount, insurance_fee_percentage);
  let total_fees_paid_at_once = add(
    total_insurance_fee,
    total_interest,
    total_admin_fee
  ); // Admin fee and Insurance
  let total_yearly = add(
    multiply(monthly_installment, repayment_period),
    total_fees_paid_at_once
  );

  //monthly fees
  let monthly_insurance_fee = divide(total_insurance_fee, 12);
  let monthly_administrative_fee = divide(total_admin_fee, 12);
  let total_monthly = divide(total_yearly, 12);

  // assumptions,
  let days_to_start = 7;
  let days_non_payment_period = 50;

  // for testing you can add a date, otherwise start "days_to_start"
  let start_loan_date = addDays(new Date(), days_to_start);
  let start_repayment_date = calc_start_repayment_date(
    days_to_start,
    new Date(),
    days_non_payment_period
  );
  let start_loan_date_translated = translate("general.date", {
    date_in: start_loan_date,
  });
  let start_repayment_date_translated = translate("general.date", {
    date_in: start_repayment_date,
  });

  // return object to get from
  return {
    amount,
    repayment_period,
    start_loan_date,
    start_repayment_date,
    total_admin_fee,
    total_interest,
    start_loan_date_translated,
    start_repayment_date_translated,
    late_payment_penalty,
    annual_interest_rate,
    monthly_interest_rate,
    admin_fee_percentage,
    total_fees: total_fees_paid_at_once.toFixed(2),
    monthly_installment: monthly_installment.toFixed(2),
    monthly_administrative_fees: monthly_administrative_fee.toFixed(2),
    monthly_insurances: monthly_insurance_fee.toFixed(2),
    total_insurance_fee: total_insurance_fee.toFixed(2),
    total_yearly: total_yearly.toFixed(2),
    total_monthly: total_monthly,
  };
}

export function calc_loanterms(params) {
  if (Config.deployment && Config.deployment === "rw") {
    return calc_loanterms_rwanda(params);
  } else if (Config.deployment && Config.deployment === "eduloan") {
    return calc_loanterms_eduloan(params);
  }
  return calc_loanterms_mfw(params);
}

export function register_calc_terms(Survey) {
  Survey.FunctionFactory.Instance.register("calcLoanterms", calc_loanterms);
}

export function register_multiplyAndSumInArray(Survey) {
  Survey.FunctionFactory.Instance.register(
    "multiplyAndSumInArray",
    ([data, ...fields]) => {
      if (!data || !Array.isArray(data) || !fields || fields.length === 0) {
        return;
      }
      return data
        .map((o) => fields.map((f) => o[f])) // example value: [[33, 2], [44, 23]]
        .map((nums) => multiply(...nums))
        .filter((v) => v) // example value: [66, 1012]
        .reduce((a, b) => a.add(b || 0), numeral(0)) // example value: { _input: 1012, _value: 1078 } // Added check for value being undefined
        .value(); // example value: 1078
    }
  );
}

export function register_get_loan_term(Survey) {
  Survey.FunctionFactory.Instance.register("getLoanTerm", (params) => {
    // convenience function to get the loan term from the terms object
    let loan_terms = params[0];
    let term = params[1];

    if (!loan_terms || !term) {
      return "";
    }

    return loan_terms[term];
  });
}

/**
 * @author Stephan Meijer <stephan.meijer@teamcoda.com>
 */
export function register_sum_matrixdown(Survey) {
  Survey.FunctionFactory.Instance.register(
    "sumMatrixdropdown",
    ([data, field]) => {
      if (!data || !field) {
        return 0.0;
      }

      return Object.values(data)
        .map((v) => v[field])
        .filter((v) => v)
        .reduce((a, b) => a.add(b), numeral(0.0))
        .value();
    }
  );
}

export function register_calc_pmt(Survey) {
  Survey.FunctionFactory.Instance.register("calcPmt", (params) => {
    /*
     * ir   - interest rate per month
     * np   - number of periods (months)
     * pv   - present value
     * fv   - future value
     * type - when the payments are due:
     *        0: end of the period, e.g. end of month (default)
     *        1: beginning of period
     */

    let ir = params[0];
    let np = params[1];
    let pv = params[2];
    let fv = params[3] || 0;
    let type = params[4] || 0;
    var pmt, pvif;

    fv || (fv = 0);
    type || (type = 0);

    if (ir === 0) return -(pv + fv) / np;

    pvif = Math.pow(1 + ir, np);
    pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

    if (type === 1) pmt /= 1 + ir;

    return pmt < 1 ? pmt * -1 : pmt;
  });
}

export function register_template_conditional(Survey) {
  Survey.FunctionFactory.Instance.register("templateConditional", (params) => {
    let val_1 = params[0];
    let val_2 = params[1];
    let condition = params[2];
    let condition_val = params[3];
    let comparison = params[4] || "equal";

    if (comparison === "greater_than") {
      return condition > condition_val ? val_1 : val_2;
    } else if (comparison === "less_than") {
      return condition < condition_val ? val_1 : val_2;
    }

    return condition === condition_val ? val_1 : val_2;
  });
}

export function register_months_from_now(Survey) {
  Survey.FunctionFactory.Instance.register("monthsFromNow", (params) => {
    let months = params[0];
    let date = params[1] ? new Date(params[1]) : new Date();

    date.setMonth(date.getMonth() + months);
    return date_formatter().format(date);
  });
}
export function days_from_now(params) {
  let days = params[0];
  let date = params[1] ? new Date(params[1]) : new Date();
  let locale = params[2];

  date.setDate(date.getDate() + days);
  return date_formatter(locale).format(date);
}
export function register_days_from_now(Survey) {
  Survey.FunctionFactory.Instance.register("daysFromNow", days_from_now);
}

export function register_weekdays_from_now(Survey) {
  Survey.FunctionFactory.Instance.register("weekdaysFromNow", (params) => {
    let days = params[0];
    const date = params[1] ? new Date(params[1]) : new Date();
    let locale = params[2];

    var weekendDays = 0;
    for (let i = 0; i < days; i++) {
      var weekDay = date.getDay();
      if (weekDay === 0 || weekDay === 6) weekendDays++;
      date.setDate(date.getDate() + 1);
    }

    date.setDate(date.getDate() + weekendDays);
    
    if (date.getDay() === 0) {
      date.setDate(date.getDate() + 1);
    } else if (date.getDay() === 6) {
      date.setDate(date.getDate() + 2)
    }

    return locale ? date.toLocaleDateString(locale) : date.toLocaleDateString();
  });
}

export function register_months_and_days_from_now(Survey) {
  Survey.FunctionFactory.Instance.register("monthsAndDaysFromNow", (params) => {
    let months = params[0];
    let days = params[1];
    let date = params[2] ? new Date(params[2]) : new Date();

    date.setMonth(date.getMonth() + months);
    date.setDate(date.getDate() + days);

    return date_formatter().format(date);
  });
}

async function getValueFromUrl(params) {
  // Function that returns the value on callback async
  if (params.length < 2) return "";

  let url = params[0];
  let id = params[1];
  // If the question is empty then do nothing
  if (!url || !id) {
    // It doesn't matter what the function returns. The library is waiting for this.returnResult(resultValue) callback
    this.returnResult("");
    return "";
  }
  // call the api
  await api
    .get(`${url}${id}`)
    .then((data) => {
      if (
        data["results"] &&
        data["results"][0] &&
        data["results"][0]["description"]
      )
        // return the value into the library. Library is waiting for this callback
        this.returnResult(data["results"][0]["description"]);
      else {
        this.returnResult("");
      }
    })
    .catch((error) => {
      logger.error(error);
      this.returnResult("An error occured, see the logs");
    });
  // May return any value. The library will ignore it.
  return "NOTHING";
}

export function register_get_value_from_url(Survey) {
  // https://surveyjs.io/form-library/examples/validators-async-expression/jquery#content-js
  Survey.FunctionFactory.Instance.register(
    "getValueFromUrl",
    getValueFromUrl,
    true
  ); // true for async
}

export function register_get_full_name(Survey) {
  Survey.FunctionFactory.Instance.register("getFullName", (params) => {
    return params.join(" ");
  });
}

export function register_calcYearsDifference(Survey) {
  Survey.FunctionFactory.Instance.register("calcYearsDifference", (params) => {
    if (!params[0]) {
      return;
    }
    const creationDate = new Date(params[0]);
    const yearDifMs = Date.now() - creationDate;
    const ageDate = new Date(yearDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  });
}

export function register_calcDaysDifference(Survey) {
  Survey.FunctionFactory.Instance.register("calcDaysDifference", (params) => {
    const date_1 = params[0] ? new Date(params[0]) : new Date();
    const date_2 = new Date();

    const diff_date = date_2.getTime() - date_1.getTime();
    const diff_days = diff_date / (1000 * 3600 * 24); //miliseconds/second, seconds/hour, hours/day

    return Math.floor(diff_days);
  });
}

