import { SurveyComponent } from "../../../../components/survey/survey_component";
import env from "../../../../env";
import { expressions } from "../../../../survey/validators";

const required = env.ENDPOINT !== "local";

// if you start using the (clientUrlForCatalog) remove the _ in the name
export const guarantor_credicapital = (_clientUrlForCatalog) =>
  new SurveyComponent({
    title: { en: "Guarantor information", "es-MX": "Datos del aval" },
    elements: [
      {
        title: {
          en: "Do you want to have a guarantor?",
          "es-MX": "¿Quieres agregar un aval?",
        },
        type: "dropdown",
        isRequired: "{amount} < 50000",
        visibleIf: "{amount} < 50000",
        name: "custom_text_110",
        choices: [
          {
            value: "si",
            text: { en: "Yes", "es-MX": "Sí" },
          },
          {
            value: "no",
            text: { en: "No", "es-MX": "No" },
          },
        ],
      },
      {
        type: "panel",
        visibleIf: "{custom_text_110} == 'si' || {amount} >= 50000",
        innerIndent: 1,
        elements: [
          {
            title: { en: "Guarantor's name", "es-MX": "Nombre del aval" },
            type: "text",
            name: "guarantor_first_name",
            isRequired: required,
          },
          {
            title: { en: "Guarantor's surname", "es-MX": "Apellido Paterno" },
            type: "text",
            name: "guarantor_last_name",
            isRequired: required,
          },
          {
            title: {
              en: "Guarantor's second surname",
              "es-MX": "Apellido Materno",
            },
            type: "text",
            name: "guarantor_mother_name",
            isRequired: required,
          },
          {
            title: { en: "Phone number", "es-MX": "Número de teléfono" },
            type: "phone",
            name: "guarantor_phone",
            isRequired: required,
            validators: [
              expressions.phone_en("guarantor_phone"),
              expressions.phone_length("guarantor_phone"),
              expressions.same_phone_number("guarantor_phone")
            ],
          },
        ],
      },
    ],
  });
