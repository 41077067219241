import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const ProjectType = new SurveyComponent({
  title: {
    en: "Project Type",
    ar: "نوع المشروع"
  },
  name: "project_balance_sheet_project_type",
  type: "text",
  isRequired: required,
});
