import env from "../env";

export const endpoints = {
  local: {
    mode: "local",
    web_url: "http://localhost:3000",
    api_url: `http://${
      env.DEPLOYMENT_NAME.replace("_", "-") || "tenantone"
    }.localhost:8001`,
    client_id: "KdfCMeXqMPKv5vUFZJhTYNQOE9XZXDbA6xOXLGP5",
    client_secret:
      "FGuJjsAW2SAk0znHGqbZ8c6w2j5AENXMfAtYivnNGYeVRAs7uCP1kcj4hihRf6hD86Cqym1YuWRwEyHmhZWLIzeBK5r9OGqnXbFt8NtrToPf7nP2l2oH73BU8jvYRZwi",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: false,
    whatsapp_number: "+3197010241194",
    locale_files_url:
      "https://static-assets-moneyphone-dev.s3.eu-central-1.amazonaws.com",
  },
  development: {
    mode: "development",
    web_url: "https://dev-apply.money-phone.com",
    api_url: "https://dev-api.money-phone.com",
    client_id: "lXXqxGP8tfPgav8foD1WDnUNwMJ9eDFleM0IVi1h",
    client_secret:
      "StfKxGJoyBLcvLeDptToZy44l2113763TUEeiICdnNognkwfnv8MH7Q3jtBQte9ee87qEaqFOMdPUS12Csj3tnZCEBggJWuKoVTS3qOIWYBoZXR5X5rUPXj2NYV3hHi8",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: false,
    whatsapp_number: "+962775491941",
    locale_files_url: "https://dwdzcc6u75umg.cloudfront.net",
  },
  staging: {
    mode: "staging",
    web_url: "https://staging-apply.money-phone.com",
    api_url: "https://staging-api.money-phone.com",
    client_id: "ymriP04A0iSm4WFdCh50JSyuQKaxKM9ixY3OdfLB",
    client_secret:
      "83I0iSNDpOeiqulyIMfOVTa2AMQhQpDv7rlxJGydOFrVoqUXQG3YLjMZKXJgpWWP1mwzf4dXfcUunnvZaSgS0XCJ9xZsqi1pm3aDbY1m2QuO9Qzih2vpmvc3Ucim5kbX",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: false,
    whatsapp_number: "+962775491941",
    locale_files_url: "https://dwdzcc6u75umg.cloudfront.net",
  },
  production: {
    mode: "production",
    web_url: "https://apply.money-phone.com",
    api_url: "https://api.money-phone.com",
    client_id: "5zyQbaZp86U2bPg2ftv9BaqVwUYxqogTXgw0CHlp",
    client_secret:
      "elbIvoKFnndrhx51Xa7lZ6GnOUHkFXn2wzWgKCBAA3IZOlsPsG6be2VL0pU7wZS8dZk8yu5Xq21mJKPtRkxoLq8OEfsmPPnbcHNiZ55zN7cAEpshm9piDQAsdIqR2cOz",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: false,
    is_required: true,
    whatsapp_number: "+962775491941",
    locale_files_url: "https://dwdzcc6u75umg.cloudfront.net",
  },
  rwanda_staging: {
    mode: "staging",
    web_url: "https://equitybank-apply-staging.money-phone.com",
    api_url: "https://equitybank-staging.money-phone.com",
    client_id: "Ntk0Zrb5h02cgdbTs6LfY6qyjmcq4GHstEWRGtBc",
    client_secret:
      "605CPJWntrqlHS2bisBRCtww226Fy1huPmx2ntiEtxWouzQmolYA66rwnuBfO9HbzDH0uzkuK4UD0nfnZvXzln3ZcFjRvoiqk2v3RWOmFSJOUiBcHKeVNP7QRjBAR4n5",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+962775491941",
    locale_files_url: "https://static-assets-moneyphone-dev.s3.eu-central-1.amazonaws.com",
  },
  rwanda_production: {
    mode: "staging",
    web_url: "https://equitybank-apply.money-phone.com",
    api_url: "https://equitybank.money-phone.com",
    client_id: "4v84NyVd6g33Vpi5BvJZAybht7xhdrXVmHYjkWLd",
    client_secret: "",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "",
    locale_files_url: "https://mon-tenant-files.s3.eu-central-1.amazonaws.com",
  },
  eduloan_dev: {
    mode: "development",
    web_url: "https://eduloan-dev.web.app",
    api_url: "https://eduloan-dev.money-phone.com",
    client_id: "dKVI84AXnBpWoV6q6HHbCY8XRA7mEfkUaIiVP4X2",
    client_secret:
      "UXRGDBTSX4JAeeY0TuztfL7ca4YAa5T32lMyjcTu4KYsUv58etNVhfd6fieiVTwsjMk2jF5HHHWB3UeposWKf1CfR2olEEpv3MMrw4TjO5X6COJ1XlIFJ4Namm2lLPAd",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010241194",
    locale_files_url:
      "https://static-assets-moneyphone-dev.s3.eu-central-1.amazonaws.com",
  },
  eduloan_staging: {
    mode: "staging",
    web_url: "https://eduloan-staging.web.app",
    api_url: "https://eduloan-staging.money-phone.com",
    client_id: "0fMTpO8kWr9oRpVsjIcmOFkQ9LZt2RkGsL0vktdY",
    client_secret:
      "YjYbR249lJZ3LPC2uL8sC89ZuDoGqvUleFEF5PbgjjW0FnljS34QYdQJgZ4cKi0oF07tDmsjiPVF1T9o0mbgK4Y1MSyQTM0DA8wjI2goOkc1VFjeVnAYdcvscXal8pHh",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010241194",
    locale_files_url:
      "https://static-assets-moneyphone-staging.s3.eu-central-1.amazonaws.com",
  },
  eduloan_prod: {
    mode: "production",
    web_url: "https://eduloan-apply.money-phone.com",
    api_url: "https://eduloan.money-phone.com",
    client_id: "ObRQbykXAuFt63UlBeZ0wnkKJ13ijfveDeXPb1to",
    client_secret: "",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010241194",
    locale_files_url: "https://mon-tenant-files.s3.eu-central-1.amazonaws.com",
  },
  ffdl_dev: {
    mode: "development",
    web_url: "https://ffdl-dev.web.app",
    api_url: "https://ffdl-dev.money-phone.com",
    client_id: "nPMXqcgiDfdN57U2zdnaxl27TgSmJdT0faMiqsm9",
    client_secret:
      "YPnatV5VlEsaX2t0EHfQxndutxwZH5SWaXunraVcngoquyrqYvfNbxZvE2WQDAi14ERIAyi8HCXx78ZsfNQNuQNo2dyme2gMOBANruQfREGSCR4ifia9o6dmWanYv4gH",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240546",
    locale_files_url:
      "https://static-assets-moneyphone-dev.s3.eu-central-1.amazonaws.com",
  },
  ffdl_staging: {
    mode: "staging",
    web_url: "https://ffdl-staging.web.app",
    api_url: "https://ffdl-staging.money-phone.com",
    client_id: "q64ivB4TDLoE9PVZbvQbJW5BM5LjvmC4zWJDK86z",
    client_secret:
      "9QzM7VuiNcxaA5NRBZtIs2w75DPwZfLzNXNyVvK3HGNZJFVGhrdz8CYdayQb9k6GJqW7Rk4gq3CxRsiS3wxjP8b3Ob3h6QO2i1zbEvA8fx9cBhsVwFEn2uS7Q9e6adtd",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240546",
    locale_files_url:
      "https://static-assets-moneyphone-staging.s3.eu-central-1.amazonaws.com",
  },
  ffdl_prod: {
    mode: "production",
    web_url: "https://ffdl-apply.money-phone.com",
    api_url: "https://ffdl.money-phone.com",
    client_id: "gbkXa8YJmgVVrVTDGcQ43LLq1XVbSGuJQBddYzne",
    client_secret: "",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240546",
    locale_files_url: "https://mon-tenant-files.s3.eu-central-1.amazonaws.com",
  },
  crediavance_dev: {
    mode: "development",
    web_url: "https://crediavance-dev.web.app",
    api_url: "https://crediavance-dev.money-phone.com",
    client_id: "nPMXqcgiDfdN57U2zdnaxl27TgSmJdT0faMiqsm9",
    client_secret:
      "YPnatV5VlEsaX2t0EHfQxndutxwZH5SWaXunraVcngoquyrqYvfNbxZvE2WQDAi14ERIAyi8HCXx78ZsfNQNuQNo2dyme2gMOBANruQfREGSCR4ifia9o6dmWanYv4gH",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240546",
    locale_files_url:
      "https://static-assets-moneyphone-dev.s3.eu-central-1.amazonaws.com",
  },
  crediavance_staging: {
    mode: "staging",
    web_url: "https://crediavance-staging.web.app",
    api_url: "https://crediavance-staging.money-phone.com",
    client_id: "q64ivB4TDLoE9PVZbvQbJW5BM5LjvmC4zWJDK86z",
    client_secret:
      "9QzM7VuiNcxaA5NRBZtIs2w75DPwZfLzNXNyVvK3HGNZJFVGhrdz8CYdayQb9k6GJqW7Rk4gq3CxRsiS3wxjP8b3Ob3h6QO2i1zbEvA8fx9cBhsVwFEn2uS7Q9e6adtd",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240546",
    locale_files_url:
      "https://static-assets-moneyphone-staging.s3.eu-central-1.amazonaws.com",
  },
  crediavance_prod: {
    mode: "production",
    web_url: "https://crediavance-apply.money-phone.com",
    api_url: "https://crediavance.money-phone.com",
    client_id: "uMPpJCAr5BB7v6EZteBvrJexDjnwlvoSiI1YoZ4O",
    client_secret: "",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240546",
    locale_files_url: "https://mon-tenant-files.s3.eu-central-1.amazonaws.com",
  },
  rentamigo_dev: {
    mode: "development",
    web_url: "https://rentamigo-dev.web.app",
    api_url: "https://rentamigo-dev.money-phone.com",
    client_id: "nPMXqcgiDfdN57U2zdnaxl27TgSmJdT0faMiqsm9",
    client_secret:
      "YPnatV5VlEsaX2t0EHfQxndutxwZH5SWaXunraVcngoquyrqYvfNbxZvE2WQDAi14ERIAyi8HCXx78ZsfNQNuQNo2dyme2gMOBANruQfREGSCR4ifia9o6dmWanYv4gH",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240566",
    locale_files_url:
      "https://static-assets-moneyphone-dev.s3.eu-central-1.amazonaws.com",
  },
  rentamigo_staging: {
    mode: "staging",
    web_url: "https://rentamigo-staging.web.app",
    api_url: "https://rentamigo-staging.money-phone.com",
    client_id: "q64ivB4TDLoE9PVZbvQbJW5BM5LjvmC4zWJDK86z",
    client_secret:
      "9QzM7VuiNcxaA5NRBZtIs2w75DPwZfLzNXNyVvK3HGNZJFVGhrdz8CYdayQb9k6GJqW7Rk4gq3CxRsiS3wxjP8b3Ob3h6QO2i1zbEvA8fx9cBhsVwFEn2uS7Q9e6adtd",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240566",
    locale_files_url:
      "https://static-assets-moneyphone-staging.s3.eu-central-1.amazonaws.com",
  },
  rentamigo_prod: {
    mode: "production",
    web_url: "https://rentamigo-apply.money-phone.com",
    api_url: "https://rentamigo.money-phone.com",
    client_id: "o8GWnIuTJVdeaVHKZsWbP31h1jCplqgqttue7JwC",
    client_secret: "",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240566",
    locale_files_url: "https://mon-tenant-files.s3.eu-central-1.amazonaws.com",
  },
  procredito_dev: {
    mode: "development",
    web_url: "https://procredito-dev.web.app",
    api_url: "https://procredito-dev.money-phone.com",
    client_id: "nPMXqcgiDfdN57U2zdnaxl27TgSmJdT0faMiqsm9",
    client_secret:
      "YPnatV5VlEsaX2t0EHfQxndutxwZH5SWaXunraVcngoquyrqYvfNbxZvE2WQDAi14ERIAyi8HCXx78ZsfNQNuQNo2dyme2gMOBANruQfREGSCR4ifia9o6dmWanYv4gH",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240662",
    locale_files_url:
      "https://static-assets-moneyphone-dev.s3.eu-central-1.amazonaws.com",
  },
  procredito_staging: {
    mode: "staging",
    web_url: "https://procredito-staging.web.app",
    api_url: "https://procredito-staging.money-phone.com",
    client_id: "q64ivB4TDLoE9PVZbvQbJW5BM5LjvmC4zWJDK86z",
    client_secret:
      "9QzM7VuiNcxaA5NRBZtIs2w75DPwZfLzNXNyVvK3HGNZJFVGhrdz8CYdayQb9k6GJqW7Rk4gq3CxRsiS3wxjP8b3Ob3h6QO2i1zbEvA8fx9cBhsVwFEn2uS7Q9e6adtd",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240662",
    locale_files_url:
      "https://static-assets-moneyphone-staging.s3.eu-central-1.amazonaws.com",
  },
  procredito_prod: {
    mode: "production",
    web_url: "https://procredito-apply.money-phone.com",
    api_url: "https://procredito.money-phone.com",
    client_id: "1DlvRP4LSKV5dNqAs0R7msT2Xe38QnIw72COSlLb",
    client_secret: "",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240662",
    locale_files_url: "https://mon-tenant-files.s3.eu-central-1.amazonaws.com",
  },
  credicapital_dev: {
    mode: "development",
    web_url: "https://credicapital-dev.web.app",
    api_url: "https://credicapital-dev.money-phone.com",
    client_id: "nPMXqcgiDfdN57U2zdnaxl27TgSmJdT0faMiqsm9",
    client_secret:
      "YPnatV5VlEsaX2t0EHfQxndutxwZH5SWaXunraVcngoquyrqYvfNbxZvE2WQDAi14ERIAyi8HCXx78ZsfNQNuQNo2dyme2gMOBANruQfREGSCR4ifia9o6dmWanYv4gH",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240546",
    locale_files_url:
      "https://static-assets-moneyphone-dev.s3.eu-central-1.amazonaws.com",
  },
  credicapital_staging: {
    mode: "staging",
    web_url: "https://credicapital-staging.web.app",
    api_url: "https://credicapital-staging.money-phone.com",
    client_id: "q64ivB4TDLoE9PVZbvQbJW5BM5LjvmC4zWJDK86z",
    client_secret:
      "9QzM7VuiNcxaA5NRBZtIs2w75DPwZfLzNXNyVvK3HGNZJFVGhrdz8CYdayQb9k6GJqW7Rk4gq3CxRsiS3wxjP8b3Ob3h6QO2i1zbEvA8fx9cBhsVwFEn2uS7Q9e6adtd",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240546",
    locale_files_url:
      "https://static-assets-moneyphone-staging.s3.eu-central-1.amazonaws.com",
  },
  credicapital_prod: {
    mode: "production",
    web_url: "https://credicapital-apply.money-phone.com",
    api_url: "https://credicapital.money-phone.com",
    client_id: "KNDxmsVcnyG2U1OzUD0VTTbBwaLdp3IRw6liBQiR",
    client_secret: "",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240546",
    locale_files_url: "https://mon-tenant-files.s3.eu-central-1.amazonaws.com",
  },
  finadev_dev: {
    mode: "development",
    web_url: "https://finadev-dev.web.app",
    api_url: "https://finadev-dev.money-phone.com",
    client_id: "nPMXqcgiDfdN57U2zdnaxl27TgSmJdT0faMiqsm9",
    client_secret:
      "YPnatV5VlEsaX2t0EHfQxndutxwZH5SWaXunraVcngoquyrqYvfNbxZvE2WQDAi14ERIAyi8HCXx78ZsfNQNuQNo2dyme2gMOBANruQfREGSCR4ifia9o6dmWanYv4gH",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240551",
    locale_files_url:
      "https://static-assets-moneyphone-dev.s3.eu-central-1.amazonaws.com",
  },
  finadev_staging: {
    mode: "staging",
    web_url: "https://finadev-staging.web.app",
    api_url: "https://finadev-staging.money-phone.com",
    client_id: "q64ivB4TDLoE9PVZbvQbJW5BM5LjvmC4zWJDK86z",
    client_secret:
      "9QzM7VuiNcxaA5NRBZtIs2w75DPwZfLzNXNyVvK3HGNZJFVGhrdz8CYdayQb9k6GJqW7Rk4gq3CxRsiS3wxjP8b3Ob3h6QO2i1zbEvA8fx9cBhsVwFEn2uS7Q9e6adtd",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240551",
    locale_files_url:
      "https://static-assets-moneyphone-staging.s3.eu-central-1.amazonaws.com",
  },
  finadev_prod: {
    mode: "production",
    web_url: "https://finadev-apply.money-phone.com",
    api_url: "https://finadev.money-phone.com",
    client_id: "B9RgordDE6cUWVq9mWY6el6Tjd9g3zNvt8Wpu6T5",
    client_secret: "",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240551",
    locale_files_url: "https://mon-tenant-files.s3.eu-central-1.amazonaws.com",
  },
  banco_popular_dev: {
    mode: "development",
    web_url: "https://banco-popular-dev.web.app",
    api_url: "https://banco-popular-dev.money-phone.com",
    client_id: "nPMXqcgiDfdN57U2zdnaxl27TgSmJdT0faMiqsm9",
    client_secret:
      "YPnatV5VlEsaX2t0EHfQxndutxwZH5SWaXunraVcngoquyrqYvfNbxZvE2WQDAi14ERIAyi8HCXx78ZsfNQNuQNo2dyme2gMOBANruQfREGSCR4ifia9o6dmWanYv4gH",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240577",
    locale_files_url:
      "https://static-assets-moneyphone-dev.s3.eu-central-1.amazonaws.com",
  },
  banco_popular_staging: {
    mode: "staging",
    web_url: "https://banco-popular-staging.web.app",
    api_url: "https://banco-popular-staging.money-phone.com",
    client_id: "q64ivB4TDLoE9PVZbvQbJW5BM5LjvmC4zWJDK86z",
    client_secret:
      "9QzM7VuiNcxaA5NRBZtIs2w75DPwZfLzNXNyVvK3HGNZJFVGhrdz8CYdayQb9k6GJqW7Rk4gq3CxRsiS3wxjP8b3Ob3h6QO2i1zbEvA8fx9cBhsVwFEn2uS7Q9e6adtd",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240577",
    locale_files_url:
      "https://static-assets-moneyphone-staging.s3.eu-central-1.amazonaws.com",
  },
  banco_popular_prod: {
    mode: "production",
    web_url: "https://banco-popular-apply.money-phone.com",
    api_url: "https://banco-popular.money-phone.com",
    client_id: "B9RgordDE6cUWVq9mWY6el6Tjd9g3zNvt8Wpu6T5",
    client_secret: "",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240577",
    locale_files_url: "https://mon-tenant-files.s3.eu-central-1.amazonaws.com",
  },
  bll_dev: {
    mode: "development",
    web_url: "https://bll-apply-dev.money-phone.com",
    api_url: "https://bll-dev.money-phone.com",
    client_id: "nPMXqcgiDfdN57U2zdnaxl27TgSmJdT0faMiqsm9",
    client_secret:
      "YPnatV5VlEsaX2t0EHfQxndutxwZH5SWaXunraVcngoquyrqYvfNbxZvE2WQDAi14ERIAyi8HCXx78ZsfNQNuQNo2dyme2gMOBANruQfREGSCR4ifia9o6dmWanYv4gH",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240577",
    locale_files_url:
      "https://static-assets-moneyphone-dev.s3.eu-central-1.amazonaws.com",
  },
  bll_staging: {
    mode: "staging",
    web_url: "https://bll-apply-staging.money-phone.com",
    api_url: "https://bll-staging.money-phone.com",
    client_id: "q64ivB4TDLoE9PVZbvQbJW5BM5LjvmC4zWJDK86z",
    client_secret:
      "9QzM7VuiNcxaA5NRBZtIs2w75DPwZfLzNXNyVvK3HGNZJFVGhrdz8CYdayQb9k6GJqW7Rk4gq3CxRsiS3wxjP8b3Ob3h6QO2i1zbEvA8fx9cBhsVwFEn2uS7Q9e6adtd",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240577",
    locale_files_url:
      "https://static-assets-moneyphone-staging.s3.eu-central-1.amazonaws.com",
  },
  bll_prod: {
    mode: "production",
    web_url: "https://bll-apply.money-phone.com",
    api_url: "https://bll.money-phone.com",
    client_id: "B9RgordDE6cUWVq9mWY6el6Tjd9g3zNvt8Wpu6T5",
    client_secret: "",
    googleToken: "xx",
    fb_app_id: "xx",
    showReduxLogger: true,
    is_required: true,
    whatsapp_number: "+3197010240577",
    locale_files_url: "https://mon-tenant-files.s3.eu-central-1.amazonaws.com",
  },
};
