import { SurveyComponent } from "../../survey_component";

export const CurrentJobDescription = new SurveyComponent({
  title: {
    en: "Job Description *",
    ar: "المسمى الوظيفي *",
  },
  name: "current_job_description",
  isRequired: false,
  type: "comment",
});
