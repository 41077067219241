import { SurveyComponent } from "../../survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";

export const IdentificationFileVerified = new SurveyComponent({
  name: "id_avatar",
  type: "panel",
  visibleIf: "{id_file} notempty",
  innerIndent: 1,
  isRequired: required,
  title: {
    en: "Please verify that this card belongs to you",
    ar: "من فضلك قم بالتأكيد بأن هذه البطاقة تعود لك"
  },
  elements: [
    {
      name: "id_avatar_desc",
      type: "html",
      description:
        "Please verify your identification information provided above by uploading a picture of yourself holding the provided identification information.",
      html: {
        en: "Please verify your identification information provided above by uploading a picture of yourself holding the provided identification information.",
        ar: "يرجى التحقق من معلومات التعريف المقدمة أعلاه عن طريق تحميل صورة لنفسك تحمل معلومة التعريف المقدمة."
      }
    },
    {
      name: "id_avatar_desc_picture",
      type: "html",
      html: `<img alt="example" src='${require("../../../../assets/images/example_card.png")}' width='200'/>`
    },
    {
      name: "id_file_with_avatar",
      type: "file_uploader",
      accept: "image/*,application/pdf",
      isRequired: required,
      title: {
        en: "Identification Picture *",
        ar: "صورة التعريف *"
      }
    }
  ]
});
