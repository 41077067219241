import { SurveyComponent } from "../../survey_component";

export const BankAccountBalance = new SurveyComponent({
  title: {
    en:
      "Bank Account Balance as of today (JOD) *",
    ar: "رصيد الحساب البنكي اعتبارًا من اليوم (دينار أردني) *"
  },
  description: {
    en:
      '',
    ar:
      ""
  },
  name: "assets_bank",
  type: "nouislider",
  step: 10,
  rangeMin: 0,
  rangeMaxStart: 500,
  maxStep: 500,
  rangeMax: 25000,
  visibleIf: "{bank_account} == 'yes'"
});
