import ConfigMFW from "./config/config.deployment.mfw.json";
import ConfigEduloan from "./config/config.deployment.eduloan.json";
import ConfigEssentials from "./config/config.deployment.essentials.json";
import ConfigRw from "./config/config.deployment.rw.json";
import ConfigBll from "./config/config.deployment.bll.json";
import ConfigFfdl from "./config/config.deployment.ffdl.json";
import ConfigCrediavance from "./config/config.deployment.crediavance.json";
import ConfigCredicapital from "./config/config.deployment.credicapital.json";
import ConfigProcredito from "./config/config.deployment.procredito.json";
import ConfigFinadev from "./config/config.deployment.finadev.json";
import ConfigRentamigo from "./config/config.deployment.rentamigo.json"
import ConfigBancoPopular from "./config/config.deployment.banco_popular.json"
import env from "./env";

let Config;
switch (env.DEPLOYMENT_NAME) {
  case "mfw":
    Config = ConfigMFW;
    break;

  case "eduloan":
    Config = ConfigEduloan;
    break;

  case "essentials":
    Config = ConfigEssentials;
    break;

  case "rw":
    Config = ConfigRw;
    break;

  case "ffdl":
    Config = ConfigFfdl;
    break;

  case "crediavance":
    Config = ConfigCrediavance;
    break;

  case "credicapital":
    Config = ConfigCredicapital;
    break;

  case "procredito":
    Config = ConfigProcredito;
    break;

  case "finadev":
    Config = ConfigFinadev;
    break;

  case "rentamigo":
    Config = ConfigRentamigo;
    break;

  case "banco_popular":
    Config = ConfigBancoPopular;
    break;

  case "bll":
    Config = ConfigBll;
    break;

  default:
    throw new Error(`Unknown deployment name: ${env.DEPLOYMENT_NAME}`);
}

export default Config;
