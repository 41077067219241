
import { SurveyComponent } from "../survey_component";

export default new SurveyComponent({
    title: {
        en: "Liabilities Details",
        ar: "تفاصيل الالتزامات"
      },
      name: "liabilities",
      valueName: "liabilities",
      type: "matrixdynamic",
      minRowCount: 0,
      rowCount: 0,
      isRequired: false,
      addRowText: {en: "Add", ar: "إضافة"},
      columns: [
        {
          title: {en: "Title", ar: "العنوان"},
          name: "liability_title",
          isRequired: true,
          cellType: "text",
          inputType: "string",
        },
        {
          title: {en: "Description", ar:  "الوصف"},
          name: "liability_description",
          isRequired: true,
          cellType: "text",
          inputType: "string",
        },
        {
          title: {
            en: "Attachment",
            ar: "المرفق"},
          name: "liability_attachment",
          cellType: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: false
        }
      ]
});
