import { SurveyComponent } from "../../../../components/survey/survey_component";
import env from "../../../../env";

const required = env.ENDPOINT !== "local";
// if you start using the (clientUrlForCatalog) remove the _ in the name

export const credit_credicapital = (_clientUrlForCatalog) =>
  new SurveyComponent({
    title: {
      en: "Loan info",
      "es-MX": "Datos del crédito solicitado",
    },
    elements: [
      {
        title: { en: "Loan amount", "es-MX": "Monto del crédito" },
        name: "amount",
        type: "nouislider",
        step: 10,
        rangeMin: 5000,
        maxStep: 500,
        rangeMax: 200000,
      },
      {
        title: { en: "Loan term in months", "es-MX": "Plazo en meses" },
        name: "loan_term",
        type: "nouislider",
        step: 1,
        rangeMin: 3,
        maxStep: 1,
        rangeMax: 6,
        isRequired: required,
        visibleIf: "{amount} <= 10000",
      },
      {
        title: { en: "Loan term in months", "es-MX": "Plazo en meses" },
        name: "loan_term",
        type: "nouislider",
        step: 1,
        rangeMin: 3,
        maxStep: 1,
        rangeMax: 12,
        isRequired: required,
        visibleIf: "10000 < {amount} && {amount} <= 100000",
      },
      {
        title: { en: "Loan term in months", "es-MX": "Plazo en meses" },
        name: "loan_term",
        type: "nouislider",
        step: 1,
        rangeMin: 3,
        maxStep: 1,
        rangeMax: 18,
        isRequired: required,
        visibleIf: "100000 < {amount} && {amount} <= 200000",
      },
      {
        title: { en: "Financed insurance", "es-MX": "Seguro financiado" },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_22",
        choices: [
          { value: "si", text: { en: "Yes", "es-MX": "Sí" } },
          { value: "no", text: { en: "No", "es-MX": "No" } },
        ],
      },
      {
        title: { en: "Select input", "es-MX": "Selecciona" },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_24",
        choices: [
          { value: "garantia", text: { en: "Guarantee", "es-MX": "Garantía" } },
          {
            value: "comision",
            text: { en: "Commission", "es-MX": "Comisión" },
          },
        ],
      },
      {
        title: { en: "Guarantee input", "es-MX": "Garantía" },
        type: "dropdown",
        visibleIf: "{custom_text_24} == 'garantia'",
        name: "custom_text_141",
        isRequired: "{custom_text_24} == 'garantia'",
        choices: [
          {
            value: "financiada",
            text: { en: "Financed", "es-MX": "Financiada" },
          },
          {
            value: "no_financiada",
            text: { en: "Not financed", "es-MX": "No financiada" },
          },
        ],
      },
      {
        title: {
          en: "Type of credit operation",
          "es-MX": "Tipo de operación de crédito",
        },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_26",
        choices: [
          {
            value: "capital_de_trabajo",
            text: { en: "working capital", "es-MX": "Capital de trabajo" },
          },
          {
            value: "inversión",
            text: { en: "investment", "es-MX": "Inversión" },
          },
          {
            value: "libre_disposición",
            text: { en: "free disposal", "es-MX": "Libre disposición" },
          },
        ],
      },
      {
        title: {
          en: "specifically detail what you will buy with the credit",
          "es-MX": "Detallar específicamente que comprará con el crédito",
        },
        type: "text",
        name: "custom_text_27",
        isRequired: required,
        visibleIf: "{custom_text_26} !== 'libre_disposición' ",
      },
      {
        title: { en: "Payment modality", "es-MX": "Modalidad de pagos" },
        type: "dropdown",
        isRequired: required,
        name: "custom_text_28",
        choices: [
          { value: "semanal", text: { en: "week", "es-MX": "Semanal" } },
          {
            value: "catorcenal",
            text: { en: "bi-weekly", "es-MX": "Catorcenal" },
          },
          {
            value: "quincenal",
            text: { en: "bi-weekly", "es-MX": "Quincenal" },
          },
          { value: "mensual", text: { en: "monthly", "es-MX": "Mensual" } },
        ],
      },
    ],
  });
