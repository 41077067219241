import {EducationalAgreementPageGuarantor} from '../../components/survey/educational_agreement_guarantor';
import {expressions} from '../../survey/validators';
import {en_3rd_agreement_texts, p, rtl} from '../../components/survey/review_fields';

import {
  CurrentJobAddress,
  CurrentJobCompany,
  CurrentJobDescription,
  CurrentJobHours,
  CurrentJobPhone,
  CurrentJobPosition,
  CurrentJobSalary,
  CurrentJobSalarySlip,
} from '../../components/survey/questions/current_job';
import {
  HomeAddressDetails,
  HomeBuildingNumber,
  HomeCity,
  HomeHouseNumber,
  HomeStreetName,
} from '../../components/survey/questions/home';
import {HasJob} from '../../components/survey/questions/has_job';
import {DateOfBirth} from '../../components/survey/questions/date_of_birth';

import {GuarantorMaritalStatus} from './pages/marital_status';
import {GuarantorIdentification} from './pages/identification';
import {BankAccount} from '../educational_intake/pages/bank_account';
import env from "../../env";

const required = env.ENDPOINT !== 'local';

const your_signature = [
  'أقر أنا الموقع ادناه بصحة المعلومات والبيانات والوثائق المزودة من قبل المدين ومن قبلي أنا الكفيل ( { first_name } ) لشركة صندوق المرأة للتمويل الأصغر وأنها مطابقة لمعلوماتي وبياناتي الشخصية وقد تم تعبئتها وأنا بكامل أهليتي المعتبرة قانونياً وأنني بذلك أكون مسؤول مسؤولية كاملة عن كفالتي وكذلك فإنني اتفهم تماماً بأنه في حال عدم قيام المدين بالسداد أن أقوم بسداد كامل قيمة القرض وفي حال عدم قيامي بالسداد فإن للشركة الحق في اتخاذ الإجراءات القانونية بحقي دون أي اعتراض.',
];

const your_signature_agreement = (name) => {
  return `أقر أنا الموقع ادناه بصحة المعلومات والبيانات والوثائق المزودة من قبل المدين ومن قبلي أنا الكفيل ${name} لشركة صندوق المرأة للتمويل الأصغر وأنها مطابقة لمعلوماتي وبياناتي الشخصية وقد تم تعبئتها وأنا بكامل أهليتي المعتبرة قانونياً وأنني بذلك أكون مسؤول مسؤولية كاملة عن كفالتي وكذلك فإنني اتفهم تماماً بأنه في حال عدم قيام المدين بالسداد أن أقوم بسداد كامل قيمة القرض وفي حال عدم قيامي بالسداد فإن للشركة الحق في اتخاذ الإجراءات القانونية بحقي دون أي اعتراض.`;
};

export const agreement = (lt, name, prefix, loan_purpose) => {
  console.log(loan_purpose);
  if (loan_purpose === 'education') {
    console.log('education');
    return `${prefix}

      Loan Amount (JOD): ${lt.amount}
      [x] I agree

      Loan Terms (Months): ${lt.repayment_period}
      [x] I agree

      ${en_3rd_agreement_texts}
      [x] I agree 

      ${your_signature_agreement(name)}
      [x] I agree 
      `;
  } else {
    console.log('business');
    return `${prefix}
      Loan Amount (JOD): ${lt.amount}
      Loan Terms (Months): ${lt.repayment_period}
      Expected Date to Receive the Loan (if granted): ${
        lt.start_loan_date_translated
      }
      Expected Monthly Administrative Fees (%): ${
        lt.monthly_administrative_fees * 100
      }%
      Expected Total Administrative Fees (JOD): ${lt.total_fees}
      Expected Monthly Installment including Fees (JOD): ${
        lt.monthly_installment
      }
      Expected First Installment Date: ${lt.start_repayment_date_translated}
      
      Your Signature:
      ${your_signature_agreement(name)}
      [x] I agree `;
  }
};

const i_agree = {
  title: 'removeMe',
  type: 'checkbox',
  name: 'agree',
  isRequired: true,
  choices: [
    {
      value:
        'Guarantor has agreed to loan amount, loan term and total fees for the { loan_purpose } flow.',
      text: {
        en: 'I agree',
        ar: 'أنا أوافق',
      },
    },
  ],
  validators: [],
};

export const guarantor = {
  showProgressBar: 'top',
  showQuestionNumbers: 'off',
  sendResultOnPageNext: true,

  firstPageIsStarted: false,

  showCompletedPage: false,
  completeText: { en: 'Submit information', ar: 'أرسل البيانات' },

  questionStartIndex: 1,
  requiredText: '',

  pages: [
    {
      title: { en: 'Guarantor acceptance', ar: 'Guarantor acceptance' },
      name: 'guarantor_refused_page',
      elements: [
        {
          title: {
            en:
              'Welcome {first_name}, you are invited to be the guarantor of {user_first_name} for a loan amount of {amount} JOD. To fulfil this invitation, you will be asked for some information about yourself. Please select whether the invitation is accepted from your side or not. If you have any questions, please contact us.',
            ar:
              'مرحبًا {first_name} تمت دعوتك لتكون الكفيل لمقدّم الطلب {user_first_name} للحصول على قرض بمبلغ {amount} دينار أردني. لتلبية هذه الدعوة، سوف يطلب منك بعض المعلومات عن نفسك. يرجى تحديد ما إذا كانت الدعوة مقبولة من جانبك أم لا. إذا كان لديك أي أسئلة ، يرجى الاتصال بنا.',
          },
          name: 'guarantor_refused',
          type: 'radiogroup',
          isRequired: required,
          choices: [
            {
              value: false,
              text: {
                en: 'I accept',
                ar: 'أوافق',
              },
            },
            {
              value: true,
              text: {
                en: 'I don’t accept',
                ar: 'لا أوافق',
              },
            },
          ],
        },
      ],
    },
    {
      title: { en: 'Guarantor Information', ar: 'معلومات الكفيل' },
      name: 'personal',
      elements: [
        {
          title: { en: 'Guarantor Full Name *', ar: 'اسم الكفيل الرباعي *' },
          name: 'last_name',
          type: 'text',
          isRequired: required,
        },
        {
          title: {
            en:
              'Guarantor Mobile No. * (Please include the country code. Ex. 962791234567)',
            ar:
              'رقم الهاتف للكفيل * (يرجى إدخال رمز البلد. مثال: 961291234567)',
          },
          name: 'phone_number',
          type: 'phone',
          validators: [
            expressions.phone_en('phone'),
            expressions.phone_length('phone'),
          ],
          isRequired: required,
        },
        DateOfBirth,
        {
          title: {
            en: 'Where do you live?',
            ar: 'أين تعيش؟',
          },
          name: 'panel_home',
          type: 'panel',
          innerIndent: 1,
          elements: [
            HomeStreetName,
            HomeBuildingNumber,
            HomeHouseNumber,
            HomeCity,
            HomeAddressDetails,
          ],
        },
      ],
    },
    GuarantorIdentification,
    GuarantorMaritalStatus,
    BankAccount,
    {
      title: { en: 'Income information', ar: 'معلومات الإيرادات / الدخل' },
      name: 'guarantor_income_info',

      elements: [
        {
          title: {
            en: 'Income Details',
            ar: 'تفاصيل الإيرادات / الدخل',
          },
          name: 'income',
          valueName: 'income',
          type: 'matrixdynamic',
          minRowCount: 1,
          rowCount: 1,
          isRequired: required,
          addRowText: { en: 'Add', ar: 'إضافة' },
          columns: [
            {
              title: { en: 'Title', ar: 'العنوان' },
              name: 'guarantor_income_title',
              isRequired: true,
              cellType: 'text',
              inputType: 'string',
            },
            {
              title: { en: 'Description', ar: 'الوصف' },
              name: 'guarantor_income_description',
              isRequired: true,
              cellType: 'text',
              inputType: 'string',
            },
            {
              title: {
                en: 'Attachment',
                ar: 'المرفق',
              },
              name: 'guarantor_income_evidence',
              cellType: 'file_uploader',
              accept: 'image/*,application/pdf',
              isRequired: true,
            },
          ],
        },
      ],
    },

    {
      title: {
        en: 'Job Information',
        ar: 'معلومات الوظيفة',
      },
      name: 'job',
      elements: [
        HasJob,
        {
          title: {
            en: 'Could you share details of your current job with us?',
            ar: 'هل يمكنك مشاركتنا بتفاصيل عملك الحالي؟',
          },
          name: 'current_jobs',
          valueName: 'current_jobs',
          type: 'panel',
          visibleIf: '{has_job} == true',
          innerIndent: 1,
          elements: [
            CurrentJobPhone,
            CurrentJobPosition,
            CurrentJobCompany,
            CurrentJobAddress,
            CurrentJobHours,
            CurrentJobSalary,
            CurrentJobSalarySlip,
            CurrentJobDescription,
          ],
        },
      ],
    },

    {
      title: {
        en: 'Review the loan and sign below',
        ar: 'قم بقراءة العقد كاملاً ومن ثم قم بالتوقيع أدناه',
      },
      name: 'loan',
      elements: [
        ...EducationalAgreementPageGuarantor.elements.map((element) => ({
          ...element,
          visibleIf: "{loan_purpose} == 'education'",
        })),
        {
          visibleIf: "{loan_purpose} == 'business'",
          title: { en: 'The loan terms:', ar: 'شروط العقد' },
          name: 'terms',
          type: 'panel',
          innerIndent: 1,
          elements: [
            {
              name: "loan_terms",
              valueName: "loan_terms",
              type: "expression",
              expression: "calcLoanterms({amount}, {repayment_period}, {monthly_insurance})", // Floating Point Proof
              title: "Technical loan terms (NOT visible for user)",
              visibleIf: "{amount} < 0",
            },
            {
              title: {
                en: 'Loan Amount (JOD)',
                ar: 'قيمة القرض (دينار أردني)',
              },
              type: 'expression',
              expression: '{amount}', // Floating Point Proof
            },
            {
              title: {
                en: 'Loan Terms (Months)',
                ar: 'فترة السداد (أشهر)',
              },
              type: 'expression',
              expression: '{repayment_period}', // Floating Point Proof
            },
            {
              title: {
                en: 'Expected Date to Receive the Loan (if granted)',
                ar: 'التاريخ المتوقع للحصول على القرض (في حال تم منحه)',
              },
              type: 'expression',
              name: 'start_loan_date_translated',
              expression: "getLoanTerm({loan_terms}, 'start_loan_date_translated')" // Floating Point Proof
            },
            {
              title: {
                en: 'Expected Monthly Administrative Fees (%) (Final calculated numbers will be included in the contract)',
                ar: 'الرسوم الإدارية الشهرية المتوقعة (%) (الأرقام النهائية التي يتم احتسابها هي الأرقام المدرجة في العقد)',
              },
              type: 'expression',
              expression: "(getLoanTerm({loan_terms}, 'monthly_administrative_fees') * 100) + '%'" // Floating Point Proof
            },
            {
              title: {
                en: 'Expected Total Administrative Fees (JOD) (Final calculated numbers will be included in the contract)',
                ar: 'إجمالي الرسوم الإدارية المتوقعة (دينار أردني) (الأرقام النهائية التي يتم احتسابها هي الأرقام المدرجة في العقد)',
              },
              type: 'expression',
              expression: "getLoanTerm({loan_terms}, 'total_fees')" // Floating Point Proof
            },
            {
              title: {
                en: 'Expected Monthly Installment including Fees (JOD) (Final calculated numbers will be included in the contract)',
                ar: 'القسط الشهري المتوقع بما يشمل الرسوم (دينار) (الأرقام النهائية التي يتم احتسابها هي الأرقام المدرجة في العقد)',
              },
              type: 'expression',
              expression: "getLoanTerm({loan_terms}, 'total_monthly')" // Floating Point Proof
            },
            {
              title: {
                en: 'Expected First Installment Date (Final calculated date will be included in the contract)',
                ar: 'تاريخ القسط الأول المتوقع (التاريخ النهائي الذي يتم احتسابه هو التاريخ المدرج في العقد)',
              },
              type: 'expression',
              expression: "getLoanTerm({loan_terms}, 'start_repayment_date_translated')" // Floating Point Proof
            }
          ]
        },

        {
          title: { en: 'Your signature', ar: 'توقيعك' },
          name: 'signature',
          type: 'panel',
          innerIndent: 1,

          elements: [
            {
              type: 'html',
              title: '',
              html: {
                ar: your_signature.map(rtl).join(''),
                en: your_signature.map(p).join(''),
              },
            },
            { ...i_agree, name: 'responsible' },
            {
              type: 'text',
              inputType: 'text',
              name: 'sign_name',
              isRequired: true,
              title: {
                en: 'Guarantor Full Name',
                ar: 'اسم الكفيل الكامل (الاسم الرباعي)',
              },
            },

            {
              type: 'text',
              inputType: 'date',
              name: 'sign_date',
              isRequired: true,
              title: {
                en: 'Application Date',
                ar: 'تاريخ الطلب',
              },
            },

            {
              type: 'signaturepad',
              name: 'signature',
              isRequired: true,
              title: {
                en: 'Please enter your signature',
                ar: 'الرجاء إدخال توقيعك',
              },
            },
          ],
        },
      ],
    },
  ],
};
