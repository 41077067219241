import { SurveyComponent } from "../../survey_component";

export const HomeAddressDetails = new SurveyComponent({
  name: "home_addres_details",
  type: "comment",
  isRequired: false,
  title: {
    en: "Address Details *",
    ar: "تفاصيل عنوان المنزل *"
  }
});
