
import { SurveyComponent } from "../survey_component";
import env from "../../../env";
const required = env.ENDPOINT !== 'local';

export default new SurveyComponent({
    title: {
        en: "Income Details",
        ar:"تفاصيل الإيرادات / الدخل",
      },
      name: "income",
      valueName: "income",
      type: "matrixdynamic",
      minRowCount: 1,
      rowCount: 1,
      isRequired: required,
      addRowText: {en: "Add", ar: "إضافة"},
      columns: [
        {
          title: {en: "Title", ar: "العنوان"},
          name: "income_title",
          isRequired: required,
          cellType: "text",
          inputType: "string",
        },
        {
          title: {en: "Description", ar:  "الوصف"},
          name: "income_description",
          isRequired: required,
          cellType: "text",
          inputType: "string",
        },
        {
          title: {
            en: "Attachment",
            ar: "المرفق"},
          name: "income_evidence",
          cellType: "file_uploader",
          accept: "image/*,application/pdf",
          isRequired: required
        }
      ]
});
